import React, { useEffect } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Redirect } from 'react-router-dom';
import qs from 'qs';

import { apiClient } from '../../http';

export default ({ location }) => {
  const { contact, token } = qs.parse(location.search.substring(1));
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    apiClient(`/contacts/${contact}/verify`, {
      method: 'POST',
      data: { verification: token }
    }).then(() => {
      notify('ra.message.verified')
      refresh();
    }).catch(() => {
      notify('resources.contacts.messages.error_verify', 'error')
    })
  }, [ contact, token, notify, refresh ])

  return <Redirect to="/profile" />
}
