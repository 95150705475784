import { SCHEDULED, STARTED, ENDED, APPROVED, CERTIFIED } from '../constants';
import { getOfficials } from './pregame';
import { getPeriod } from '.';

export const getGameTime = (state) => {
  if (!state) return null;
  return state.gameTime;
}

export const getDefaultGameEndTime = (state, sport) => {
  if (sport === 'Baseball') return { period: 9, half: 2 }
  if (sport !== 'Hockey') return {};

  const { period, minutes, seconds } = getGameTime(state);

  if (period === 'SO') return { period, minutes: 0, seconds: 0 };
  if (period === 'OT') return { period, minutes, seconds };

  // if period setting is 0:00, end game at previous period
  const lastPeriod = [3, 2, 1]
    .map(period => getPeriod(state, period))
    .find(period => period && !(period.minutes === 0 && period.seconds === 0))

  return { period: lastPeriod.period, minutes: 0, seconds: 0 }
}

export const getGameStatus = (state) => {
  if (!state) return null;
  return state.status;
}

export const isGameScheduled = (state) => {
  if (!state) return false;
  return getGameStatus(state) === SCHEDULED;
}

export const isGameStarted = (state) => {
  if (!state) return false;
  return getGameStatus(state) === STARTED;
}

export const isGameEnded = (state) => {
  if (!state) return false;
  return getGameStatus(state) === ENDED;
}

/**
 * Scoresheet status is scheduled, started or ended
 */
export const isGameActive = (state) => {
  if (!state) return false;
  return [SCHEDULED, STARTED, ENDED, null].includes(getGameStatus(state));
}

export const isGameApproved = (state) => {
  if (!state) return false;
  return getGameStatus(state) === APPROVED;
}

export const isGameCertified = (state) => {
  if (!state) return false;
  return getGameStatus(state) === CERTIFIED;
}

/**
 * Scoresheet status is approved or certified
 */
export const isGameCompleted = (state) => {
  if (!state) return false;
  return [APPROVED, CERTIFIED].includes(getGameStatus(state))
}

/**
 * Scoresheet status is ended, approved or certified
 */
export const isGameEndedOrCompleted = (state) => {
  if (!state) return false;
  return [ENDED, APPROVED, CERTIFIED].includes(getGameStatus(state));
}

export const getNotes = (state) => {
  if (!state) return '';
  return state.notes;
}

export const getAdminNotes = (state) => {
  if (!state) return '';
  return state.adminNotes;
}

export const getForfeit = (state) => {
  if (!state) return null;
  return state.forfeit;
}

// officials approvals
export const getScoresheetApproval = (state) => {
  if (!state) return null;
  if (!isGameCompleted(state)) return null;

  if (state.approvals) return state.approvals; // legacy
  return {
    officials: getOfficials(state, { assignedOnly: true }),
  }
}

export const isReviewed = (state) => {
  if (!state) return false;
  return !state.unreviewed;
}
