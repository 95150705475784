import React from 'react';
import PropTypes from 'prop-types';
import inflection from 'inflection';
import { Divider, MenuItem, makeStyles, useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import classnames from 'classnames';
import { LoadingIndicator, MenuItemLink, useTranslate, getResources } from 'react-admin';
import { ViewList as DefaultIcon, TrendingUp as ReportsIcon, HomeOutlined as ProfileIcon, DateRange as AvailabilityIcon, HelpOutline as HelpIcon } from '@material-ui/icons'
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import { FF_ACCOUNTS, FF_CMS, FF_CUPS, FF_SCHEDULE_MENU, FF_TOURNAMENTS } from "@hisports/common/featureFlags";

import { isAuthorized } from '../common/Authorize';
import SeasonSelector from '../common/inputs/SeasonSelector';
import { useFilteredScopes, useScopes, useFlag } from '../http';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
      marginBottom: '1em',
      [theme.breakpoints.only('xs')]: {
        marginTop: theme.spacing(1),
      },
    },
    itemActive: {
      fontWeight: 500,
    },
    releaseVersion: {
      fontSize: '75%',
    },
    open: {
      width: get(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: get(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    loader: {
      display: 'flex',
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    profile: {
      paddingBottom: '1em',
      [theme.breakpoints.up('md')]: {
        background: theme.palette.background.paper,
        position: 'fixed',
        bottom: 0,
      },
    }
  }),
  { name: 'RaMenu' }
);

const translatedResourceName = (resource, translate) => {
  const label = resource.options && resource.options.label;
  return translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _: label
      ? translate(label, { smart_count: 2, _: label })
      : inflection.humanize(inflection.pluralize(resource.name)),
  });
}

const ResourceMenuItem = ({ name, resources, scopes, onMenuClick, dense, classes }) => {
  const translate = useTranslate();

  const resource = resources.find(resource => resource.name === name);
  if (!resource) return null;

  if (!resource.hasList || !isAuthorized(scopes, resource.name, 'list')) return null;

  return <MenuItemLink
    key={resource.name}
    to={`/${resource.name}`}

    primaryText={translatedResourceName(resource, translate)}
    leftIcon={
      resource.icon ? <resource.icon /> : <DefaultIcon />
    }
    onClick={onMenuClick}
    dense={dense}
    classes={{
      active: classes.itemActive
    }}
  />
}

const SeasonLoader = ({ open, className }) => {
  return <div className={className}>
    {open && <SeasonSelector />}
    <LoadingIndicator />
  </div>
}

const isVisible = (resources, scopes) => resources.map(resource => isAuthorized(scopes, resource, 'list')).filter(Boolean).length > 1

const Menu = props => {
  const isEnabled = useFlag();
  const classes = useStyles(props);
  const translate = useTranslate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const releaseName = process.env.RELEASE_VERSION && process.env.RELEASE_VERSION.replace('release/', '').substring(0, 8);
  const releaseDate = process.env.RELEASE_DATE && moment(process.env.RELEASE_DATE).format('LL')

  const {
    className,
    dense,
    hasDashboard,
    onMenuClick,
    pathname,
    ...rest
  } = props;

  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual)
  const scopes = useScopes();
  const adminScopes = useFilteredScopes(['System', 'Tenant', 'Office', 'Schedule']);

  // Used to force redraw on navigation
  useSelector(state => state.router.location.pathname);

  const scheduling = ['leagues', 'tournaments', 'cups', 'schedules'];
  const showSchedulingDivider = isVisible(scheduling, adminScopes);

  const management = ['participants', 'teams', 'offices', 'venues'];
  const showManagementDivider = isVisible(management, scopes);

  const web = ['posts', 'webevents', 'pages'];
  const showWebDivider = isVisible(web, scopes) && isEnabled(FF_CMS);

  const showReports = isAuthorized(scopes, 'reports', 'list');

  const isOfficial = isAuthorized(scopes, 'profile', 'official')
  const documentationLink = translate('common.urls.documentation');

  const resourceProps = { resources, scopes, onMenuClick, dense, classes };
  const itemProps = { onClick: onMenuClick, dense, classes: { active: classes.itemActive } };

  return <div className={classnames(classes.main, { [classes.open]: open, [classes.closed]: !open }, className)} {...rest}>
    <SeasonLoader open={open} className={classes.loader} />

    <ResourceMenuItem name="leagues" {...resourceProps} scopes={adminScopes} />
    {isEnabled(FF_TOURNAMENTS) && <ResourceMenuItem name="tournaments" {...resourceProps} scopes={adminScopes} />}
    {isEnabled(FF_CUPS) && <ResourceMenuItem name="cups" {...resourceProps} scopes={adminScopes} />}
    {isEnabled(FF_SCHEDULE_MENU) && <ResourceMenuItem name="schedules" {...resourceProps} scopes={adminScopes} />}

    {showSchedulingDivider && <Divider light className={classes.divider} />}
    <ResourceMenuItem name="games" {...resourceProps} />
    <ResourceMenuItem name="practices" {...resourceProps} />
    <ResourceMenuItem name="activities" {...resourceProps} />

    {showManagementDivider && <Divider light className={classes.divider} />}
    <ResourceMenuItem name="participants" {...resourceProps} />
    <ResourceMenuItem name="teams" {...resourceProps} />
    <ResourceMenuItem name="offices" {...resourceProps} scopes={adminScopes} />
    <ResourceMenuItem name="venues" {...resourceProps} />

    {showWebDivider && <Divider light className={classes.divider} />}
    {isEnabled(FF_CMS) && <ResourceMenuItem name="posts" {...resourceProps} />}
    {isEnabled(FF_CMS) && <ResourceMenuItem name="webevents" {...resourceProps} />}
    {isEnabled(FF_CMS) && <ResourceMenuItem name="pages" {...resourceProps} />}

    {showReports && <Divider light className={classes.divider} />}
    {isEnabled(FF_ACCOUNTS) && <ResourceMenuItem name="accounts" {...resourceProps} scopes={adminScopes} />}
    {showReports && <MenuItemLink to="/reports" primaryText={translate('resources.reports.name', 2)} leftIcon={<ReportsIcon />} {...itemProps} />}

    <div className={classnames(classes.profile, { [classes.open]: open, [classes.closed]: !open })}>
      <Divider light className={classes.divider} />
      {isMobile && <MenuItemLink to={{ pathname: documentationLink }} target="_blank" primaryText={translate('resources.help.name', 2)} leftIcon={<HelpIcon />} exact {...itemProps} />}
      {isOfficial && <MenuItemLink to="/profile/officiating" primaryText={translate('resources.participants.labels.availability.card.title')} leftIcon={<AvailabilityIcon />} {...itemProps} />}
      <MenuItemLink to="/profile" primaryText={translate('resources.profiles.labels.card.title')} leftIcon={<ProfileIcon />} exact {...itemProps} />
      {releaseName && releaseDate && <MenuItem className={classes.releaseVersion} disabled >{`Version: ${releaseName} (${releaseDate})`}</MenuItem>}
    </div>
  </div>
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  open: PropTypes.bool,
  pathname: PropTypes.string,
};

Menu.defaultProps = {
  onMenuClick: () => null,
  dense: false,
};

export default Menu;
