import React from 'react';
import { ReferenceInput } from 'react-admin';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { HomeIcon, WorkIcon, HomeWorkIcon } from '../icons';

const filter = address => ({ address: address || undefined })

export const AddressSuggestion = ({ suggestion: address, query, isHighlighted, ...props }) => {
  return <AutocompleteSuggestion
    icon={address.type == 'Work' ? <WorkIcon /> : address.type == 'Home' ? <HomeIcon /> : <HomeWorkIcon />}
    primary={address.address}
    secondary={address.city && address.province ? `${address.city}, ${address.province}` : ''}
  />
}

export const AddressInput = ({ helperText, ...props }) => {
  return <ReferenceInput
    reference="addresses"
    filterToQuery={filter}
    {...props}
  >
    <AutocompleteInput
      optionText="address"
      helperText={helperText}
      suggestionComponent={AddressSuggestion}
    />
  </ReferenceInput>
}
