import React, { Fragment, useRef } from 'react';
import { TextInput, SimpleForm, useTranslate, NumberInput, useRecordContext } from 'react-admin';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab';
import { Info } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { FF_CROSS_SCHEDULING } from '@hisports/common/featureFlags';
import { changeTimezone, isEmpty } from '@hisports/parsers';

import { SquareAlert } from '../../common/SquareAlert';
import { InlineDateTimeInput, dateTimePlaceholder } from '../../common/inputs/DateInput';
import { TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import { EndTimeInput as InlineEndTimeInput } from '../../common/inputs/EndTimeInput';
import { FieldDependency } from '../../common/FieldDependency';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { useRichTranslate } from '../../common/useRichTranslate';
import { isAuthorized } from '../../common/Authorize';
import { useFlag } from '../../http';

import { useIsHomeFirst } from '../events/EventViewSettings';
import { TeamInput } from '../teams/TeamInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { ScheduleGroupInput } from '../groups/GroupInput';
import { GameAvailabilityInfo } from '../games/GameAvailabilityInfo';
import { GameAvailabilityContextProvider } from '../games/GameAvailabilityContext';
import { GameField } from '../games/GameField';
import { CrossSchedulingInput, GameStatusInput } from '../games/GameForm';
import { useScheduleSettingsWithStore } from '../scheduleSettings';

const validate = (values, schedules, initialDate, limitDateChange, requestChanges, translate) => {
  const errors = {};
  const schedule = schedules?.[values?.scheduleId]

  if (!values.scheduleId) errors.scheduleId = 'ra.validation.required'
  if (values.id && !values.categoryId) errors.categoryId = 'ra.validation.required'

  if (values.round != null && values.round < 1) {
    errors.round = translate('ra.validation.minValue', { min: 1 });
  }

  if (values.endTime && !values.startTime) errors.startTime = 'ra.validation.required'
  if (values.startTime && !values.endTime) errors.endTime = 'ra.validation.required'
  if (values.startTime === values.endTime) errors.endTime = 'ra.validation.end_before_start'

  if (values.startTime && values.endTime) {
    if (!moment.tz(values.startTime, values.timezone).isValid()) {
      errors.startTime = 'resources.games.validations.invalid_time'
    } else if (!moment.tz(values.endTime, values.timezone).isValid()) {
      errors.endTime = 'resources.games.validations.invalid_time'
    } else if (moment.tz(values.endTime, values.timezone).isBefore(values.startTime, 'minute')) {
      errors.endTime = 'resources.games.validations.end_before_start'
    } else if (moment.tz(values.endTime, values.timezone).diff(values.startTime, 'hours') > 6) {
      errors.endTime = 'resources.games.validations.invalid_time_check_am_pm'
    }
  }

  if (limitDateChange && ((!initialDate && values?.date) || !moment.utc(initialDate).isSame(values?.date, 'D'))) {
    errors.startTime = 'resources.games.validations.limit_date_change';
  } else if (values.date && schedule?.startDate && moment.utc(schedule.startDate).isAfter(values.date)) {
    errors.startTime = 'resources.games.validations.before_schedule_date'
  } else if (values.date && schedule?.endDate && moment.utc(schedule.endDate).add(1, 'day').isBefore(values.date)) {
    errors.startTime = 'resources.games.validations.after_schedule_date'
  }

  if (requestChanges && !values.requestReason) errors.requestReason = 'ra.validation.required';

  return errors;
}

const inputProps = {
  resource: 'draftGames',
  basePath: '/draftgames',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const TimezoneInput = ({ surfaces, ...props }) => {
  const { values } = useFormState();

  const surface = surfaces?.[values?.arenaId];
  const disabled = !['TBA', 'NDA'].includes(surface?.alias)

  return <TimezoneEnumInput disabled={disabled} {...props} />
}

export const UpdateGameAlert = ({ updatedGameId, square = false, severity = 'info', alertLabel }) => {
  const translate = useRichTranslate();

  const Alert = square ? SquareAlert : MuiAlert;

  if (!updatedGameId) return null;

  return <Alert icon={<Info />} severity={severity}>
    {alertLabel || translate('resources.draftGames.alerts.update_game', {
      game: <GameField source="updatedGameId" record={{ updatedGameId }} includeDate="full" includeTime="full" includeSurface="full" />
    })}
  </Alert>
}

const DraftRequestFields = ({ surfaces }) => {
  const translate = useTranslate();
  const draftGame = useRecordContext();

  return <>
    <Grid item xs={12}>
      <TextInput source="requestReason" label="Reason for Request" multiline minRows="3" helperText="resources.draftGames.helpers.request_reason" {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <SurfaceInput source="arenaId" {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TimezoneInput source="timezone" surfaces={surfaces} {...inputProps} />
    </Grid>

    <Grid item xs={12} sm={6}>
      <FieldDependency fieldSource="arenaId" disabled>
        <InlineDateTimeInput
          source="startTime"
          options={{
            label: translate('resources.draftGames.fields.startTime'),
            format: 'YYYY-MM-DD HH:mm',
            placeholder: dateTimePlaceholder,
            ampm: false,
            openTo: 'hours',
            initialFocusedDate: draftGame?.date,
          }}
          {...inputProps}
        />
      </FieldDependency>
    </Grid>
    <Grid item xs={12} sm={6}>
      <FieldDependency fieldSource="arenaId" disabled>
        <InlineEndTimeInput
          source="endTime"
          maxHours={6}
          options={{
            label: translate('resources.draftGames.fields.endTime'),
            format: 'YYYY-MM-DD HH:mm',
            placeholder: dateTimePlaceholder,
            ampm: false,
            openTo: 'hours',
            initialFocusedDate: draftGame?.date,
          }}
          {...inputProps}
        />
      </FieldDependency>
    </Grid>
    <GameAvailabilityInfo />

    <Grid item xs={12}>
      <TextInput source="comments" multiline minRows="3" helperText="resources.draftGames.helpers.comments" {...inputProps} />
    </Grid>
  </>
}

const DraftFormFields = ({ surfaces, hideRounds, disableTimeFields, ...props }) => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const draftGame = useRecordContext(props);
  const isHomeFirst = useIsHomeFirst();

  return <>
    <Grid item xs={12} sm={12}>
      <ScheduleGroupInput source="groupId" showNone={translate('ra.message.no_group')} {...inputProps} />
    </Grid>
    {isEnabled(FF_CROSS_SCHEDULING) && <Grid item xs={12}>
      <CrossSchedulingInput />
    </Grid>}

    <Grid item xs={12} sm={6}>
      <TeamInput source={isHomeFirst ? 'homeTeamId' : 'awayTeamId'} helperText="resources.games.helpers.team" {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TeamInput source={isHomeFirst ? 'awayTeamId' : 'homeTeamId'} helperText="resources.games.helpers.team" {...inputProps} />
    </Grid>
    <Grid item xs={12} md={4}>
      <SurfaceInput source="arenaId" {...inputProps} />
    </Grid>

    <Grid item xs={12} md={4}>
      <FieldDependency fieldSource="arenaId" disabled>
        <InlineDateTimeInput
          source="startTime"
          options={{
            label: translate('resources.draftGames.fields.startTime'),
            format: 'YYYY-MM-DD HH:mm',
            placeholder: dateTimePlaceholder,
            ampm: false,
            openTo: 'hours',
            initialFocusedDate: draftGame?.date,
            disabled: disableTimeFields
          }}
          {...inputProps}
        />
      </FieldDependency>
    </Grid>
    <Grid item xs={12} md={4}>
      <FieldDependency fieldSource="arenaId" disabled>
        <InlineEndTimeInput
          source="endTime"
          maxHours={6}
          options={{
            label: translate('resources.draftGames.fields.endTime'),
            format: 'YYYY-MM-DD HH:mm',
            placeholder: dateTimePlaceholder,
            ampm: false,
            openTo: 'hours',
            initialFocusedDate: draftGame?.date,
            disabled: disableTimeFields
          }}
          {...inputProps}
        />
      </FieldDependency>
    </Grid>
    <GameAvailabilityInfo />

    <Grid item xs={12} sm={6} lg={4}>
      <GameStatusInput source="status" defaultValue="Active" {...inputProps} />
    </Grid>
    {!hideRounds && <Grid item xs={12} sm={6} lg={4}>
      <NumberInput source="round" helperText="resources.draftGames.helpers.round" {...inputProps} />
    </Grid>}
    <Grid item xs={12} sm={6} md={4}>
      <TimezoneInput source="timezone" surfaces={surfaces} {...inputProps} />
    </Grid>

    <Grid item xs={12}>
      <TextInput source="comments" multiline minRows="3" helperText="resources.draftGames.helpers.comments" {...inputProps} />
    </Grid>
  </>
}

const InnerDraftGameForm = ({ initialValues, hideRounds, requestChanges = false, ...props }) => {
  const translate = useTranslate();
  const draftGame = useRecordContext(props);
  const { data: scheduleSettings, loading: scheduleSettingsLoading } = useScheduleSettingsWithStore(draftGame?.scheduleId || initialValues?.scheduleId);

  // must use ref for roles to get current value, otherwise value in calculator is stale
  const schedules = useSelector(store => store.admin.resources.schedules.data);
  const schedulesRef = useRef({});
  schedulesRef.current = schedules;

  const surfaces = useSelector(store => store.admin.resources.surfaces.data);
  const surfacesRef = useRef({});
  surfacesRef.current = surfaces;

  // decorators must be wrapped in useRef otherwise SimpleForm prop will register duplicates
  // this bug seems to be triggered by use of hooks (useSelector)
  const decorators = useRef([createCalculator({
    field: 'crossScheduleId',
    updates: {
      crossGroupId: (crossScheduleId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.crossScheduleId !== crossScheduleId) return
        return values?.crossGroupId
      }
    }
  }, {
    field: 'startTime',
    updates: {
      date: (startTime, values) => {
        if (!startTime) return values.date;
        if (!startTime || !values.timezone) return startTime;
        return moment.tz(startTime, values.timezone).format('YYYY-MM-DD');
      },
      endTime: async (startTime, values, prevValues) => {
        const { scheduleId, endTime, timezone } = values;
        if (!startTime || !scheduleId || isEmpty(prevValues) || startTime === prevValues.startTime) return endTime;
        const { gameLength } = scheduleSettings;
        if (!gameLength) return endTime;
        return moment.tz(startTime, timezone).add(gameLength, 'minutes').toISOString();
      }
    }
  }, {
    field: 'arenaId',
    updates: {
      timezone: (arenaId, values, prevValues) => {
        const surface = surfacesRef.current[arenaId];
        if (['TBA', 'NDA'].includes(surface?.alias)) {
          return values?.timezone || prevValues?.timezone;
        }
        return surface?.timezone || values?.timezone;
      }
    }
  }, {
    field: 'timezone',
    updates: {
      startTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.startTime || isEmpty(prevValues) || timezone === prevTimezone) return values.startTime;
        return changeTimezone(values.startTime, prevTimezone, timezone)
      },
      endTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.endTime || isEmpty(prevValues) || timezone === prevTimezone) return values.endTime;
        return changeTimezone(values.endTime, prevTimezone, timezone)
      },
    }
  })])

  const hasDateChangeAccess = isAuthorized(draftGame, 'draftGames', 'edit');
  const limitDateChange = !requestChanges && draftGame?.isShared && scheduleSettings?.limitDateChange && !hasDateChangeAccess;
  const disableTimeFields = scheduleSettingsLoading || (limitDateChange && !initialValues?.date);
  const updatedGameId = draftGame?.updatedGameId || initialValues?.updatedGameId;

  return <SimpleForm
    toolbar={<DialogFormToolbar submitLabel="ra.action.save" />}
    decorators={decorators.current}
    validate={values => validate(values, schedulesRef.current, initialValues?.date, limitDateChange, requestChanges, translate)}
    validateOnBlur
    initialValues={{ status: 'Active', ...initialValues }}
    component={Fragment}
    {...props}>
    <Grid container spacing={2} fullWidth>
      {!!updatedGameId && <Grid item xs={12}>
        <UpdateGameAlert
          updatedGameId={updatedGameId}
          alertLabel={requestChanges && translate("resources.draftGameApprovals.alerts.request_changes")}
        />
      </Grid>}
      {requestChanges
        ? <DraftRequestFields surfaces={surfaces} />
        : <DraftFormFields
          surfaces={surfaces}
          hideRounds={hideRounds}
          disableTimeFields={disableTimeFields}
        />
      }
    </Grid>
  </SimpleForm>
}

export const DraftGameForm = props =>
  <GameAvailabilityContextProvider>
    <InnerDraftGameForm {...props} />
  </GameAvailabilityContextProvider>
