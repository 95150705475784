import React, { Fragment, useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin'

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { InlineDateInput } from '../../../common/inputs/DateInput';
import { AddMenu } from '../../../common/calendar/scheduler/AddMenu';
import { useScopes } from '../../../http';

import { BaseEventView } from '../../events/EventView';
import { CALENDAR_VIEWS, EventViewSettingsContext, EventViewSettingsMenu, GAME_LIST_FILTERS, useEventSort, useReverseHomeAway, useShowCalendar } from '../../events/EventViewSettings';
import { SurfaceInput } from '../../surfaces/SurfaceInput';
import { TeamInput } from '../../teams/TeamInput';
import { GroupInput } from '../../groups/GroupInput';
import { EditDraftGameDialog } from '../../draftgames/DraftGameModal';
import { DraftGamesBulkActions } from '../../draftgames/DraftGameBulkActions';

const EventViewSettingsProvider = ({ ...props }) => {
  const groupDates = useState(true);
  const groupArenas = useState(false);
  const groupRounds = useState(false);
  const assignments = useState(false);
  const gameInfo = useState(false);
  const flags = useState(false);
  const reverseHomeAway = useReverseHomeAway()
  const calendar = useState(false);
  const calendarView = useState(CALENDAR_VIEWS.WEEK);
  const teamEvents = useState(false);
  const slots = useState(true);
  const teamNames = useState(false)
  const availabilities = useState(false);
  const surfaceSizes = useState([]);
  const gameListFilter = useState(GAME_LIST_FILTERS.ALL_GAMES);
  const value = useMemo(() => ({
    calendar,
    calendarView,
    teamEvents,
    slots,
    teamNames,
    availabilities,
    gameInfo,
    flags,
    reverseHomeAway,
    groupDates,
    groupArenas,
    groupRounds,
    assignments,
    surfaceSizes,
    gameListFilter
  }), [ calendar, calendarView, teamEvents, slots, availabilities, gameInfo, flags, reverseHomeAway, groupDates, groupArenas, groupRounds, assignments, surfaceSizes, gameListFilter, teamNames ])
  return <EventViewSettingsContext.Provider value={value} {...props} />
}

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

const getFilters = (schedule, scopes, showCalendar) => {
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');

  const scheduleType = schedule?.type;
  const officeId = schedule?.officeId;

  const categoryIds = schedule && [schedule.categoryId]

  return [
    <InlineDateInput source="startTime" label="resources.draftGames.filters.startTime" variant="outlined" alwaysOn />,
    <InlineDateInput source="endTime" label="resources.draftGames.filters.endTime" variant="outlined" disabled={showCalendar} alwaysOn />,

    canListTeams && <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ scheduleId: schedule?.id, seasonId: schedule?.seasonId }} variant="outlined" alwaysOn {...multipleProps} />,
    canListSurfaces && <SurfaceInput source="arenaId" variant="outlined" {...multipleProps} />,
    <GroupInput
      source="groupId"
      variant="outlined"
      scheduleType={scheduleType}
      officeId={officeId}
      categoryIds={categoryIds}
      allowEmpty
      alwaysOn
    />,
  ].filter(Boolean)
}

const DraftGamesListCard = ({ setDraftGameId, ...props }) => {
  const schedule = useRecordContext(props);
  const scopes = useScopes();
  const [ showCalendar ] = useShowCalendar();
  const sort = useEventSort();

  const filters = getFilters(schedule, scopes, showCalendar);

  const actions = [
    <EventViewSettingsMenu
      disableCalendar
      disableAssignments
      disableGroupRounds={false}
      disableGroupArenas={false}
      alwaysOn
      showTeamEvents={false}
      showSlots={false}
      showTeamNames={false}
      showAvailabilties={false}
      showSurfaceSizes={false}
      showFlagsInput={false}
      showRenumber={false}
      showExporter
      showGameInfoInput={false}
    />,
  ]

  const handleRowSelect = (id, basePath, draftGame) => setDraftGameId(id);

  const showBulkActions = isAuthorized(schedule, 'draftGames', 'bulkActions');

  return <ListCard
    title="resources.draftGames.labels.card.title"
    reference="draftGames"
    target="scheduleId"
    addButton={<AddMenu hideAddGame />}
    actions={actions}
    filters={filters}
    filter={{ scheduleId: schedule?.id, "seasonId": schedule?.seasonId, isPublished: false, updatedGameId: null, pastEvents: true } }
    sort={sort}
    bulkActionButtons={showBulkActions && <DraftGamesBulkActions />}
    rowsPerPageOptions={showCalendar ? [] : [25, 50, 100]}
    perPage={showCalendar ? 99999 : 25}
    noResultsText={!showCalendar}
    {...props}
  >
    <BaseEventView
      rowClick={handleRowSelect}
      disableCalendar
      disableAssignments
      hideStatus
      component={Fragment}
    />
  </ListCard>
}

export default props => {
  const [ draftGameId, setDraftGameId ] = useState()

  return <EventViewSettingsProvider>
    <DraftGamesListCard setDraftGameId={setDraftGameId} {...props} />
    <EditDraftGameDialog isOpen={!!draftGameId} id={draftGameId} handleClose={() => setDraftGameId()} />
  </EventViewSettingsProvider>
}
