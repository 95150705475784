import React from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';
import { Card, Grid } from '@material-ui/core'

import { ScoresheetProvider } from '../scoresheet/ScoresheetContext';
import { GamePurgeList } from '../scoresheet/suspension/GamePurgeList';
import { IncidentReportCard } from '../scoresheet/gameIncidents/IncidentReportCard';
import SuspensionsCard from './SuspensionCard';
import { ParticipantSuspensionsCard } from './ParticipantSuspensionsCard';
import { useGameWithStore } from '../games/useGame';

export const SuspensionShowLayout = props => {
  const suspension = useRecordContext();
  const { gameId, participantId, penaltyId } = suspension

  const game = useGameWithStore(gameId)
  const gameOfficeId = game?.data?.officeId

  if (!gameId) return null;

  return <ScoresheetProvider gameId={suspension.gameId}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SuspensionsCard />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <GamePurgeList />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RecordContextProvider value={{ id: gameId, officeId: gameOfficeId }}>
          <IncidentReportCard hideAddButton filter={{ penaltyId }} rowOptions={{ isRowSelectable: () => false }} />
        </RecordContextProvider>
      </Grid>
      <Grid item xs={12}>
        <RecordContextProvider value={{ id: participantId }}>
          <ParticipantSuspensionsCard filter={{ participantId }} />
        </RecordContextProvider>
      </Grid>
    </Grid>
  </ScoresheetProvider>
}
