import React from "react";
import { NumberInput, SimpleForm, TextInput, useRecordContext, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import { DialogContentText, Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { parseGameNumber, validateGameNumber } from "@hisports/scheduler";
import { getGameNumberValidationError } from '@hisports/scheduler/src/sequences/sequences';

import { useHttpClient } from "../../http";
import { ScheduleInput } from "../schedules/ScheduleInput";
import { ScheduleGroupInput } from "../groups/GroupInput";

const useStyles = makeStyles(theme => ({
  groups: {
    paddingBottom: theme.spacing(2)
  }
}))

const validate = values => {
  const errors = {};
  if (!values.scheduleId) errors.scheduleId = 'ra.validation.required';
  if (!values.start) errors.start = 'ra.validation.required';

  if (values.start && !validateGameNumber(values.start, values.scheduleId)) {
    const gameNumberError = getGameNumberValidationError(values.start, values.scheduleId);
    errors.start = `resources.schedulesequences.validations.${gameNumberError}`
  }

  if (values.max && values.max < 0) errors.max = 'ra.validation.positive';

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const InnerForm = ({ isSequenceUsed, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values: { start } } = useFormState();

  const maxPlaceholder = (start) => {
    if (!start) return '100';
    const { number } = parseGameNumber(start) || {};
    return `${number + 100}`;
  }

  return <Grid container spacing={1} fullWidth>
    {!props?.initialValues?.scheduleId && <Grid item xs={12}>
      <ScheduleInput source="scheduleId" disabled label="resources.schedulesequences.fields.scheduleId" {...inputProps} />
    </Grid>}
    <Grid item xs={12}>
      <ScheduleGroupInput source="groupId" disabled={isSequenceUsed} showNone={translate('ra.message.no_group')} className={classes.groups} {...inputProps} />
    </Grid>
    <Grid item xs={6}>
      <TextInput source="start" disabled={isSequenceUsed} placeholder="A100B" helperText={false} label="resources.schedulesequences.fields.start" {...inputProps} />
    </Grid>
    <Grid item xs={6}>
      <NumberInput source="max" placeholder={maxPlaceholder(start)} helperText={false} label="resources.schedulesequences.fields.max" {...inputProps} />
    </Grid>
  </Grid>
}

export const ScheduleSequenceForm = ({ prevalidate, ...props }) => {
  const translate = useTranslate()
  const sequence = useRecordContext()
  const { data: prevalidateData } = useHttpClient(`/schedulesequences/${sequence?.id}/prevalidate`, null, !prevalidate && !!sequence?.id);

  const isSequenceUsed = prevalidate?.isSequenceUsed || prevalidateData?.isSequenceUsed;

  return <>
    <DialogContentText>
      {translate('resources.schedulesequences.alerts.sequence_info')}
    </DialogContentText>
    {isSequenceUsed && <>
      <Alert severity="warning">
        {translate('resources.schedulesequences.alerts.sequence_in_use')}
      </Alert>
      <br />
    </>}
    <SimpleForm validate={validate} {...props}>
      <InnerForm isSequenceUsed={isSequenceUsed} {...props} />
    </SimpleForm>
  </>
}
