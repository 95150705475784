import React, { useState } from 'react';
import { Route, Redirect, Link, useHistory } from 'react-router-dom';
import { Stepper, Step, StepLabel, StepContent, Button, FormHelperText, styled } from '@material-ui/core';
import { FormInput as RAFormInput, TextInput, useLogin, useNotify, useTranslate } from 'react-admin';
import { Form, useFormState } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import qs from 'qs';
import Helmet from 'react-helmet';

import { useAccount, apiClient, useLoggedIn, useVolunteer } from '../http';
import PasswordInput from '../common/inputs/PasswordInput';
import { useWizard, withWizard } from '../common/Wizard';

import { LogoutButton } from './MyProfile/cards/AccountCard';
import BaseLayout from './BaseLayout';

const STEP_VERIFY = 0;
const STEP_ACCOUNT = 1;
const STEP_REVIEW = 2;

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const FormInput = ({ children, ...props }) =>
  <RAFormInput input={children} />

const Error = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
  margin: theme.spacing(1, 2),
}))

const Meta = ({ isLoggedIn }) => {
  const translate = useTranslate()
  if (isLoggedIn) return <LogoutButton />;
  return <>
    <Button type="link" component={Link} to="/reset">{translate('ra.input.password.forgot')}</Button>
    <Button type="link" component={Link} to="/login">{translate('ra.auth.login')}</Button>
  </>
}

const getInvitation = search => {
  const { invitation } = qs.parse(search.substring(1));
  return invitation;
}

const VerifyStep = ({ setStep, handleError, goBack, ...props }) => {
  const translate = useTranslate()
  const [ loading, setLoading ] = useState(false);

  const validateIdentity = values => {
    const errors = {}

    if (!values.participantId) errors.participantId = translate('ra.validation.required');
    if (!values.firstName) errors.lastName = translate('ra.validation.required');
    if (!values.lastName) errors.firstName = translate('ra.validation.required');

    return errors;
  }

  const verifyIdentity = ({ participantId, firstName, lastName }) => {
    setLoading(true)
    return apiClient('/accounts/validateIdentity', {
      method: "POST",
      data: { participantId, firstName, lastName },
      skipAuth: true,
    })
      .then(() => setStep(STEP_ACCOUNT))
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  useWizard(validateIdentity, verifyIdentity);

  return <>
    <FormInput>
      <TextInput
        source="participantId"
        label="components.register.participant"
        autoFocus
        options={{ InputProps: { placeholder: translate('ra.message.digits', { number: '6-14' }) } }}
        {...inputProps}
      />
    </FormInput>

    <FormInput>
      <TextInput
        source="firstName"
        label="resources.participants.fields.firstName"
        {...inputProps}
      />
    </FormInput>

    <FormInput>
      <TextInput
        source="lastName"
        label="resources.participants.fields.lastName"
        {...inputProps}
      />
    </FormInput>

    <Button type="submit" variant="contained" color="primary" disabled={loading}>Verify</Button>
  </>
}

const AccountStep = ({ setStep, handleError, goBack, invitation, ...props }) => {
  const translate = useTranslate()
  const validate = (values, translate) => {
    const errors = {}

    if (!values.email) errors.email = translate('ra.validation.required');
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    } else if (values.password.length < 8) {
      errors.password = translate('ra.validation.minLength', { min: 8 })
    } else if (!values.confirmPassword) {
      errors.confirmPassword = translate('ra.validation.required')
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = translate('ra.validation.match')
    }

    return errors;
  }

  const onSubmit = () => {
    setStep(STEP_REVIEW)
  }

  useWizard((values) => validate(values, translate), onSubmit);

  return <>
    <FormInput>
      <TextInput
        source="email"
        label="ra.input.field.email"
        type="email"
        options={{ InputProps: {
          placeholder: translate('ra.auth.your_username'),
          autoComplete: "email username"
        } }}
        {...inputProps}
      />
    </FormInput>

    <FormInput>
      <PasswordInput source="password" label="ra.auth.password" autoComplete="new-password" {...inputProps} />
    </FormInput>

    <FormInput>
      <PasswordInput source="confirmPassword" label="ra.input.password.confirm" autoComplete="new-password" {...inputProps} />
    </FormInput>

    <Button type="submit" variant="contained" color="primary">{translate('ra.action.continue')}</Button>
    {invitation == null && <Button onClick={goBack}>{translate('ra.action.back')}</Button>}
  </>
}

const ReviewStep = ({ setStep, handleError, goBack, invitation, ...props }) => {
  const translate = useTranslate();
  const history = useHistory();
  const notify = useNotify();
  const account = useAccount();
  const { values: registration } = useFormState();
  const [ loading, setLoading ] = useState(false)
  const login = useLogin();

  const validate = () => ({})

  const register = ({ participantId, firstName, lastName, email, password }) => {
    setLoading(true)
    return apiClient('/accounts/register', {
      method: "POST",
      data: {
        participantId,
        firstName,
        lastName,
        email,
        password,
        invitation,
      },
      skipAuth: !account,
    }).then(() => {
      if (account) {
        notify('Profile successfully linked')
        history.push('/profile');
        return;
      }
      return login({ type: 'member', username: email, password })
    })
      .catch(handleError)
      .finally(() => setLoading(false))
  }

  useWizard(validate, register);

  if (account) {
    return <>
      <p>{translate('components.register.messages.link')}</p>
      <p style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>{account.username}</p>

      <Button type="submit" variant="contained" color="primary" disabled={loading}>{translate('ra.action.continue')}</Button>

      <p>{translate('components.register.messages.correct_account')}</p>
    </>
  }

  return <>
    <p style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>{registration.email}</p>
    <p>{translate('components.register.messages.email_login')}</p>

    <Button type="submit" variant="contained" color="primary" disabled={loading}>{translate('ra.action.register')}</Button>
    <Button onClick={goBack} disabled={loading}>{translate('ra.action.change')}</Button>
  </>
}

const RegistrationForm = ({ isLoggedIn, invitation, ...props }) => {
  const translate = useTranslate();
  let initialState = STEP_VERIFY;
  if (invitation) {
    initialState = !isLoggedIn ? STEP_ACCOUNT : STEP_REVIEW;
  }
  const [ activeStep, setStep ] = useState(initialState);
  const { submitError } = useFormState();

  const goBack = () => {
    setStep(step => step - 1);
  }

  const handleError = error => {
    switch (error?.response?.data?.error?.name) {
      case 'account_exists': {
        const message = translate('components.register.validation.account_exists');
        if (invitation) return { [FORM_ERROR]: message }
        setStep(STEP_VERIFY);
        return { participantId: message };
      }

      case 'email_exists': {
        const message = translate('components.register.validation.account_exists'); ;
        if (invitation) return { [FORM_ERROR]: message }
        setStep(STEP_ACCOUNT);
        return { email: message };
      }

      case 'already_linked':
        return { [FORM_ERROR]: translate('components.register.validation.already_linked') };

      case 'participant_invalid':
        setStep(STEP_VERIFY);
        return { participantId: translate('components.register.validation.participant_invalid') };

      case 'participant_mismatch':
        setStep(STEP_VERIFY);
        return { participantId: translate('components.register.validation.participant_mismatch') };

      case 'ineligible':
        setStep(STEP_VERIFY);
        return { participantId: translate('components.register.validation.ineligible') };

      case 'ValidationError':
        if (Object.keys(error.details.messages).some(err => ['participantId', 'firstName', 'lastName'].includes(err))) {
          setStep(STEP_VERIFY)
        } else {
          setStep(STEP_ACCOUNT);
        }
        return error.details.messages;

      case 'token_invalid':
        return { [FORM_ERROR]: translate('components.register.validation.token_invalid') }

      default:
        return { [FORM_ERROR]: translate('ra.page.error_try_again_refresh') };
    }
  }

  return <>
    <Stepper activeStep={activeStep} orientation="vertical" {...props}>
      <Step disabled={!!invitation}>
        <StepLabel>{translate('components.register.hockey_identity')}</StepLabel>
        <StepContent>
          <VerifyStep setStep={setStep} handleError={handleError} goBack={goBack} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{translate('components.register.registration')}</StepLabel>
        <StepContent>
          <AccountStep setStep={setStep} handleError={handleError} goBack={goBack} invitation={invitation} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{!isLoggedIn ? translate('components.register.review') : translate('components.register.link_invitation')}</StepLabel>
        <StepContent>
          <ReviewStep setStep={setStep} handleError={handleError} goBack={goBack} invitation={invitation} />
        </StepContent>
      </Step>
    </Stepper>
    <Error>{submitError}</Error>
  </>
}

const BaseRegister = props => {
  const { validate, onSubmit } = useWizard();
  const invitation = getInvitation(props.location.search);

  const isLoggedIn = useLoggedIn();
  const isVolunteer = useVolunteer();
  if (isLoggedIn && (!invitation || isVolunteer)) return <Redirect to="/" />

  return <BaseLayout meta={<Meta isLoggedIn={isLoggedIn} />}>
    <Helmet title="Register" />
    <Form validate={validate} onSubmit={onSubmit} render={({ handleSubmit }) =>
      <form onSubmit={handleSubmit}>
        <RegistrationForm isLoggedIn={isLoggedIn} invitation={invitation} {...props} />
      </form>
    } />
  </BaseLayout>
}

const Register = withWizard(BaseRegister);

export default [
  <Route path="/register" exact component={Register} noLayout />
]
