import { useCallback, useEffect, useRef } from "react";
import { useNotify } from "react-admin";

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useAutoHideNotify = () => {
  const notify = useNotify();
  const timerRef = useRef(null);

  const showNotification = useCallback((message, type = 'info', duration = 5000) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    notify(message, type);

    timerRef.current = setTimeout(() => {
      timerRef.current = null;
    }, duration);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [notify]);

  return showNotification;
};
