import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { useFormState } from 'react-final-form'
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';

import { ParticipantInput } from '../participants/ParticipantInput';
import { ContactInput } from '../contacts/ContactInput';
import { TargetInput } from '../targets/TargetInput';
import { TargetGroupInput } from '../targets/TargetGroupInput';
import { CategoryInput } from '../categories/CategoryInput';

const OfficeAttributeGrid = props => {
  const { values } = useFormState();
  const { targetType, targetId } = values;

  if (targetType !== 'Office' || !targetId) return null;
  return <Grid {...props} />
}

const TeamSwitchInput = props => {
  const { values } = useFormState();
  const { targetType } = values;

  if (targetType !== 'Team') return null;
  return <SwitchInput {...props} />
}

const inputProps = {
  resource: 'notifications',
  basePath: '/notifications',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export default ({ showContact = true, ...props }) => {
  const { id, email, participantId } = props.initialValues || {}
  const legacy = id && email && !participantId

  return <SimpleForm toolbar={<Toolbar />} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <ParticipantInput source="participantId" autoFocus disabled={participantId} helperText="resources.notifications.helpers.participantId" {...inputProps} />
      </Grid>
      {showContact && !!participantId && <Grid item xs={12}>
        <ContactInput source="contactId" filter={{ participantId, type: 'Email' }} helperText="resources.notifications.helpers.contactId" {...inputProps} />
      </Grid>}
      {legacy && <Grid item xs={12}>
        <TextInput source="email" disabled helperText="resources.notifications.helpers.email" {...inputProps} />
      </Grid>}

      <Grid item xs={12} spacing={2}>
        <SwitchInput source="scheduling" defaultValue={false} helperText="resources.notifications.helpers.scheduling" format={Boolean} parse={Boolean} {...inputProps} />
        <TeamSwitchInput source="activities" defaultValue={false} helperText="resources.notifications.helpers.activities" format={Boolean} parse={Boolean} {...inputProps} />
        <SwitchInput source="assigning" defaultValue={false} helperText="resources.notifications.helpers.assigning" format={Boolean} parse={Boolean} {...inputProps} />
        <SwitchInput source="gameReport" defaultValue={false} helperText="resources.notifications.helpers.gameReport" format={Boolean} parse={Boolean} {...inputProps} />
        <SwitchInput source="majorPenalty" defaultValue={false} helperText="resources.notifications.helpers.majorPenalty" format={Boolean} parse={Boolean} {...inputProps} />
        <SwitchInput source="drafts" defaultValue={false} helperText="resources.notifications.helpers.drafts" format={Boolean} parse={Boolean} {...inputProps} />
      </Grid>

      <Grid item xs={12}>
        <TargetInput source="targetId" disabled {...inputProps} />
      </Grid>

      <OfficeAttributeGrid item xs={12}>
        <CategoryInput source="categoryIds" select multiple allowEmpty emptyLabel="All" helperText="resources.notifications.helpers.categoryIds" {...inputProps} />
      </OfficeAttributeGrid>
      <Grid item xs={12}>
        <TargetGroupInput source="groupIds" select multiple allowEmpty helperText="resources.notifications.helpers.groupIds" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
