import { KEEPERS_UPDATED, SCORESHEET_APPROVED } from "../actions";

const initialKeepersState = {
  scorekeeper: null,
  timekeeper: null
}

export default (state = initialKeepersState, action) => {
  switch (action.type) {
    case KEEPERS_UPDATED: {
      const { scorekeeper, timekeeper } = action.payload.event;
      return {
        scorekeeper: {
          name: scorekeeper,
          signature: state.scorekeeper && state.scorekeeper.signature || null,
        },
        timekeeper: {
          name: timekeeper,
          signature: state.timekeeper && state.timekeeper.signature || null,
        }
      }
    }

    case SCORESHEET_APPROVED: {
      const { scorekeeper, timekeeper } = action.payload.event;
      return {
        scorekeeper,
        timekeeper,
      }
    }

    default: {
      return state;
    }
  }
}
