import moment from "moment-timezone";
import { sortBy } from "lodash";

import { dedupe } from "@hisports/parsers";

export const getByes = (games, teamIds, schedule) => {
  if (!games.length || !schedule) return [];

  games = games.filter(game => game.date);
  if (!games.length) return [];

  games = sortBy(games, ["date", "startTime"]);

  const rotations = [];
  const startDate = moment.utc(schedule.startDate).format('YYYY-MM-DD');
  const endDate = moment.utc(games[games.length - 1].date).format('YYYY-MM-DD');
  const totalWeeks = moment(endDate).diff(startDate, 'weeks') + 1;

  for (let weekNumber = 0; weekNumber < totalWeeks; weekNumber++) {
    rotations.push({
      start: moment.utc(startDate, 'YYYY-MM-DD').add(weekNumber * 7, 'days').format('YYYY-MM-DD'),
      end: moment.utc(startDate, 'YYYY-MM-DD').add(weekNumber * 7 + 6, 'days').format('YYYY-MM-DD'),
    });
  }

  const byes = rotations.map(week => {
    const teamIds = [];
    games.forEach(game => {
      const areTeamsPlaying = moment.utc(game.date, 'YYYY-MM-DD').isBetween(week.start, week.end, 'day', '[]')
      if (!areTeamsPlaying) return;
      teamIds.push(game.homeTeamId, game.awayTeamId);
    })

    return {
      startDate: week.start,
      endDate: week.end,
      teamIds: dedupe(teamIds)
    }
  });

  return byes.map(bye => ({
    ...bye,
    teamIds: teamIds.filter(teamId => !bye.teamIds.includes(teamId))
  }))
}

export const getMatchups = (games, scheduleTeams, schedule) => {
  if (!games.length) return [];

  const teamIds = sortBy(scheduleTeams, ['group.name', 'order']).map(team => team.id);
  const byes = schedule ? getByes(games, teamIds, schedule) : [];

  const teams = teamIds.reduce((teams, teamId) => {
    const team = scheduleTeams.find(scheduleTeam => scheduleTeam.id === teamId);
    teams[teamId] = {
      groupId: team && team.group && team.group.id,
      totalOpponents: 0,
      opponents: teamIds.reduce((ids, id) => (ids[id] = { teamId: id, home: 0, away: 0 }, ids), {}),
      conflicts: 0,
      byes: byes.reduce((total, bye) => (bye.teamIds.includes(teamId) && total++, total), 0),
      home: 0,
      away: 0
    };
    return teams
  }, {})

  games.forEach(({ homeTeamId, awayTeamId, status }) => {
    const updateStats = (team, type, checkStatus) => {
      team[type] += 1;
      if (checkStatus && status === "Conflict") {
        team.conflicts += 1;
      }
    };

    if (homeTeamId) {
      updateStats(teams[homeTeamId], "home", true);
    }

    if (awayTeamId) {
      updateStats(teams[awayTeamId], "away", true);
    }

    if (homeTeamId && awayTeamId) {
      const homeOpponent = teams[homeTeamId].opponents[awayTeamId];
      const awayOpponent = teams[awayTeamId].opponents[homeTeamId];

      if (homeOpponent) {
        updateStats(homeOpponent, "home");
      }

      if (awayOpponent) {
        updateStats(awayOpponent, "away");
      }
    }
  });

  // get total opponents for each team
  Object.values(teams).forEach((team) => {
    const opponents = Object.values(team.opponents);

    opponents.forEach((opponent) => {
      if (opponent.home || opponent.away) {
        team.totalOpponents += 1;
      }
    });
  });

  return Object.entries(teams).map(([teamId, team]) => ({
    teamId: Number(teamId),
    totalOpponents: team.totalOpponents,
    opponents: Object.entries(team.opponents).map(([opponentId, opponentData]) => ({
      teamId: Number(opponentId),
      home: opponentData.home,
      away: opponentData.away
    })),
    conflicts: team.conflicts,
    byes: team.byes,
    home: team.home,
    away: team.away,
    groupId: team.groupId
  }));
}

export const getOfficeMatchups = (games, teams) => {
  if (!games.length) return [];

  const teamOfficeMatchups = teams.map(team => {
    const officeMatchups = games.reduce((matchups, game) => {
      if (![game.homeTeamId, game.awayTeamId].includes(team.id)) return matchups;

      if (game.homeTeamId === team.id) {
        const awayTeam = teams.find(team => team.id === game.awayTeamId);
        if (awayTeam) {
          matchups[awayTeam.officeId] = (matchups[awayTeam.officeId] || 0) + 1;
        }
      }

      if (game.awayTeamId === team.id) {
        const homeTeam = teams.find(team => team.id === game.homeTeamId);
        if (homeTeam) {
          matchups[homeTeam.officeId] = (matchups[homeTeam.officeId] || 0) + 1;
        }
      }

      return matchups;
    }, {})

    return {
      teamId: team.id,
      officeMatchups,
    }
  })

  return teamOfficeMatchups;
}


