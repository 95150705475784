import { clone } from 'lodash';

import {
  LINEUP_UPDATED,
  GAME_STARTED,
  GOALIE_CHANGED,
  GOALIE_CHANGE_EDITED,
  GOALIE_CHANGE_DELETED,
  GOALIE_SHOTS_UPDATED,
  SETTINGS_UPDATED,
  SCORESHEET_RESET
} from '../actions';

import { isGoalie, append, replace, removeById, isTimeEqual, byTeamId } from '../util';
import { SCHEDULED } from '../constants';

const toGoalieChange = (event, meta = {}) => ({
  ...meta,
  ...event
})

const isStarter = m => isGoalie(m) && m.isStarter;

const getStarters = scoresheet => {
  const { lineups, settings: { periods } } = scoresheet;
  const gameStart = periods[0];
  return Object.keys(lineups)
    .map(teamId => lineups[teamId])
    .map(lineup => {
      // select starter or fallback to first goalie found
      const starter = lineup.members.find(isStarter);
      const fallback = lineup.members.find(isGoalie);
      return {
        teamId: lineup.teamId,
        goalie: starter || fallback
      }
    })
    .map(({ teamId, goalie }) => ({
      teamId,
      onParticipantId: goalie && goalie.participantId,
      isStarter: true,
      gameTime: clone(gameStart),
    }))
}

export const shots = (state = [], action) => {
  if (action.type !== GOALIE_SHOTS_UPDATED) return state;
  // insert/replace goalie shot changes (event may not contain all goalies)
  return action.payload.event.goalies.reduce(
    (goalies, goalie) => replace(goalies, goalie.participantId, goalie, 'participantId')
    , state);
}

export default (state = [], action, scoresheet, sport) => {
  switch (action.type) {
    case GAME_STARTED: {
      return getStarters(scoresheet)
    }

    case LINEUP_UPDATED:
    case SETTINGS_UPDATED: {
      if (scoresheet.status === SCHEDULED) return state;

      const starters = getStarters(scoresheet);
      const changes = state.filter(goalie => !goalie.isStarter);

      return [
        ...starters,
        ...changes
      ]
    }

    case GOALIE_CHANGED: {
      const { event } = action.payload;

      // changes for the same team/time are treated as edits instead of append
      const existing = state.find(change =>
        change.teamId == event.teamId && isTimeEqual(change.gameTime, event.gameTime, sport)
      )
      if (existing && existing.isStarter) {
        // starters don't have an id and need to be handled differently
        const index = state.findIndex(change => change.isStarter && (change.teamId == event.teamId))
        return [
          ...state.slice(0, index),
          toGoalieChange(event, { isStarter: true }),
          ...state.slice(index + 1),
        ]
      } else if (existing) {
        return replace(state, existing.id, toGoalieChange(event, {
          id: existing.id,
        }))
      }

      return append(state, {
        id: action.payload.id,
        teamId: event.teamId,
        onParticipantId: event.onParticipantId || null,
        gameTime: event.gameTime,
      })
    }

    case GOALIE_CHANGE_EDITED: {
      const { event } = action.payload;

      return replace(state, event.goalieChangeId, toGoalieChange(event, {
        id: event.goalieChangeId,
      }));
    }

    case GOALIE_CHANGE_DELETED: {
      const { event } = action.payload;

      // protect starters from being deleted
      const existing = state.find(change => change.id == event.goalieChangeId);
      if (existing && existing.isStarter) {
        const starters = getStarters(scoresheet)
        const starter = starters.find(byTeamId(existing.teamId))
        return replace(state, event.goalieChangeId, starter);
      }

      return removeById(state, event.goalieChangeId)
    }

    case SCORESHEET_RESET: {
      return [];
    }

    default:
      return state;
  }
}
