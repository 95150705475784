import { uniqBy } from 'lodash';

import { appendMany, removeById, replace, append, isSkater, isGoalie, isStaff, isPlayer, isNotSuspended, removeByIds } from '../util';

import {
  LINEUP_UPDATED,
  ADD_MEMBER_TO_LINEUP,
  ADD_MEMBERS_TO_LINEUP,
  REMOVE_MEMBER_FROM_LINEUP,
  REMOVE_MEMBERS_FROM_LINEUP,
  LINEUP_EXTRA_ADDED,
  LINEUP_EXTRA_REMOVED,
  CLEAR_LINEUP,
  RESTORE_LINEUP,
  LINEUP_APPROVED,
  EDIT_LINEUP_MEMBER,
  THROWS_INNINGS_UPDATED,
  SCORESHEET_RESET,
} from '../actions';

const LOAD_GAME = 'LOAD_GAME';

const initialState = {
  teamId: null,
  members: [],
  extras: [],
  approval: null
}

export const throwsInnings = (state = [], action) => {
  if (action.type === SCORESHEET_RESET) return [];
  if (action.type !== THROWS_INNINGS_UPDATED) return state;
  const { throwsInnings, teamId } = action.payload.event

  const hasThrowsInnings = player => player.throws != null || player.inningsPitched != null

  return [...throwsInnings.filter(hasThrowsInnings), ...state.filter(player => player.teamId != teamId)]
}

const selectStarter = (members, participantId, override) => {
  const goalies = members.filter(member => isGoalie(member) && isNotSuspended(member));
  const currentStarter = goalies.find(member => member.isStarter && isNotSuspended(member));

  let nextStarter;
  if (currentStarter) {
    nextStarter = currentStarter.participantId;
  }
  if (participantId && (!currentStarter || override)) {
    nextStarter = participantId;
  }
  if (!nextStarter && goalies.length > 0) {
    nextStarter = goalies[0].participantId;
  }

  return members.map(member => {
    if (!isGoalie(member)) {
      member.isStarter = false;
    } else {
      member.isStarter = member.participantId === nextStarter;
    }
    return member;
  })
}

const lineup = (state = initialState, action, teamId) => {
  switch (action.type) {
    case LOAD_GAME: {
      return {
        ...state,
        teamId
      }
    }

    case LINEUP_UPDATED: {
      const lineup = action.payload.event;

      let members = uniqBy(lineup.members, 'participantId')
      members = selectStarter(members);

      return {
        ...state,
        ...lineup,
        members,
      }
    }

    case ADD_MEMBER_TO_LINEUP: {
      const { member } = action.payload;
      if (!member) return state;

      let members = replace(state.members, member.participantId, member, 'participantId')
      members = selectStarter(members, member.participantId);

      return {
        ...state,
        teamId,
        members,
      }
    }

    case REMOVE_MEMBER_FROM_LINEUP: {
      const { member } = action.payload;
      if (!member) return state;

      let members = removeById(state.members, member.participantId, 'participantId')
      members = selectStarter(members);

      return {
        ...state,
        members,
      }
    }

    case EDIT_LINEUP_MEMBER: {
      const { member } = action.payload;
      if (!member) return state;

      const isStarter = isGoalie(member) && member.isStarter && isNotSuspended(member);
      let members = replace(state.members, member.participantId, member, 'participantId')
      members = selectStarter(members, isStarter ? member.participantId : undefined, isStarter);

      return {
        ...state,
        members,
      }
    }

    case ADD_MEMBERS_TO_LINEUP: {
      let members = appendMany(state.members, action.payload.members, 'participantId');
      members = selectStarter(members);

      return {
        ...state,
        members,
      }
    }

    case REMOVE_MEMBERS_FROM_LINEUP: {
      let { members } = action.payload;
      if (!members) return state;

      const memberIds = members.map(member => member.participantId)
      members = removeByIds(state.members, memberIds, 'participantId')
      members = selectStarter(members)

      return {
        ...state,
        members,
      }
    }

    case LINEUP_EXTRA_ADDED: {
      return {
        ...state,
        extras: append(state.extras, action.payload.event.member, 'participantId'),
      }
    }

    case LINEUP_EXTRA_REMOVED: {
      let members = removeById(state.members, action.payload.event.participantId, 'participantId')
      members = selectStarter(members);

      return {
        ...state,
        members,
        extras: removeById(state.extras, action.payload.event.participantId, 'participantId'),
      }
    }

    case CLEAR_LINEUP: {
      const { type } = action.payload;
      const members = !type ? [] : state.members.filter(member => {
        if (type === 'Player') return !isPlayer(member);
        if (type === 'Skater') return !isSkater(member);
        if (type === 'Goalie') return !isGoalie(member);
        if (type === 'Staff') return !isStaff(member);
        return true;
      })
      return {
        ...state,
        members,
      }
    }

    case RESTORE_LINEUP: {
      return action.payload.lineup;
    }

    case LINEUP_APPROVED: {
      const { event, timestamp } = action.payload;
      return {
        ...state,
        approval: {
          timestamp,
          ...event
        }
      }
    }

    default: {
      return state;
    }
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_GAME: {
      const { homeTeamId, awayTeamId } = action.payload;
      return {
        [homeTeamId]: lineup(state[homeTeamId], action, homeTeamId),
        [awayTeamId]: lineup(state[awayTeamId], action, awayTeamId)
      }
    }

    case LINEUP_UPDATED:
    case LINEUP_APPROVED:
    case LINEUP_EXTRA_ADDED:
    case LINEUP_EXTRA_REMOVED: {
      const { teamId } = action.payload.event;
      return {
        ...state,
        [teamId]: lineup(state[teamId], action, teamId)
      }
    }

    case ADD_MEMBER_TO_LINEUP:
    case ADD_MEMBERS_TO_LINEUP:
    case EDIT_LINEUP_MEMBER:
    case REMOVE_MEMBER_FROM_LINEUP:
    case REMOVE_MEMBERS_FROM_LINEUP:
    case RESTORE_LINEUP:
    case CLEAR_LINEUP: {
      const { teamId } = action.payload;
      return {
        ...state,
        [teamId]: lineup(state[teamId], action, teamId)
      }
    }

    case SCORESHEET_RESET: {
      return {};
    }

    default:
      return state;
  }
}
