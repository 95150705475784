import { GET_LIST, useQuery } from "react-admin"

export const useBranchSettings = officeId => {
  const { data, loading, error, refetch } = useQuery({
    type: GET_LIST,
    resource: 'effectivebranchofficesettings',
    payload: {
      filter: { officeId, effectiveOffices: false },
    } }, {
    enabled: officeId != null,
  })

  return { data: data?.[0], loading, error, refetch }
}
