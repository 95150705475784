import React, { Fragment, useMemo, useState } from "react";
import { RecordContextProvider, ResourceContextProvider, SimpleForm, useNotify, useRefresh, useTranslate } from "react-admin";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@material-ui/core";
import { DoneAll } from "@material-ui/icons";

import { apiClient } from "../../http";
import { DialogFormToolbar } from "../../common/dialogs/DialogForm";
import { SelectableListView } from "../../common/dialogs/SelectableListView";
import { useSeason } from "../../common/inputs/SeasonSelector";
import { DateInput } from "../../common/inputs/DateInput";
import { EventViewSettingsContext, EventViewSettingsMenu, GAME_LIST_FILTERS, useEventSort, useReverseHomeAway } from "../events/EventViewSettings";
import { BaseEventView } from "../events/EventView";
import { TeamInput } from "../teams/TeamInput";
import { gameFilter } from "./DraftGameShareModal";

const validate = ({ selectedIds = [] }) => {
  const errors = {}

  if (!selectedIds.length) {
    errors.selectedIds = 'ra.validation.required'
  }

  return errors
}

const EventViewSettingsProvider = ({ ...props }) => {
  const groupDates = useState(true);
  const groupArenas = useState(false);
  const groupRounds = useState(false);
  const assignments = useState(false);
  const gameInfo = useState(false);
  const flags = useState(false);
  const reverseHomeAway = useReverseHomeAway()
  const calendar = useState(false);
  const teamEvents = useState(false);
  const slots = useState(true);
  const availabilities = useState(false);
  const surfaceSizes = useState([]);
  const gameListFilter = useState(GAME_LIST_FILTERS.ALL_GAMES);
  const value = useMemo(() => ({
    calendar,
    teamEvents,
    slots,
    availabilities,
    gameInfo,
    flags,
    reverseHomeAway,
    groupDates,
    groupArenas,
    groupRounds,
    assignments,
    surfaceSizes,
    gameListFilter
  }), [ calendar, teamEvents, slots, availabilities, gameInfo, flags, reverseHomeAway, groupDates, groupArenas, groupRounds, assignments, surfaceSizes, gameListFilter ])
  return <EventViewSettingsContext.Provider value={value} {...props} />
}

const PublishDraftGameForm = ({ schedule, office, ...props }) => {
  const localSort = useEventSort();
  const seasonId = useSeason();

  const filters = [
    <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ scheduleId: schedule?.id, seasonId: schedule?.seasonId }} variant="outlined" multiple allowEmpty alwaysOn />,
    <DateInput source="startTime" label="resources.draftGames.filters.startTime" variant="outlined" alwaysOn />,
    <DateInput source="endTime" label="resources.draftGames.filters.endTime" variant="outlined" alwaysOn />,
  ]
  const actions = [
    <EventViewSettingsMenu
      disableCalendar
      disableAssignments
      disableGroupRounds={false}
      disableGroupArenas={false}
      alwaysOn
      showTeamEvents={false}
      showSlots={false}
      showAvailabilties={false}
      showSurfaceSizes={false}
      showFlagsInput={false}
      showRenumber={false}
      showGameInfoInput={false}
    />
  ]

  // query only non published draft games and non draft updates
  const filter = { ...gameFilter(office, schedule), seasonId, pastEvents: true, isPublished: false, updatedGameId: null }

  // filter out unslotted draft games from the list
  const localFilterCallback = draftGame => {
    return draftGame.arenaId && draftGame.startTime && draftGame.endTime
  }

  return <SimpleForm {...props}>
    <SelectableListView
      filter={filter}
      localSort={localSort}
      localFilterCallback={localFilterCallback}
      filters={filters}
      actions={actions}
      initialSelectAll
    >
      <BaseEventView
        rowClick="toggleSelection"
        isRowSelectable={() => true}
        hasBulkActions
        disableCalendar
        disableAssignments
        hideStatus
        component={Fragment}
      />
    </SelectableListView>
  </SimpleForm>
}

export const PublishDraftGameDialog = ({ isOpen, onClose, schedule, office, ...props }) => {
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const refresh = useRefresh();
  const notify = useNotify();

  const onSubmit = ({ selectedIds } = {}) => {
    const url = office ? `/offices/${office.id}/publish` : `/schedules/${schedule.id}/publish`;
    apiClient(url, {
      method: 'POST',
      data: {
        draftGameIds: selectedIds
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        const failed = data?.failed || 0;
        const invalid = data?.invalid || 0;

        refresh();
        notify(translate('resources.draftGames.notifications.published_games', count))

        if (failed) {
          notify(translate('resources.draftGames.notifications.publish_failed', failed), 'error');
        }
        if (invalid) {
          notify(translate('resources.draftGames.notifications.invalid_games', invalid), 'error')
        }
      })
      .catch(err => {
        const name = err?.response?.data?.error?.name
        if (['missing_sequence', 'sequence_exhausted'].includes(name)) {
          notify(translate(`resources.draftGames.notifications.${name}`), 'error')
        } else {
          notify(translate('resources.draftGames.notifications.published_games_error', selectedIds.length), 'error')
        }
      })
    onClose()
  }

  return <Dialog open={isOpen} fullWidth maxWidth="md" fullScreen={fullScreen} onClose={onClose}>
    <DialogTitle>{translate('resources.draftGames.labels.publish')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.draftGames.messages.publish_help')}
      </DialogContentText>
      <ResourceContextProvider value="draftGames">
        <RecordContextProvider value={null}>
          <EventViewSettingsProvider>
            <PublishDraftGameForm schedule={schedule} office={office} save={onSubmit} component={Fragment} validate={validate} validateOnBlur toolbar={
              <DialogFormToolbar submitLabel="ra.action.publish" onCancel={onClose} disableInvalidSubmit />
            } />
          </EventViewSettingsProvider>
        </RecordContextProvider>
      </ResourceContextProvider>
    </DialogContent>
  </Dialog>
}

export const PublishDraftGamesAction = ({ size = "small", ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);

  const handleClick = () => {
    setOpen(true);
  }

  return <>
    <Button color="primary" size={size} startIcon={<DoneAll />} onClick={handleClick}>{translate('ra.action.publish')}</Button>
    {isOpen && <PublishDraftGameDialog isOpen={isOpen} onClose={() => setOpen(false)} {...props} />}
  </>
}
