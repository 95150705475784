import React, { useState } from 'react';
import { ReferenceField, useLocale, useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import moment from 'moment-timezone';
import { IconButton, Tooltip, Badge, makeStyles, Button, Dialog, DialogTitle, List, ListItem, ListItemText, ListItemIcon, Chip } from '@material-ui/core';
import {
  AssignmentOutlined as AssignmentIcon,
  NotificationsOffOutlined as DraftIcon,
  AssignmentInd as SelfAssignmentIcon,
  Assignment as ScoresheetIcon,

  FlagOutlined as RequestedIcon,
  MoreHoriz as PendingIcon,
  Check as AssignedIcon,
  DoneAll as ConfirmedIcon,
  NotInterested as DeclinedIcon,
  LocationOff as NoShowIcon,

  People as LineupIcon,
  Check as CompleteIcon,
  Flag as CertifyIcon,
} from '@material-ui/icons';

import FunctionField from '../../common/fields/FunctionField'
import { isAuthorized } from '../../common/Authorize';

import { TeamField } from '../teams/TeamField';

import { useGameContext } from './GameContext';

const getGameConflict = game => {
  if (game?.isApproved) return 'resources.games.messages.status.completed';
  switch (game.status) {
    case 'Conflict': return 'resources.games.messages.status.conflict';
    case 'Cancelled': return 'resources.games.messages.status.cancelled';
    case 'Postponed': return 'resources.games.messages.status.postponed';
    default: break;
  }
}

const useBadgeStyles = makeStyles(theme => ({
  badge: {
    border: '2px solid #fff',
    maxWidth: '1em',
    '& > .MuiSvgIcon-root': {
      fontSize: '1.25em',
      color: 'white'
    },
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  }
}))
const IconBadge = ({ icon, badge, color = "secondary" }) => {
  const classes = useBadgeStyles();
  return <Badge overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={badge} color={badge ? color : undefined} classes={badge ? classes : undefined}>
    {icon}
  </Badge>
}

const useStatusStyles = makeStyles(theme => ({
  root: {
    display: 'inline',
    textAlign: 'right',
  },
}))
const StatusIcon = ({ title, disabled, onClick, ...icon }) => {
  const classes = useStatusStyles();
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    if (typeof onClick === 'function') onClick();
  }

  return <div className={classes.root}>
    <Tooltip title={title}><span>
      <IconButton disabled={disabled} onClick={handleClick}>
        <IconBadge classes={{ badge: classes.badge }} {...icon} />
      </IconButton></span>
    </Tooltip>
  </div>
}

const AssignStatus = ({ game, status }) => {
  const translate = useTranslate();
  const setGame = useGameContext();

  if (!status) return null;

  const openDialog = () => setGame({ gameId: game.id, type: 'assign' })

  const isActive = status?.status === 'Active';
  const isConflict = game && ['Cancelled', 'Conflict', 'Postponed'].includes(game.status);

  const R = translate(`resources.games.values.assignment.position_short.Referee`)
  const L = translate(`resources.games.values.assignment.position_short.Linesperson`)

  const tooltip = [];
  if (isActive) {
    tooltip.push(`${status.referees}${R}/${status.linespersons}${L} ${translate('resources.games.labels.assignment.status.confirmed').toLowerCase()}.`)
  } else {
    tooltip.push(`${status.status}.`)
  }

  const selfIcon = <SelfAssignmentIcon />
  const assignmentIcon = isActive ? <AssignmentIcon /> : <DraftIcon />

  if (status.self === 'requested' && !status.allAssigned) return <StatusIcon title={translate('resources.games.notifications.assignment.status.assignee.requested')} icon={assignmentIcon} badge={<RequestedIcon />} onClick={openDialog} />
  if (status.self === 'assigned') return <StatusIcon title={translate('resources.games.notifications.assignment.status.assignee.pending')} icon={selfIcon} badge="!" onClick={openDialog} />
  if (status.self === 'confirmed') return <StatusIcon title={translate('resources.games.notifications.assignment.status.assignee.confirmed')} icon={selfIcon} badge={<AssignedIcon />} color="success" onClick={openDialog} />
  if (status.self === 'declined') return <StatusIcon title={translate('resources.games.notifications.assignment.status.assignee.declined')} icon={selfIcon} badge={<DeclinedIcon />} color="error" onClick={openDialog} />
  if (status.self === 'no-show') return <StatusIcon title={translate('resources.games.labels.assignment.status.no_show')} icon={selfIcon} badge={<NoShowIcon />} color="error" onClick={openDialog} />

  const canAssign = isAuthorized(game, 'games', 'assign')
  if (isConflict && !canAssign) return <StatusIcon title={`Officials - ${translate(getGameConflict(game))}`} icon={assignmentIcon} disabled />;

  if (canAssign && status.declined > 0) {
    tooltip.unshift(`${status.declined} ${translate('resources.games.labels.assignment.status.declined').toLowerCase()}.`)
    return <StatusIcon title={tooltip.join('\n')} icon={assignmentIcon} badge={<DeclinedIcon />} color="error" onClick={openDialog} />
  }

  if (status.allConfirmed) return <StatusIcon title={translate('resources.games.messages.assignment.all_assignments_confirmed')} icon={assignmentIcon} badge={<ConfirmedIcon />} color="success" onClick={openDialog} />

  if (status.allAssigned) {
    tooltip.unshift(`${status.pending} ${translate('resources.games.labels.assignment.status.pending').toLowerCase()}.`)
    const badge = isActive ? <AssignedIcon /> : <PendingIcon />
    return <StatusIcon title={tooltip.join('\n')} icon={assignmentIcon} badge={badge} onClick={openDialog} />
  }

  const isUnassigned = !Number(status.referees) && !Number(status.linespersons) && !Number(status.supervisors)
  if (isConflict && isUnassigned) return <StatusIcon title={`${translate('resources.officials.name', 2)} - ${translate(getGameConflict(game))}`} icon={assignmentIcon} disabled />

  if (canAssign && status.requests > 0) {
    tooltip.unshift(`${status.unassigned} ${translate('resources.games.labels.assignment.unassigned').toLowerCase()} - ${status.requests} ${translate('resources.games.labels.assignment.status.requested').toLowerCase()}.`)
  } else {
    tooltip.unshift(`${status.unassigned} ${translate('resources.games.labels.assignment.unassigned').toLowerCase()}.`)
  }

  return <StatusIcon title={tooltip.join('\n')} icon={assignmentIcon} badge={status.unassigned} onClick={openDialog} />
}

const TeamSelectDialog = ({ status, teamIds, onClose }) => {
  const translate = useTranslate();
  const handleClose = (e, teamId) => {
    e.preventDefault();
    e.stopPropagation();
    onClose(teamId)
  }

  return <Dialog open fullWidth maxWidth="sm" onClose={e => handleClose(e)}>
    <DialogTitle>{translate('resources.games.labels.select_team')}</DialogTitle>
    <List>
      {teamIds.map(teamId => {
        const approved = status[teamId]?.approved;
        const badge = approved ? <CompleteIcon /> : '!';
        const color = approved ? 'success' : 'secondary'
        return <ListItem button onClick={e => handleClose(e, teamId)} key={teamId}>
          <ListItemIcon>
            <IconBadge icon={<LineupIcon />} badge={badge} color={color} />
          </ListItemIcon>
          <ListItemText primary={<TeamField record={{ id: teamId }} source="id" link={false} />} />
        </ListItem>
      })}
    </List>
  </Dialog>
}

const LineupStatus = ({ game, status }) => {
  const translate = useTranslate();
  const setGame = useGameContext();
  const [ dialogOpen, setDialogOpen ] = useState(null)
  if (!status) return null;

  const conflict = getGameConflict(game);
  if (conflict) return <StatusIcon title={`${translate('resources.games.labels.lineup.name')} - ${translate(conflict)}`} icon={<LineupIcon />} disabled />

  const teamIds = Object.keys(status).map(teamId => parseInt(teamId, 10));
  const approved = teamIds.map(teamId => status[teamId]).every(lineup => lineup.approved);

  const openDialog = teamId => {
    if (dialogOpen) setDialogOpen(false);
    if (!teamId) return;
    setGame({ gameId: game.id, type: 'lineup', teamId })
  }

  const handleClick = () => {
    if (teamIds.length === 1) return openDialog(teamIds[0]);
    setDialogOpen(true)
  }

  let button;
  const upcoming = moment().isSameOrAfter(moment.tz(game.startTime, game.timezone).subtract(2, 'days'), 'day') && moment().isBefore(moment.tz(game.endTime, game.timezone).endOf('day'), 'hour')
  if (approved) {
    button = <StatusIcon title={translate('resources.games.labels.lineup.approved')} icon={<LineupIcon />} badge={<CompleteIcon />} color="success" onClick={handleClick} />
  } else if (upcoming) {
    button = <StatusIcon title={translate('resources.games.labels.lineup.incomplete')} icon={<LineupIcon />} badge="!" onClick={handleClick} />
  } else {
    button = <StatusIcon title={translate('resources.games.labels.lineup.game')} icon={<LineupIcon />} onClick={handleClick} />
  }

  return <>
    {button}
    {dialogOpen && <TeamSelectDialog status={status} teamIds={teamIds} onClose={openDialog} />}
  </>
}

export const ReportButton = ({ label = "resources.reports.name", ...props }) => {
  const game = useRecordContext(props);
  const locale = useLocale();
  const translate = useTranslate()

  if (!game?.isApproved) return null;
  return <Button color="primary" variant="contained" size="small" startIcon={<ScoresheetIcon fontSize="small" />} href={`${process.env.PDF_URL}/game/${game.id}?locale=${locale}`} target="_blank" onClick={e => e.stopPropagation()}>
    {translate(label, { _: label, smart_count: 1 })}
  </Button>
}

const CertifyStatus = ({ flags = [], ...props }) => {
  const game = useRecordContext()
  const translate = useTranslate()
  const setGame = useGameContext();

  const openDialog = () => setGame({ gameId: game.id, type: 'certify' })

  const badge = flags.length > 0 ? '!' : <CompleteIcon />
  const color = flags.length > 0 ? 'error' : 'secondary'

  return <StatusIcon title={translate('resources.games.labels.certify_game')} icon={<CertifyIcon />} badge={badge} color={color} onClick={openDialog} />
}

const useFieldStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      flexDirection: 'column',
    }
  }
}))

export const StatusField = props => {
  const game = useRecordContext(props);
  const classes = useFieldStyles();
  const translate = useTranslate();

  if (!game) return null;
  if (!['Cancelled', 'Conflict', 'Postponed'].includes(game.status)) return null;
  return <span className={classes.root}>
    <Chip label={translate(`resources.games.values.status.${game.status}`, { _: game.status })} size="small" color="secondary" />
  </span>
}

export const GameStatusField = props => {
  const game = useRecordContext(props);
  const resource = useResourceContext(props)
  const translate = useTranslate();
  const classes = useFieldStyles();

  if (!game) return null;

  if (['Cancelled', 'Conflict', 'Postponed'].includes(game.status)) {
    return <StatusField {...props} />
  }

  if (resource !== 'games' || (game.type && game.type !== 'Game')) return null;
  return <ReferenceField {...props} basePath="/gameStatuses" source="id" resource="gameStatuses" reference="gameStatuses" link={false}>
    <FunctionField render={({ lineups, officials, flags }) => {
      if (game.isApproved) {
        if (flags && !game.isCertified) {
          return <CertifyStatus flags={flags} {...props} />
        }

        return <span className={classes.root}>
          <ReportButton {...props} label={translate('resources.reports.name', 1)} />
        </span>
      }

      return <span className={classes.root}>
        <LineupStatus game={game} status={lineups} />
        <AssignStatus game={game} status={officials} />
      </span>
    }
    } />
  </ReferenceField>
}
