import React from 'react';
import { EditButton, useRecordContext, useTranslate } from 'react-admin';
import { Table, TableBody, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';

import { useFlag, usePermissions } from '../../../http';
import { getFilteredScopes } from '../../../http/restClient/response';
import FunctionField from '../../../common/fields/FunctionField'
import UserTextField from '../../../common/fields/UserTextField';
import InfoCard from '../../../common/cards/InfoCard';
import Row from '../../../common/cards/TableRow';
import TimeRangeField from '../../../common/fields/TimeRangeField';
import { isAuthorized } from '../../../common/Authorize';

import { useIsHomeFirst } from '../../events/EventViewSettings';
import { CategoryField } from '../../categories/CategoryField';
import { ScheduleField } from '../../schedules/ScheduleField';
import { GroupField } from '../../groups/GroupField';
import { TeamField } from '../../teams/TeamField';
import { SurfaceField } from '../../surfaces/SurfaceField';
import { SeasonField } from '../../seasons/SeasonField';

import GameForfeitModal from '../GameForfeitModal';
import GameStatusModel from '../GameStatusModel';
import { GameStatusIcon } from '../GameNumberField';
import DraftUpdateModal from '../DraftUpdateModal';
import { OfficeField } from '../../offices/OfficeField';

export const GameEditButton = ({ game, ...props }) => {
  const translate = useTranslate();
  if (game?.isApproved || game?.isCertified) {
    return <Tooltip title={translate("resources.games.actions.edit_completed")}>
      <div>
        <EditButton label="resources.games.actions.edit" record={game} disabled />
      </div>
    </Tooltip>
  }
  return <EditButton label="resources.games.actions.edit" record={game} />
}

export const GameStatusField = ({ gameStatus }) =>
  <FunctionField render={(game, source, resource, translate) => {
    if (gameStatus?.status === 'forfeited') return translate('resources.games.values.status.Forfeited');
    if (gameStatus?.status === 'double-forfeit') return translate('resources.games.values.status.DoubleForfeit');

    if (game.isCertified) return translate('resources.games.values.status.Certified');
    if (game.isApproved) return translate('resources.games.values.status.Completed');
    return <>
      {translate(`resources.games.values.status.${game.status}`, { _: game.status })}
      <GameStatusIcon game={game} />
    </>
  }} />


export default ({ status, ...props }) => {
  const game = useRecordContext(props);
  const isHomeFirst = useIsHomeFirst();
  const isEnabled = useFlag();
  const homeTeam = useSelector(store => store.admin.resources.teams.data[game?.homeTeamId]);
  const permissions = usePermissions();

  const { scorekeeping = false, status: gameStatus, rescheduleRequests } = status || {};

  const filteredScopes = getFilteredScopes(permissions, game?.officeId, game?.scheduleId, homeTeam?.officeId);

  const canEditGame = isAuthorized(filteredScopes, 'games', 'edit')
  const canChangeStatus = isAuthorized(filteredScopes, 'games', 'changeStatus') && !game.isApproved;
  const canForfeitGame = scorekeeping && isAuthorized(filteredScopes, 'games', 'forfeit') && !['forfeited', 'double-forfeit'].includes(gameStatus) && !!game.homeTeamId && !!game.awayTeamId;
  const canRequestChanges = isEnabled(FF_DRAFT_GAMES) && rescheduleRequests && isAuthorized(game, 'games', 'approve', false, false);
  const canDraftUpdate = isEnabled(FF_DRAFT_GAMES) && isAuthorized(game, 'games', 'create');

  const homeTeamRow = <Row label="resources.games.fields.homeTeamId">
    <TeamField source="homeTeamId" authMethod="gameTeams" includeId="full" includeCategory="full" />
  </Row>
  const awayTeamRow = <Row label="resources.games.fields.awayTeamId">
    <TeamField source="awayTeamId" authMethod="gameTeams" includeId="full" includeCategory="full" />
  </Row>

  return <InfoCard
    title="resources.games.name"
    actions={[
      canEditGame && <GameEditButton game={game} />,
      canChangeStatus && <GameStatusModel />,
      canForfeitGame && <GameForfeitModal />,
      canDraftUpdate && <DraftUpdateModal />,
      canRequestChanges && <DraftUpdateModal requestChanges={canRequestChanges} />,
    ]}
  >
    <Table>
      <TableBody>
        <Row label="resources.games.fields.officeId">
          <OfficeField source="officeId" />
        </Row>
        <Row label="resources.games.fields.scheduleId">
          <ScheduleField source="scheduleId" includeCategory="full" />
        </Row>
        <Row label="resources.games.fields.groupId" hidden={!game?.groupId}>
          <GroupField source="groupId" />
        </Row>
        <Row label="resources.games.fields.crossScheduleId" hidden={!game?.crossScheduleId}>
          <ScheduleField source="crossScheduleId" includeCategory="full" />
        </Row>
        <Row label="resources.games.fields.crossGroupId" hidden={!game?.crossGroupId}>
          <GroupField source="crossGroupId" />
        </Row>
        <Row label="resources.games.labels.time">
          <TimeRangeField startSource="startTime" endSource="endTime" showDate showLocal />
        </Row>
        {isHomeFirst ? <>{homeTeamRow}{awayTeamRow}</> : <>{awayTeamRow}{homeTeamRow}</>}
        <Row label="resources.games.fields.arenaId">
          <SurfaceField source="arenaId" includeAttributes="full" includeCity="full" />
        </Row>
        <Row label="resources.games.fields.categoryId">
          <CategoryField source="categoryId" includeGender="full" />
        </Row>
        <Row label="resources.games.fields.seasonId">
          <SeasonField source="seasonId" />
        </Row>
        <Row label="resources.games.fields.status">
          <GameStatusField source="status" gameStatus={status} />
        </Row>
        <Row label="resources.games.fields.comments" hidden={!game?.comments}>
          <UserTextField source="comments" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
