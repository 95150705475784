import React, { createContext, useMemo, useContext, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Provider, createSelectorHook, createDispatchHook, createStoreHook, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';

import { parseLegacyRulebookMeta } from '@hisports/scoresheet/src/util';

import LoadingAlert from '../../common/LoadingAlert';
import { useHttpClient, useSport } from '../../http';
import { createStore } from './store';

const StoreContext = createContext(null);
const MetaContext = createContext(null);

export function ScoresheetProvider({ gameId, children, hideAlerts = false }) {
  const translate = useTranslate();
  const sport = useSport();
  const version = useSelector(store => store.admin.ui.viewVersion)

  const { data: game, loading: gameLoading, error: gameError, refetch: gameRefetch } = useHttpClient(`/games/${gameId}`)
  const { data, loading: scoresheetLoading, error: scoresheetError, refetch: scoresheetRefetch } = useHttpClient(`/games/${gameId}/scoresheet`)
  const { data: meta, loading: metaLoading, error: metaError, refetch: metaRefetch } = useHttpClient(`/games/${gameId}/meta`)

  useEffect(() => {
    // skip initial load to avoid duplicate calls
    if (version === 0) return
    gameRefetch()
    scoresheetRefetch()
    metaRefetch()
  }, [version, gameRefetch, scoresheetRefetch, metaRefetch ])

  const scoresheet = data ? data.scoresheet : undefined;
  const store = useMemo(() => createStore(sport, game?.seasonId, scoresheet), [ sport, game?.seasonId, scoresheet ])
  const value = useMemo(() => {
    return {
      game,
      meta: parseLegacyRulebookMeta(meta),
    }
  }, [ game, meta ])

  if (gameError || scoresheetError || metaError) {
    if (hideAlerts) return null;
    return <Alert severity="error">{translate('resources.scoresheets.alerts.load_error')}</Alert>
  }

  if ((!game && gameLoading) || (!scoresheet && scoresheetLoading) || (!meta && metaLoading)) {
    if (hideAlerts) return null;
    return <LoadingAlert>{translate('resources.scoresheets.alerts.loading.scoresheet')}</LoadingAlert>
  }

  if ((!scoresheet && !scoresheetLoading) || (!meta && !metaLoading)) {
    if (hideAlerts) return null;
    return <Alert severity="error">{translate('resources.scoresheets.alerts.load_error')}</Alert>
  }
  return <MetaContext.Provider value={value}>
    <Provider store={store} context={StoreContext}>
      {children}
    </Provider>
  </MetaContext.Provider>
}

export const useMeta = () => {
  const { game, meta } = useContext(MetaContext);
  return {
    game,
    meta,
  }
}

export const useScoresheet = createSelectorHook(StoreContext)
export const useScoresheetDispatch = createDispatchHook(StoreContext)
export const useScoresheetStore = createStoreHook(StoreContext)
