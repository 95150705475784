import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ListContextProvider, useDataProvider, useListContext, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { Card, Button, FormControlLabel, Switch, Grid } from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { sortBy } from 'lodash';

import { isEmpty } from '@hisports/parsers';
import { FF_DRAFT_CONFLICTS } from '@hisports/common/featureFlags';
import { GAME_NUMBER_REGEX } from '@hisports/scheduler';

import { useAuthContext, useFlag } from '../../../http/AuthContext';
import { useList } from '../../../common/ra/useList';
import { DateInput } from '../../../common/inputs/DateInput';
import { ListView } from '../../../common/cards/ListCard';
import { useSchedulingContext } from '../../../common/calendar/SchedulingContext';
import { CalendarProvider } from '../../../common/calendar/CalendarContext';

import { TeamInput } from '../../teams/TeamInput';
import { SurfaceInput } from '../../surfaces/SurfaceInput';
import { BaseEventView } from '../../events/EventView';
import { CALENDAR_VIEWS, EventViewSettingsMenu, useShowCalendar } from '../../events/EventViewSettings';

import GameDialog from '../dialogs/GameDialog';
import AnalyzeDialog from '../dialogs/AnalyzeDialog';
import DraggableGameList from '../draggables/DraggableGameList';
import DraggableEvent from '../draggables/DraggableEvent';
import GameToolbar from './GameToolbar';
import PastGamesAlert from './PastGamesAlert';

export const isConflicted = (games = [], game) => {
  // teams cannot play more than one game per day
  if (game.status === 'Conflict') return true;
  if (!game.date) return false
  const gameDate = moment.tz(game.date, 'YYYY-MM-DD', game.timezone)
  const sameDay = games.filter(g => g.id !== game.id && g.date && gameDate.isSame(moment.tz(g.date, 'YYYY-MM-DD', g.timezone)) && g.status !== 'Conflict')
  return sameDay.some(g =>
    g.homeTeamId === game.homeTeamId ||
    g.homeTeamId === game.awayTeamId ||
    g.awayTeamId === game.homeTeamId ||
    g.awayTeamId === game.awayTeamId
  )
}


export const renumberGames = (games, numberingPattern) => {
  if (!numberingPattern) return games
  const [ , prefix, start, suffix ] = GAME_NUMBER_REGEX.exec(numberingPattern);
  const startNumber = Number(start) || 0;
  const padLength = Math.max(start.length, String(startNumber + games.length - 1).length);
  return games.map((game, index) => {
    const id = `${startNumber + index}`.padStart(padLength, '0');
    let number;
    if (prefix && suffix) {
      number = `${prefix}${id}${suffix}`;
    } else if (prefix && !suffix) {
      number = `${prefix}${id}`
    } else if (!prefix && suffix) {
      number = `${id}${suffix}`
    } else {
      number = id;
    }
    return { ...game, number }
  })
}

const getNextNumber = ({ games = [], options = {} }) => {
  let { startNumber } = options;
  let startOffset = 0;

  if (!startNumber) return;

  if (games.length) {
    const gameNumbers = games.map(game => {
      if (!game?.number) return;

      const [ , prefix, start, suffix ] = GAME_NUMBER_REGEX.exec(game?.number);
      return Number(start)
    }).filter(number => number != null)

    const maxNumber = Math.max(...gameNumbers);
    const [ , prefix = '', start, suffix = '' ] = GAME_NUMBER_REGEX.exec(startNumber);

    startNumber = prefix + maxNumber + suffix;
    startOffset = 1;
  }

  const [ , prefix, start, suffix ] = GAME_NUMBER_REGEX.exec(startNumber);
  const lastNumber = Number(start) || 0;

  const padLength = Math.max(start.length, String(lastNumber + startOffset).length);
  const id = `${lastNumber + startOffset}`.padStart(padLength, '0');
  let number;
  if (prefix && suffix) {
    number = `${prefix}${id}${suffix}`;
  } else if (prefix && !suffix) {
    number = `${prefix}${id}`
  } else if (!prefix && suffix) {
    number = `${id}${suffix}`
  } else {
    number = id;
  }

  return number;
}

const sort = { field: ['round', 'date', 'number'], order: ['ASC', 'ASC', 'ASC'] }
export const useDraftGamesController = ({ draft, schedule, limitedView, existingGames, resetNumbering = false }) => {
  const [ showCalendar ] = useShowCalendar();
  const { permissions } = useAuthContext();
  const dataProvider = useDataProvider();
  const { change } = useForm();
  const [ game, setGame ] = useState(null);
  const [ conflictsOnly, setConflictsOnly ] = useState(false);
  const [ permittedHomeTeamIds, setPermittedHomeTeamIds ] = useState([])

  useEffect(() => {
    if (!draft?.games) return;

    if (!limitedView) {
      setPermittedHomeTeamIds([]);
      return;
    }

    const permittedOfficeIds = permissions
      .filter(permission => permission.scopes.includes('scheduling:drafts'))
      .flatMap(permission => permission.officeIds)

    dataProvider.getMany('teams', { ids: draft.games.map(game => game.homeTeamId) })
      .then(res => res.data)
      .then(teams => teams.filter(team => permittedOfficeIds.includes(team.officeId)).map(team => team.id))
      .then(setPermittedHomeTeamIds)
  }, [ draft, limitedView, permissions, dataProvider ])

  const data = useMemo(() => {
    if (!draft?.games) return [];
    return draft.games.map(game => {
      game.scheduleId = draft.scheduleId;
      if (schedule) {
        game.categoryId = schedule.categoryId;
      }
      return game;
    })
  }, [ draft, schedule ])

  let perPage = showCalendar ? 1000 : 25;
  if (draft?.options?.type === 'Double Rotation' || draft?.options?.type === 'Single Rotation') {
    // fit as many rounds in a page while being below or equal to 25 games per page
    const totalTeams = draft?.teams?.length || 0
    const gamesPerRound = (totalTeams - (totalTeams % 2)) * (draft?.options?.type === 'Single Rotation' ? 0.5 : 1);
    const roundsPerPage = Math.floor(perPage / gamesPerRound);
    perPage = gamesPerRound * roundsPerPage;
  }

  const context = useList({
    data,
    resource: 'games',
    ids: data.map(event => event.id),
    sort,
    loaded: true,
    loading: false,
    page: 1,
    perPage,
    filter: {
      ...(conflictsOnly ? { id: data.filter(game => isConflicted(draft?.games, game)).map(game => game.id) } : {}),
      ...(limitedView ? { homeTeamId: permittedHomeTeamIds } : {})
    }
  })

  const updateGames = games => {
    change('games', games);
    context.onUnselectItems();
  }

  const handleSave = game => {
    let updatedGames = [...draft.games];

    if (!game.id) {
      // add new game
      game.id = uuid();
      updatedGames.push(game);
    } else {
      // replace update
      updatedGames = updatedGames.map(g => {
        if (g.id !== game.id) return g;
        return game;
      })
    }

    updatedGames = sortBy(updatedGames, ['round', 'date', 'startTime'])

    if (resetNumbering) {
      updatedGames = renumberGames(updatedGames, draft?.options?.startNumber);
    }

    updateGames([...updatedGames])
  }

  const handleDelete = game => {
    let updatedGames = [...draft.games]
      .filter(existing => existing.id !== game.id)

    if (resetNumbering) {
      updatedGames = renumberGames(updatedGames, draft?.options?.startNumber);
    }

    updateGames([...updatedGames])
  }

  return {
    context: {
      basePath: '/games',
      resource: 'games',
      ...context,
    },
    draft,
    schedule,
    existingGames,
    updateGames,
    onSave: handleSave,
    onDelete: handleDelete,
    game,
    setGame,
    conflictsOnly,
    setConflictsOnly,
  }
}

const AnalyzeButton = ({ draft, existingGames, size, variant }) => {
  const translate = useTranslate();
  const [ open, setOpen ] = useState(false);

  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Search />} onClick={() => setOpen(true)}>{translate('ra.action.analyze')}</Button>
    <AnalyzeDialog
      draft={draft}
      existingGames={existingGames}
      isOpen={open}
      onClose={() => setOpen(false)}
    />
  </>
}

const GameListContext = ({ children, ...props }) => {
  const { data, ids, ...listContext } = useListContext()

  const games = useMemo(() => {
    return ids.map(id => data[id])
  }, [ data, ids ])

  const context = useList({
    ...listContext,
    data: games,
    ids: games.map(game => game.id),
    loaded: true,
    loading: false,
    page: 1,
    perPage: 25,
  })

  return <ListContextProvider value={context}>
    {children}
  </ListContextProvider>
}

const CalendarListContext = ({ children, ...props }) => {
  const baseListContext = useListContext()
  const { data, ids, ...listContext } = baseListContext
  const [ showCalendar ] = useShowCalendar();

  const games = useMemo(() => {
    return ids.map(id => data[id])
  }, [data, ids])

  const calendarContext = useList({
    ...listContext,
    data: games,
    ids: games.map(game => game.id),
    loaded: true,
    loading: false,
    page: 1,
    perPage: 99999,
  })

  return <ListContextProvider value={showCalendar ? calendarContext : baseListContext}>
    {children}
  </ListContextProvider>
}

export default ({
  draft,
  schedule,
  existingGames,
  updateGames,
  onSave,
  onDelete,
  start,
  popoverActions,

  game,
  setGame,
  conflictsOnly,
  setConflictsOnly,

  showAddGame = false,
  showDelete = false,
  hideNumber = false,
  hideAvailabilities = true,
}) => {
  const isEnabled = useFlag();
  const translate = useTranslate()
  const [ showCalendar ] = useShowCalendar();
  const { setSelectedGame } = useSchedulingContext();

  const isGeneratedDraft = draft?.type === 'Generated';

  const initialValues = useMemo(() => {
    const values = {}

    if (schedule) {
      values.scheduleId = schedule.id;
      values.categoryId = schedule.categoryId;
    }
    if (!isEmpty(draft)) {
      values.number = getNextNumber(draft)
    }

    return values;
  }, [ schedule, draft ])

  const handleConflictsChange = event => setConflictsOnly(event.target.checked)
  const handleRowClick = (id, basePath, game) => setGame(game);
  const handleRowSelect = (id, basePath, game) => setSelectedGame(game);
  const handleClose = () => setGame(null)
  const handleSave = game => {
    onSave(game)
    handleClose();
  }
  const handleDelete = game => {
    onDelete(game);
    handleClose();
  }

  const filters = [
    !showCalendar && <DateInput source="startTime" label={translate('resources.games.filters.startTime')} variant="outlined" alwaysOn />,
    !showCalendar && <DateInput source="endTime" label={translate('resources.games.filters.endTime')} variant="outlined" disabled={showCalendar} alwaysOn />,
    !showCalendar && <TeamInput source="teamId" filter={{ seasonId: schedule?.seasonId }} multiple variant="outlined" />,
    !showCalendar && <SurfaceInput source="arenaId" multiple variant="outlined" />,
    !showCalendar && isEnabled(FF_DRAFT_CONFLICTS) && <FormControlLabel
      label={translate('resources.drafts.messages.show_conflicts_only')}
      control={
        <Switch value={conflictsOnly} onChange={handleConflictsChange} color="primary" />
      }
      alwaysOn
    />,
  ].filter(Boolean);

  const addButton = showAddGame && <Button color="primary" startIcon={<Add />} onClick={() => setGame(initialValues)}>{translate('resources.games.actions.add')}</Button>
  const deleteButton = showDelete && game?.id && <Button color="secondary" onClick={() => handleDelete(game)}>{translate('ra.action.delete')}</Button>
  const actions = [
    <AnalyzeButton draft={draft} existingGames={existingGames} size="small" />,
    <EventViewSettingsMenu
      disableAssignments
      disableGroupRounds={!isGeneratedDraft}
      disableGroupArenas={false}
      alwaysOn
      showTeamEvents
      showSlots
      showAvailabilties
      showSurfaceSizes
      showFlagsInput={false}
      showRenumber
    />,
  ]

  return <Grid container fullWidth spacing={1}>
    {showCalendar && <Grid item md={3}>
      <GameListContext>
        <DraggableGameList rowClick={handleRowSelect} dragComponent={DraggableEvent} />
      </GameListContext>
    </Grid>}
    <CalendarListContext>
      <CalendarProvider initialStart={start} disableAuth hideArena popoverActions={popoverActions} strictView={CALENDAR_VIEWS.DAY}>
        <Grid item md={showCalendar ? 9 : 12}>
          <Card>
            <ListView
              filters={filters}
              addButton={addButton}
              actions={actions}
              alert={<PastGamesAlert games={draft.games} />}
              bulkActionButtons={<GameToolbar updateGames={updateGames} />}
              open
              noResultsText={!showCalendar}
            >
              <BaseEventView
                rowClick={handleRowClick}
                hasBulkActions
                hideGroup
                hideStatus
                hideArena
                disableAssignments
                component={Fragment}
                disableCalendar={false}
              />
            </ListView>
            <GameDialog
              game={game}
              schedule={schedule}
              isOpen={!!game}
              hideNumber={hideNumber}
              hideAvailabilities={hideAvailabilities}
              deleteButton={deleteButton}
              onClose={handleClose}
              onSave={handleSave}
            />
          </Card>
        </Grid>
      </CalendarProvider>
    </CalendarListContext>
  </Grid>
}
