const _ = require("lodash");

const isEven = n => n % 2 === 0;

// generate a round-robin schedule where teams play teams from opposite pools
// this algorithm is only for exactly 2 pools
export const crossGroup = ({ teams, startRound = 0, totalRounds }) => {
  const schedule = [];
  let nextId = 0;

  // pool of first team becomes the pool to start at home
  const pools = _(teams.map((team, index) => ({ ...team, index })))
    .groupBy(team => team.groupId)
    .sortBy(group => teams[0].groupId === group)
    .value()

  if (pools.length !== 2) {
    const e = new Error("Can't generated cross-group matchups without teams in 2 groups");
    e.name = "invalid_groups";
    throw e;
  }

  const [ poolA, poolB ] = pools;
  const roundsPerRotation = Math.max(poolA.length, poolB.length);

  for (let round = startRound; round < (startRound + totalRounds); round++) {
    const isFrontRotation = round % (2 * roundsPerRotation) < roundsPerRotation;
    const isBackRotation = !isFrontRotation
    const isPoolAHome = isEven(roundsPerRotation) && isBackRotation ? !isEven(round) : isEven(round);

    // eslint-disable-next-line unicorn/no-for-loop
    for (let team = 0; team < poolA.length; team++) {
      const opponent = (team + round) % roundsPerRotation;
      const poolATeam = poolA[team]
      const poolBTeam = poolB[opponent]

      if (!poolATeam || !poolBTeam) continue;

      schedule.push({
        id: nextId++,
        home: isPoolAHome ? poolATeam.index : poolBTeam.index,
        away: isPoolAHome ? poolBTeam.index : poolATeam.index,
        // start round at 1 instead of 0
        round: round + 1,
      })
    }
  }

  return schedule;
}
