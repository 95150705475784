import moment from "moment";

import { filterParticipants } from "@hisports/scoresheet/src/util/participants";
import { filterOfficialParticipants } from "@hisports/scoresheet/src/util/officialParticipants";
import { LATEST_SEASON } from "@hisports/common/src/seasons";

import { authService } from '../index';

const dedupe = values => Array.from(new Set(values.filter(value => value != null)));
const escapeRegExpChars = value => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const ilike = value => ({ ilike: `%${value}%` })
export const startsWith = value => ({ ilike: `${value}%` })
export const regexStartsWith = value => ({ regexp: new RegExp(`^${escapeRegExpChars(value)}`, 'i') }) // loopback-filters 1.1.1 does not support ilike
export const eq = (value, useInq = true, simplify = true) => {
  if (value == null) return;
  if (!Array.isArray(value)) return value;

  const values = dedupe(value);
  if (!values.length) return;

  if (simplify && values.length === 1) return values[0]
  if (useInq) return { inq: values };
  return values;
}

const filterName = (resource, params) => {
  const { name: value } = params.filter;
  if (!value) return;

  switch (resource) {
    case 'participants':
      return filterParticipants(value);

    case 'officialoffices':
      return filterOfficialParticipants(value);

    case 'offices':
    case 'teams': {
      return {
        or: [
          { name: ilike(value) },
          { HCRId: startsWith(value) },
        ]
      }
    }

    case 'venues': {
      return { or: [
        { 'name': ilike(value) },
        { 'address': ilike(value) },
        { 'city': ilike(value) },
        { 'region': ilike(value) },
        { 'country': ilike(value) },
        { 'alias': ilike(value) },
      ] }
    }

    case 'surfaces': {
      const [ venue ] = value.trim().split(' - ')
      return { or: [
        { name: ilike(venue) },
        { alias: ilike(venue) },
        { 'venue.name': ilike(venue) },
        { 'venue.address': ilike(venue) },
        { 'venue.city': ilike(venue) },
        { 'venue.region': ilike(venue) },
        { 'venue.country': ilike(venue) },
        { 'venue.alias': ilike(venue) },
      ] }
    }

    default:
      return {
        name: ilike(value),
      }
  }
}

const filterOfficialName = (resource, params) => {
  const { officialname: value } = params.filter
  if (!value) return;

  if (resource == 'officialoffices') return filterOfficialParticipants(value);
}

const filterTeam = (resource, params) => {
  const { target, id } = params;
  const { teamId: value } = params.filter;

  let teamId = eq(value);
  if (resource === 'games' && target == 'teams') {
    teamId = id;
  }

  if (teamId == null) return;
  if (resource === 'games' || resource === 'draftgames') {
    return { or: [
      { homeTeamId: teamId },
      { awayTeamId: teamId },
    ] }
  }

  return {
    teamId,
  }
}

const filterHCRId = (resource, params) => {
  const { HCRId: value } = params.filter;
  if (!value) return;

  if (typeof value === 'string' || typeof value === 'number') {
    return {
      HCRId: startsWith(value),
    }
  }

  return {
    HCRId: value,
  }
}

const filterEmail = (resource, params) => {
  const { email: value } = params.filter;
  if (!value) return;

  if (resource !== 'accounts') return;
  return {
    email: ilike(value),
  }
}

const filterOffice = (resource, params) => {
  const { officeId, effectiveOffices, includeMembers, includeParents, via } = params.filter;
  const { target, id } = params;

  if (effectiveOffices === false) {
    return { officeId }
  }

  if (params.clientFiltering) return;

  let value = officeId || effectiveOffices;
  if (target === 'officeId' && effectiveOffices) {
    value = id;
  }

  if (!value) return;
  switch (resource) {
    case 'draftgames':
    case 'games': {
      return {
        effectiveOffices: eq(value, false),
        includeMembers: includeMembers || false,
        includeParents,
        via,
      }
    }

    case 'schedules':
    case 'teams':
    default: {
      return {
        effectiveOffices: eq(value, false),
        includeMembers,
        includeParents,
        via,
      }
    }
  }
}

const filterEffective = effectiveType => (resource, params) => {
  const { [effectiveType]: value } = params.filter;

  if (params.clientFiltering) return;

  const effectiveValue = eq(value, false);
  if (!effectiveValue || !['games', 'draftgames'].includes(resource)) return;

  return {
    [effectiveType]: effectiveValue,
  }
}

const filterSchedule = (resource, params) => {
  const { scheduleId: value } = params.filter;
  const scheduleId = eq(value);
  if (scheduleId == null) return;

  if (resource === 'teams' && !Array.isArray(value)) return;
  return {
    scheduleId,
  }
}

const filterSurface = (resource, params) => {
  const { arenaId, effectiveSurfaces, includeParents = true } = params.filter;
  const { target, id } = params;

  if (effectiveSurfaces === false) {
    return { arenaId }
  }

  if (params.clientFiltering) return;

  let value = arenaId || effectiveSurfaces;
  if ((target === 'arenaId') && effectiveSurfaces) {
    value = id;
  }

  if (!value) return;
  switch (resource) {
    default: {
      return {
        effectiveSurfaces: eq(value, false),
        includeParents,
      }
    }
  }
}

const filterNumber = (resource, params) => {
  const { number } = params.filter;
  if (!number) return;

  if (resource === 'games') {
    if (params.clientFiltering) {
      // include draft game number (id)
      return { or: [{ number: regexStartsWith(number) }, { id: regexStartsWith(number) }] }
    }

    return { number: startsWith(number) }
  }

  return {
    number,
  }
}

const filterDate = (resource, params) => {
  const { target } = params;
  const { name, pastEvents, seasonId } = params.filter;
  const limitPast = !pastEvents && (seasonId === LATEST_SEASON || seasonId === authService._seasonId);
  if (name || !limitPast || resource !== 'schedules' || target) return;

  return {
    endDate: {
      gte: moment().format('YYYY-MM-DD')
    }
  }
}

const filterTime = (resource, params) => {
  const { target } = params;
  const { startTime, endTime, number, pastEvents, missingStartTime, seasonId } = params.filter;

  if (!['games', 'draftgames', 'activities', 'practices', 'arenaslots', 'officialtransactions'].includes(resource)) return;

  if (missingStartTime) {
    return { startTime: null }
  }

  if (startTime && endTime) {
    const startDate = moment.utc(startTime).format('YYYY-MM-DD');
    const endDate = moment.utc(endTime).format('YYYY-MM-DD');

    return { and: [
      { date: { gte: startDate } },
      { date: { lte: endDate } },
    ] }
  } else if (startTime && !endTime) {
    if (target && resource !== 'arenaslots') return { startTime: { gte: startTime } };
    const date = moment.utc(startTime).format('YYYY-MM-DD')

    if (resource === 'officialtransactions') return { date: { gte: date } };

    return {
      date
    }
  } else if (!startTime && endTime) {
    if (target && resource !== 'arenaslots') return { endTime: { gte: endTime } };
    const startDate = moment.utc().startOf('day').toISOString();
    const endDate = moment.utc(endTime).format('YYYY-MM-DD');

    if (resource === 'officialtransactions') return { date: { lte: endDate } };

    return { and: [
      { date: { gte: startDate } },
      { date: { lte: endDate } },
    ] }
  } else if (!pastEvents && (seasonId === LATEST_SEASON || seasonId === authService._seasonId) && !number && !target) { // limit past games
    if (resource === 'arenaslots') return;
    return {
      date: {
        gte: moment().format('YYYY-MM-DD'),
      }
    }
  }
}

const filterAssigner = (resource, params) => {
  const { isAssigner } = params.filter;
  if (isAssigner == null || params.clientFiltering) return;
  return { isAssigner }
}

const filterTarget = (resource, params) => {
  const { target, id } = params;
  if (!target || !target.endsWith('Id') || id == null) return;

  const filterKeys = [...Object.keys(params.filter), ...(params.filter.or || []).map(orClause => Object.keys(orClause)).flat()]
  if (filterKeys.some(key => ['effectiveOffices', 'scheduleOffices', 'awayTeamOffices', 'homeTeamOffices'].includes(key)) && target === 'officeId') return;
  if (filterKeys.includes('effectiveSurfaces') && target === 'arenaId') return;

  return {
    [target]: id
  }
}

const filterValue = (resource, params) => {
  const { target, id } = params;
  if (resource !== 'assignrules' || target !== 'value') return;
  return {
    value: id,
  }
}

const filterRest = (resource, params) => {
  const {
    startTime, endTime, pastEvents, missingStartTime,
    name, number, HCRId, email,
    teamId, scheduleId, officeId, arenaId,
    effectiveOffices, effectiveSurfaces, includeMembers, includeParents, via,
    scheduleOffices, teamOffices, homeTeamOffices, awayTeamOffices, surfaceOffices, assignOffices,
    _scope, _include, _options, isAssigner,
    ...rest
  } = params.filter;

  const where = Object.keys(rest).reduce((where, key) => {
    const value = rest[key];
    if (typeof value === 'undefined') return where;
    if (Array.isArray(value) && !value.some(v => typeof v === 'object')) {
      where[key] = eq(value, true, !['arenaslots', 'practices', 'officialoffices'].includes(resource))
    } else {
      where[key] = value;
    }
    return where;
  }, {})

  if (!Object.keys(where).length) return;
  return where;
}

export const getWhere = (resource, params) => {
  const where = [
    filterName,
    filterOfficialName,
    filterTeam,
    filterHCRId,
    filterEmail,
    filterOffice,
    filterEffective('scheduleOffices'),
    filterEffective('teamOffices'),
    filterEffective('homeTeamOffices'),
    filterEffective('awayTeamOffices'),
    filterEffective('surfaceOffices'),
    filterEffective('assignOffices'),
    filterSurface,
    filterNumber,
    filterSchedule,
    filterTime,
    filterDate,
    filterAssigner,
    filterTarget,
    filterValue,
    filterRest,
  ].reduce((filters, filter) => {
    const result = filter(resource, params);
    if (result != null) filters.push(result);
    return filters;
  }, [])

  if (!where.length) return;
  if (where.length === 1) return where[0];
  return { and: where };
}

export const getOrder = sort => {
  if (!sort) return;
  const { field, order } = sort;
  if (Array.isArray(field)) return field.map((f, i) => {
    if (order && order[i]) return `${f} ${order[i]}`
    return f
  })
  if (order) return `${field} ${order}`
  return field;
}

// skip pagination if value >500 (e.g., 999 workaround)
export const getLimit = ({ perPage }) => {
  if (perPage && perPage > 0 && perPage < 500) return perPage;
}

export const getSkip = ({ page, perPage }) => {
  const limit = getLimit({ perPage });
  if (limit == null || page <= 0) return;
  return (page - 1) * perPage;
}

export const skipFilter = (resource, filter) => {
  if (!['participants', 'accounts', 'categories'].includes(resource)) return false;
  const hasFilter = Object.keys(filter).some(key => {
    const value = filter[key];
    if (key === '_scope') return false;
    if (key === 'isOfficial') return false;
    if (typeof value === 'string') {
      if (key === 'name') return value.length >= 3;
      if (key === 'email') return value.length >= 5;
      return value.length > 0;
    }
    return value != null;
  })
  return !hasFilter;
}
