import React from "react"
import { useRecordContext } from "react-admin"
import { SmsFailedOutlined } from '@material-ui/icons';

import ListCard from "../../../common/cards/ListCard"
import { isAuthorized } from "../../../common/Authorize";
import { useFlag, usePermissions } from "../../../http";
import { getFilteredScopes } from "../../../http/restClient/response";

import { IncidentReportGrid } from "./IncidentReportGrid";
import { AddIncidentReportButton } from "./IncidentReportModal";
import { FF_INCIDENTS } from "@hisports/common/featureFlags";

export const IncidentReportCard = ({ isAssignedOfficial, hideAddButton = false, rowOptions, ...props }) => {
  const game = useRecordContext();
  const permissions = usePermissions();
  const filteredScopes = getFilteredScopes(permissions, game?.officeId);
  const isEnabled = useFlag();

  const gameId = game?.id

  const showIncidentReportsCard = (isAuthorized(filteredScopes.filter(scope => scope != 'assigning:official'), 'gameIncidents', 'show') || isAssignedOfficial) && isEnabled(FF_INCIDENTS);

  if (!showIncidentReportsCard) return null;

  const showAddIncidentReportButton = !hideAddButton && (isAuthorized(game, 'gameIncidents', 'create') || isAssignedOfficial);

  return <ListCard
    title="resources.gameIncidents.name"
    icon={<SmsFailedOutlined />}
    reference="gameIncidents"
    target="gameId"
    sort={{ field: ['participant.lastName', 'participant.firstName'], order: ['ASC', 'ASC'] }}
    addButton={showAddIncidentReportButton && <AddIncidentReportButton initialValues={{ gameId }} />}
    {...props}
  >
    <IncidentReportGrid rowOptions={rowOptions} />
  </ListCard>
}
