import {
  GOAL_SCORED,
  GOAL_EDITED,
  GOAL_DELETED,
  SCORESHEET_RESET,
} from '../actions';

import { append, replace, removeById } from '../util';

const toGoal = (event, meta = {}) => ({
  ...meta,
  ...event
})

export default (state = [], action) => {
  switch (action.type) {
    case GOAL_SCORED: {
      const { id, event: goal } = action.payload;
      return append(state, toGoal(goal, {
        id,
      }))
    }

    case GOAL_EDITED: {
      const { goalId, ...goal } = action.payload.event;

      return replace(state, goalId, toGoal(goal, {
        id: goalId,
      }));
    }

    case GOAL_DELETED: {
      const { goalId } = action.payload.event;

      return removeById(state, goalId)
    }

    case SCORESHEET_RESET: {
      return [];
    }

    default:
      return state;
  }
}
