import React from 'react';
import { useTranslate } from 'react-admin';

import ResourceTitle from '../../common/ResourceTitle';
import { Create, Edit, Show } from "../../common/ra";

import { SuspensionForm } from '../scoresheet/suspension/SuspensionForm';
import { SuspensionShowLayout } from './SuspensionShow';

export const SuspensionShow = props => {
  const translate = useTranslate()
  return <Show {...props}
    title={<ResourceTitle title={() => translate('resources.suspensions.name', 1)} />}
  >
    <SuspensionShowLayout />
  </Show>
}

export const SuspensionCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={() => translate('resources.suspensions.labels.add')} />}
    {...props}
  >
    <SuspensionForm redirect="show" />
  </Create>
}

export const SuspensionEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: translate('resources.suspensions.name', 1) })} />}
    {...props}
  >
    <SuspensionForm redirect="show" />
  </Edit>
}
