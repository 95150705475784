import React from 'react';
import { TextInput, SimpleForm, useTranslate, useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FF_OFFICIALS, FF_SCOREKEEPERS, FF_TIMEKEEPERS, FF_ADDITIONAL_LINESPERSONS } from '@hisports/common/featureFlags';

import CurrencyInput from '../../common/inputs/CurrencyInput';
import SwitchInput from '../../common/inputs/SwitchInput';
import { useFlag, useHttpClient } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';

const validate = (values, prevValues, hasRules) => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required';

  if (values.Referee && values.Referee < 0) errors.Referee = 'ra.validation.invalid';
  if (values.Linesperson && values.Linesperson < 0) errors.Linesperson = 'ra.validation.invalid';
  if (values.LinespersonTwo && values.LinespersonTwo < 0) errors.LinespersonTwo = 'ra.validation.invalid';
  if (values.LinespersonThree && values.LinespersonThree < 0) errors.LinespersonThree = 'ra.validation.invalid';
  if (values.Official && values.Official < 0) errors.Official = 'ra.validation.invalid';
  if (values.Scorekeeper && values.Scorekeeper < 0) errors.Scorekeeper = 'ra.validation.invalid';
  if (values.Timekeeper && values.Timekeeper < 0) errors.Timekeeper = 'ra.validation.invalid';
  if (values.Supervisor && values.Supervisor < 0) errors.Supervisor = 'ra.validation.invalid';

  if (values?.isArchived === true && prevValues?.isArchived === false && hasRules) errors.isArchived = 'resources.assignFees.validations.cannot_archive';

  return errors;
}

const inputProps = {
  helperText: '',
  resource: 'assignFees',
  basePath: '/assignFees',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const AssignFeesForm = props => {
  const isEnabled = useFlag();
  const translate = useTranslate();
  const assignFees = useRecordContext();
  const { data: prevalidate } = useHttpClient(`/assignfees/${assignFees?.id}/prevalidate`, null, !!assignFees?.id);

  const { isFeesUsed = false, hasRules = false } = prevalidate || {};

  return <>
    {isFeesUsed && <>
      <Alert severity="warning">
        {translate('resources.assignFees.alerts.cannot_update_amounts')}
      </Alert>
      <br />
    </>}
    <SimpleForm validate={values => validate(values, assignFees, hasRules)} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12}>
          <TextInput source="name" autoFocus {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyInput source="Referee" disabled={isFeesUsed} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="Linesperson" disabled={isFeesUsed} {...inputProps} />
        </Grid>
        {isEnabled(FF_ADDITIONAL_LINESPERSONS) && <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="LinespersonTwo" disabled={isFeesUsed} {...inputProps} />
        </Grid>}
        {isEnabled(FF_ADDITIONAL_LINESPERSONS) && <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="LinespersonThree" disabled={isFeesUsed} {...inputProps} />
        </Grid>}
        {isEnabled(FF_OFFICIALS) && <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="Official" disabled={isFeesUsed} {...inputProps} />
        </Grid>}
        {isEnabled(FF_SCOREKEEPERS) && <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="Scorekeeper" disabled={isFeesUsed} {...inputProps} />
        </Grid>}
        {isEnabled(FF_TIMEKEEPERS) && <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="Timekeeper" disabled={isFeesUsed} {...inputProps} />
        </Grid>}
        <Grid item xs={12} sm={4} md={4}>
          <CurrencyInput source="Supervisor" disabled={isFeesUsed} {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <OfficeInput source="officeId" disabled {...inputProps} />
        </Grid>
        <Grid item xs={12}>
          <SwitchInput source="isArchived" {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
