import React from 'react';
import { EditButton, useRecordContext, ReferenceField } from 'react-admin';
import { Table, TableBody } from '@material-ui/core';

import InfoCard from '../../common/cards/InfoCard';
import Row from '../../common/cards/TableRow';
import { isAuthorized } from '../../common/Authorize';

import { EnumField } from '../../common/inputs/EnumInputs';
import { ParticipantField } from '../participants/ParticipantField';
import { TeamField } from '../teams/TeamField';
import { ScheduleField } from '../schedules/ScheduleField';
import { GameField } from '../games/GameField';
import { PenaltyField } from '../scoresheet/gameIncidents/IncidentReportGrid';
import { SuspensionDetailsField } from '../scoresheet/suspension/SuspensionGrid';

export default props => {
  const suspension = useRecordContext(props);

  const canEditSuspension = isAuthorized(suspension, 'suspensions', 'edit');

  return <InfoCard
    title="resources.suspensions.name"
    actions={[
      canEditSuspension && <EditButton label="resources.suspensions.labels.edit" record={suspension} />,
    ]}
  >
    <Table>
      <TableBody>
        <Row label="resources.suspensions.fields.participantId">
          <ParticipantField source="participantId" includeId="full" />
        </Row>
        <Row label="resources.suspensions.fields.teamId">
          <TeamField source="teamId" includeId="full" />
        </Row>
        <Row label="resources.games.fields.scheduleId">
          <ReferenceField source="gameId" reference="games" resource="games" basePath="/games" label="resources.games.fields.scheduleId" link={false}>
            <ScheduleField source="scheduleId" includeCategory="full" includeType="full" />
          </ReferenceField>
        </Row>
        <Row label="resources.suspensions.fields.gameId">
          <GameField source="gameId" link="scoresheet" includeDate="inline" includeSurface="full" />
        </Row>
        <Row label="resources.suspensions.fields.penaltyId">
          <PenaltyField source="penaltyId" />
        </Row>
        <Row label="resources.suspensions.labels.details">
          <SuspensionDetailsField source="suspensionId" />
        </Row>
        <Row label="resources.suspensions.fields.status">
          <EnumField source="status" />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
}
