import React, { useContext, useState, useMemo, useEffect } from 'react';

import { authService } from './index';

const AuthContext = React.createContext({});

export const AuthProvider = props => {
  const [ isReady, setReady ] = useState(false);
  const [ isLoggedIn, setLoggedIn ] = useState(false);
  const [ isVolunteer, setVolunteer ] = useState(false);
  const [ account, setAccount ] = useState(null);
  const [ identity, setIdentity ] = useState();
  const [ identities, setIdentities ] = useState([]);
  const [ permissions, setPermissions ] = useState([]);
  const [ scopes, setScopes ] = useState([])
  const [ seasonId, setSeasonId ] = useState();
  const [ officeId, setOfficeId ] = useState();
  const [ notices, setNotices ] = useState([]);
  const [ flags, setFlags ] = useState([]);

  useEffect(() => {
    const handler = () => {
      setReady(authService._ready);
      setLoggedIn(authService.isLoggedIn());
      setVolunteer(authService.isVolunteer());
      setAccount(authService.getAccount());
      setIdentity(authService.getIdentity());
      setIdentities(authService.getIdentities());
      setPermissions(authService.getPermissions());
      setScopes(authService.getScopes());
      setSeasonId(authService._seasonId);
      setOfficeId(authService._officeId);
      setNotices(authService.getNotices());
      setFlags(authService.getFlags());
    }

    handler();
    return authService.subscribe(handler);
  }, [])

  const value = useMemo(() => ({
    isReady,
    isLoggedIn,
    isVolunteer,
    account,
    identity,
    identities,
    permissions,
    scopes,
    seasonId,
    officeId,
    notices,
    flags,
  }), [ isReady, isLoggedIn, isVolunteer, account, identity, identities, permissions, scopes, seasonId, officeId, notices, flags ])

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuthContext = () => useContext(AuthContext);

const createAuthHook = property => () => {
  const auth = useAuthContext();
  return auth[property]
}

export const useReady = createAuthHook('isReady');
export const useLoggedIn = createAuthHook('isLoggedIn');
export const useVolunteer = createAuthHook('isVolunteer');
export const useAccount = createAuthHook('account');
export const useIdentity = createAuthHook('identity');
export const useIdentities = createAuthHook('identities');
export const usePermissions = createAuthHook('permissions');
export const useScopes = createAuthHook('scopes');
export const useNotices = createAuthHook('notices');
export const useFlags = createAuthHook('flags');

export const useFilteredScopes = (roleTypes) => {
  const permissions = usePermissions();
  const filtered = permissions.filter(permission => roleTypes.includes(permission.roleType));
  return Array.from(new Set(filtered.flatMap(p => p.scopes)));
}

export const useParticipant = () => {
  const identity = useIdentity();
  return identity?.participantId;
}

export const useTenant = () => {
  const identity = useIdentity();
  return identity?.tenant;
}

export const useSport = () => {
  const tenant = useTenant();
  if (!tenant) return null;
  return tenant.sport;
}

export const useDefaultSeason = () => {
  const { seasonId } = useAuthContext();
  return seasonId;
}

export const useDefaultOffice = () => {
  const { officeId } = useAuthContext();
  return officeId;
}

export const useFlag = () => {
  const flags = useFlags();
  return useMemo(() => {
    return flag => flags.includes(flag);
  }, [ flags ])
}
