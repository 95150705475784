import React, { Fragment, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, styled } from '@material-ui/core';
import { Button, RecordContextProvider, SimpleForm, useListContext, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import moment from 'moment-timezone';

import { isAuthorized } from '../../common/Authorize';
import { apiClient, useScopes } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { InlineDateInput } from '../../common/inputs/DateInput';
import Toolbar from '../../common/ra/Toolbar';
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const FormDialogContent = styled(DialogContent)({
  '&:first-child': { // overrides default style
    paddingTop: 0,
  }
})

const validateStatus = (values) => {
  const errors = {}
  const { status } = values || {};
  if (!status) errors.status = 'ra.validation.required'
  return errors
}

const validateDates = (values, schedule = {}, translate) => {
  const errors = {}
  const { startDate, endDate } = schedule;

  if (values.date == null) {
    errors.date = 'ra.validation.required'
  } else if (startDate && moment.utc(startDate, 'YYYY-MM-DD').isAfter(values.date)) {
    errors.date = translate('resources.draftGames.validations.must_be_after', { date: moment(startDate).format('LL') })
  } else if (endDate && moment.utc(endDate, 'YYYY-MM-DD').add(1, 'day').isBefore(values.date)) {
    errors.date = translate('resources.draftGames.validations.must_be_before_schedule_end_date', { date: moment(endDate).format('LL') })
  }

  return errors
}

const ChangeDateDialog = ({ open, onSave, onClose }) => {
  const translate = useTranslate();
  const schedule = useRecordContext();

  return <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.draftGames.actions.set_date')}</DialogTitle>
    <RecordContextProvider value={null}>
      <SimpleForm {...inputProps} save={onSave} component={Fragment} margin="none" toolbar={<Toolbar hideDelete />} validate={(values) => validateDates(values, schedule, translate)} {...inputProps} >
        <FormDialogContent {...inputProps}>
          <DialogContentText>
            {translate('resources.draftGames.messages.change_dates_dialog')}
          </DialogContentText>
          <InlineDateInput
            source="date"
            helperText={translate('resources.draftGames.messages.schedule_dates', { startDate: moment(schedule.startDate).format('LL'), endDate: moment(schedule.endDate).format('LL') })}
            {...inputProps}
            margin="none"
          />
        </FormDialogContent>
      </SimpleForm>
    </RecordContextProvider>
  </Dialog>
}

const ChangeDate = ({ selectedIds, date }) => {
  const [ open, setOpen ] = useState(false);
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = async (values) => {
    apiClient(`/draftgames/bulkChangeDate`, {
      method: 'POST',
      data: {
        draftGamesIds: selectedIds,
        date: values.date,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        onUnselectItems();
        notify(translate('resources.draftGames.notifications.set_date', { smart_count: count }), 'success')
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.draftGames.notifications.set_date_error', { smart_count: selectedIds.length }), 'error')
      })

    setOpen(false);
  }

  return <>
    <Button label={translate('resources.draftGames.actions.set_date')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <ChangeDateDialog open={open} onSave={onSubmit} onClose={() => setOpen(false)} />
  </>
}

const ClearDateDialog = ({ open, onSubmit, onClose, draftGamesIds = [] }) => {
  const translate = useTranslate();

  return <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.draftGames.actions.clear_date', { smart_count: draftGamesIds.length })}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.draftGames.messages.confirm_clear_date', { smart_count: draftGamesIds.length })}
      </DialogContentText>
      <RecordContextProvider value={null}>
        <SimpleForm
          save={onSubmit}
          component={Fragment}
          toolbar={<DialogFormToolbar submitLabel="ra.action.clear" cancelLabel="ra.action.cancel" onCancel={onClose} hideSubmit={!draftGamesIds?.length} />}
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

const ClearDate = ({ selectedIds }) => {
  const [ open, setOpen ] = useState(false);
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = async () => {
    apiClient(`/draftgames/bulkChangeDate`, {
      method: 'POST',
      data: {
        draftGamesIds: selectedIds,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        onUnselectItems();
        notify(translate('resources.draftGames.notifications.clear_date', { smart_count: count }), 'success')
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.draftGames.notifications.clear_date_error', { smart_count: selectedIds.length }), 'error')
      })

    setOpen(false);
  }

  return <>
    <Button label={translate('resources.draftGames.actions.clear_date')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <ClearDateDialog open={open} onSubmit={onSubmit} onClose={() => setOpen(false)} draftGamesIds={selectedIds} />
  </>
}

const ChangeStatusDialog = ({ open, onSave, onClose, status }) => {
  const translate = useTranslate();

  return <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.draftGames.actions.change_status')}</DialogTitle>
    <RecordContextProvider value={null}>
      <SimpleForm {...inputProps} save={onSave} component={Fragment} toolbar={<Toolbar hideDelete />} validate={(values) => validateStatus(values)} {...inputProps} >
        <FormDialogContent {...inputProps}>
          <GameStatusEnumInput source="status" {...inputProps} margin="none" />
        </FormDialogContent>
      </SimpleForm>
    </RecordContextProvider>
  </Dialog>
}

const ChangeStatus = ({ selectedIds, status }) => {
  const [ open, setOpen ] = useState(false);
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = async (values) => {
    apiClient(`/draftgames/bulkChangeStatus`, {
      method: 'POST',
      data: {
        draftGamesIds: selectedIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        onUnselectItems();
        notify(translate('resources.draftGames.notifications.change_status', { smart_count: count }), 'success')
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.draftGames.notifications.change_status_error', 'error'))
      })

    setOpen(false);
  }

  return <>
    <Button label={translate('resources.draftGames.actions.change_status')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <ChangeStatusDialog open={open} onSave={onSubmit} onClose={() => setOpen(false)} draftGamesIds={selectedIds} />
  </>
}


const DeleteDialog = ({ open, onSubmit, onClose, draftGamesIds = [] }) => {
  const translate = useTranslate();

  return <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.draftGames.actions.delete_draft_game', { smart_count: draftGamesIds.length })}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.draftGames.messages.confirm_delete', { smart_count: draftGamesIds.length })}
      </DialogContentText>
      <RecordContextProvider value={null}>
        <SimpleForm
          save={onSubmit}
          component={Fragment}
          toolbar={<DialogFormToolbar submitLabel="ra.action.delete" cancelLabel="ra.action.cancel" onCancel={onClose} hideSubmit={!draftGamesIds?.length} />}
        />
      </RecordContextProvider>
    </DialogContent>
  </Dialog>
}

const Delete = ({ selectedIds }) => {
  const [ open, setOpen ] = useState(false);
  const { onUnselectItems } = useListContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const onSubmit = async () => {
    apiClient(`/draftgames/bulkDelete`, {
      method: 'DELETE',
      data: {
        draftGamesIds: selectedIds,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        refresh();
        onUnselectItems();
        notify(translate('resources.draftGames.notifications.delete_draft', { smart_count: count }), 'success')
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.draftGames.notifications.delete_draft_error', { smart_count: selectedIds.length }), 'error')
      })

    setOpen(false);
  }

  return <>
    <Button label={translate('ra.action.delete')} onClick={() => setOpen(true)} disabled={!selectedIds.length} />
    <DeleteDialog open={open} onSubmit={onSubmit} onClose={() => setOpen(false)} draftGamesIds={selectedIds} />
  </>
}

export const DraftGamesBulkActions = props => {
  const scopes = useScopes();
  const canEditDrafts = isAuthorized(scopes, 'draftGames', 'edit');
  const canDeleteDrafts = isAuthorized(scopes, 'draftGames', 'remove');

  return <>
    {canEditDrafts && <ChangeDate {...props} />}
    {canEditDrafts && <ClearDate {...props} />}
    {canEditDrafts && <ChangeStatus {...props} />}
    {canDeleteDrafts && <Delete {...props} />}
  </>
}
