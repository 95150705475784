import React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid } from '@material-ui/core';

import { getGameStatus, isGameCompleted, isGameScheduled, isLineupApproved } from '@hisports/scoresheet/src/selectors';
import { isCoach } from '@hisports/scoresheet/src/util';
import { ENDED, MANAGER, STARTED, APPROVED } from '@hisports/scoresheet/src/constants';

import { FF_GOALIES, FF_INCIDENTS, FF_PITCHERS, FF_REQUIRE_GAME_START, FF_REQUIRE_LINEUPS, FF_REQUIRE_TEAMS } from '@hisports/common/featureFlags';

import { useParticipant, useScopes, useFlag, usePermissions } from '../../http';

import { hasAnyScope, isAuthorized } from '../../common/Authorize';

import GameDocumentsCard from '../games/cards/GameDocumentsCard';
import { useIsHomeFirst } from '../events/EventViewSettings';
import { useBranchSettings } from '../branchOfficeSettings';

import { SummaryCard } from './summary/SummaryCard';
import { LineupCard } from './lineup/LineupCard';
import { GoalieShotsCard } from './shots/GoalieShotsCard'
import { ThrowsInningsCard } from './baseball/ThrowsInningsCard';
import { SuspensionsCard } from './suspension/SuspensionsCard';
import { IncidentReportCard } from './gameIncidents/IncidentReportCard';
import { GameActionAlerts } from './actions/alerts';

import { ScoresheetProvider, useMeta, useScoresheet } from './ScoresheetContext';
import { getFilteredScopes } from '../../http/restClient/response';

export const isStaffMember = member => isCoach(member) || (member?.positions || []).includes(MANAGER);

const showSummaryCard = (scoresheet, game, isEnabled) => {
  const isScheduled = isGameScheduled(scoresheet)
  const lineupApproved = isLineupApproved(scoresheet, { teamId: game.homeTeamId }) && isLineupApproved(scoresheet, { teamId: game.awayTeamId })
  const isCompleted = isGameCompleted(scoresheet)

  if (isCompleted) {
    // Show if game completed
    return true
  } else if (isEnabled(FF_REQUIRE_GAME_START)) {
    // Game started is required
    return !isScheduled && lineupApproved
  } else if (isEnabled(FF_REQUIRE_LINEUPS)) {
    // Lineups need to be approved
    return lineupApproved
  } else if (isEnabled(FF_REQUIRE_TEAMS)) {
    // Only teams are required
    return game.homeTeamId && game.awayTeamId
  }
}

const ScoresheetLayout = ({ game, status }) => {
  const participantId = useParticipant();
  const isEnabled = useFlag()
  const scopes = useScopes();
  const isHomeFirst = useIsHomeFirst();
  const { meta } = useMeta();
  const scoresheet = useScoresheet(scoresheet => scoresheet)
  const permissions = usePermissions();
  const { data: branchSettings } = useBranchSettings(game?.officeId);

  const filteredScopes = getFilteredScopes(permissions, game?.officeId);

  const isHomeTeamOfficeScorekeeper = permissions.some(p =>
    p.roleType === 'System' ||
    (
      hasAnyScope(p.scopes, ['scoresheets:manage'])
      && p.roleName === 'scorekeeper'
      && (p?.officeIds || []).includes(meta?.teams?.[game?.homeTeamId]?.officeId)
    )
  );

  const isCompleted = isGameCompleted(scoresheet)

  const isAssignedOfficial = ['confirmed', 'assigned'].includes(status?.officials?.self)
  const isHomeStaff = meta?.teams[game?.homeTeamId]?.members.some(member => member.participantId === participantId && isStaffMember(member));
  const isAwayStaff = meta?.teams[game?.awayTeamId]?.members.some(member => member.participantId === participantId && isStaffMember(member));
  const isTeamScorekeeper = (meta?.policies.awayTeamScorekeeping && status?.scorekeeping && isAwayStaff) || (meta?.policies.homeTeamScorekeeping && status?.scorekeeping && isHomeStaff)
  const isScorekeeper = isAssignedOfficial || isTeamScorekeeper || isHomeTeamOfficeScorekeeper

  const showSummary = showSummaryCard(scoresheet, game, isEnabled)
  const canViewGameDocuments = isAuthorized(scopes, 'gameDocuments', 'list')
  const showSuspensions = isCompleted && isAuthorized(scopes, 'suspensions', 'show') && branchSettings?.ff_suspensions;
  const showIncidentsCard = showSummary && (isAuthorized(filteredScopes.filter(scope => scope != 'assigning:official'), 'gameIncidents', 'show') || isAssignedOfficial) && isEnabled(FF_INCIDENTS);
  const showHomeLineup = isCompleted || isScorekeeper || status?.lineups?.[game.homeTeamId] != null;
  const showAwayLineup = isCompleted || isScorekeeper || status?.lineups?.[game.awayTeamId] != null;
  const showGoalieShots = isEnabled(FF_GOALIES) && [STARTED, ENDED, APPROVED].includes(getGameStatus(scoresheet));
  const showHomeThrowsInnings = isEnabled(FF_PITCHERS) && isLineupApproved(scoresheet, { teamId: game.homeTeamId }) && (status?.lineups?.[game.homeTeamId] != null || isScorekeeper);
  const showAwayThrowsInnings = isEnabled(FF_PITCHERS) && isLineupApproved(scoresheet, { teamId: game.awayTeamId }) && (status?.lineups?.[game.awayTeamId] != null || isScorekeeper);

  const md = showHomeLineup && showAwayLineup ? 6 : 12
  return <Grid container spacing={3}>
    <Grid item xs={12}>
      <GameActionAlerts isAssignedOfficial={isAssignedOfficial} isScorekeeper={isScorekeeper} />
    </Grid>
    {showSummary && <Grid item xs={12}>
      <SummaryCard status={status} isAssignedOfficial={isAssignedOfficial} isScorekeeper={isScorekeeper} />
    </Grid>}
    {canViewGameDocuments && <Grid item xs={12}>
      <GameDocumentsCard />
    </Grid>}
    {showIncidentsCard && <Grid item xs={12}>
      <IncidentReportCard isAssignedOfficial={isAssignedOfficial} />
    </Grid>}
    {showSuspensions && <Grid item xs={12}>
      <SuspensionsCard />
    </Grid>}
    {(isHomeFirst ? showHomeLineup : showAwayLineup) && <Grid item xs={12} md={md}>
      <LineupCard
        title={`resources.scoresheets.labels.${isHomeFirst ? 'home' : 'away'}.lineup`}
        status={status}
        teamId={isHomeFirst ? game.homeTeamId : game.awayTeamId}
      />
    </Grid>}
    {(isHomeFirst ? showAwayLineup : showHomeLineup) && <Grid item xs={12} md={md}>
      <LineupCard
        title={`resources.scoresheets.labels.${isHomeFirst ? 'away' : 'home'}.lineup`}
        status={status}
        teamId={isHomeFirst ? game.awayTeamId : game.homeTeamId}
      />
    </Grid>}
    {showGoalieShots && <Grid item xs={12}>
      <GoalieShotsCard status={status} />
    </Grid>}
    {isEnabled(FF_PITCHERS) && <Grid item xs={12} md={md}>
      <ThrowsInningsCard teamId={isHomeFirst ? game.homeTeamId : game.awayTeamId} isScorekeeper={isScorekeeper} canEdit={isHomeFirst ? showHomeThrowsInnings : showAwayThrowsInnings} />
    </Grid>}
    {isEnabled(FF_PITCHERS) && <Grid item xs={12} md={md}>
      <ThrowsInningsCard teamId={isHomeFirst ? game.awayTeamId : game.homeTeamId} isScorekeeper={isScorekeeper} canEdit={isHomeFirst ? showAwayThrowsInnings : showHomeThrowsInnings} />
    </Grid>}
  </Grid>
}

export default ({ status, ...props }) => {
  const game = useRecordContext();

  return <ScoresheetProvider gameId={game.id} officeId={game.officeId}>
    <ScoresheetLayout game={game} status={status} {...props} />
  </ScoresheetProvider>
}
