import React from 'react';
import { ReferenceField } from 'react-admin';
import { Chip, makeStyles } from '@material-ui/core';
import { SupervisorAccountOutlined } from '@material-ui/icons';

import { FF_SSO } from '@hisports/common/featureFlags';

import FunctionField from '../../common/fields/FunctionField'
import { useFlag } from '../../http';

import { AccountField } from '../accounts/AccountField';
import { ParticipantField } from '../participants/ParticipantField';
import { IdentityDatagrid } from './IdentityModal';


const useStyles = makeStyles(theme => ({
  headerRow: {
    display: 'none',
  },
  row: {
    '& .sso': {
      width: theme.spacing(0),
    },
  },
  ssoChip: {
    color: theme.palette.lightBlue[500],
    borderColor: theme.palette.lightBlue[500],
    backgroundColor: theme.palette.lightBlue[50],
  },
  ssoIcon: {
    color: theme.palette.lightBlue[500]
  }
}))

const PrimaryField = props =>
  <FunctionField {...props} render={(identity, source, resource, translate) => {
    if (identity.isPrimary) return translate('resources.participants.fields.isPrimary')
  }} />

const ExternalIdField = props => {
  const classes = useStyles();

  return <ReferenceField basePath="/accounts" resource="accounts" reference="accounts" link={false} {...props}>
    <FunctionField {...props} render={(account, source, resource, translate) => {
      if (account.externalId) return <Chip
        className={classes.ssoChip}
        size="small"
        variant="outlined"
        label={translate('components.login.chip.sso')}
        icon={<SupervisorAccountOutlined className={classes.ssoIcon} style={{ marginLeft: 8 }} />}
      />
    }} />
  </ReferenceField>
}

export const IdentityGrid = ({ showAccount = true, showParticipant = true, component: Datagrid = IdentityDatagrid, ...props }) => {
  const classes = useStyles()
  const isEnabled = useFlag();

  return <Datagrid classes={classes} size="medium" {...props}>
    {isEnabled(FF_SSO) && showAccount && <ExternalIdField cellClassName="sso" source="accountId" />}
    {showAccount && <AccountField source="accountId" allowEmpty label="" />}
    {showParticipant && <ParticipantField source="participantId" allowEmpty label="" />}
    <PrimaryField />
  </Datagrid>
}
