import {
  PENALTY_STARTED,
  PENALTY_ENDED,
  PENALTY_EDITED,
  PENALTY_DELETED,
  SCORESHEET_RESET,
} from '../actions';

import { append, update, replace, removeById } from '../util';

const toPenalty = (event, meta = {}) => ({
  isEnded: false,
  ...meta,
  ...event
})

export default (state = [], action, scoresheet) => {
  switch (action.type) {
    case PENALTY_STARTED: {
      const { id, event: penalty } = action.payload;

      return append(state, toPenalty(penalty, { id }))
    }

    case PENALTY_ENDED: {
      const { penaltyId, endTime } = action.payload.event;

      return update(state, penaltyId, {
        isEnded: true,
        endTime
      })
    }

    case PENALTY_EDITED: {
      const { penaltyId, ...penalty } = action.payload.event;

      return replace(state, penaltyId, original => toPenalty(penalty, {
        id: penaltyId,
        isEnded: original && original.isEnded || false,
        endTime: penalty.endTime
      }));
    }

    case PENALTY_DELETED: {
      const { penaltyId } = action.payload.event;

      return removeById(state, penaltyId)
    }

    case SCORESHEET_RESET: {
      return [];
    }

    default:
      return state;
  }
}
