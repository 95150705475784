import { capitalize } from 'lodash';
import moment from 'moment-timezone';

export const isDexterity = value => {
  if (!value) return null;
  const dexterity = capitalize(value).trim();
  if (!['Left', 'Right'].includes(dexterity)) return false;
  return dexterity;
}

export const toDexterity = input => {
  if (!input) return;
  const dexterity = capitalize(input).trim();
  if (!['Left', 'Right'].includes(dexterity)) return;
  return dexterity;
}

export const isHeight = /\d['�][\s]*\d{1,2}["�][\s]*/ // covering the odd case that replacement characters happen

export const toHeight = value => {
  if (!value) return;
  const height = value.replace(/[^0-9]/g, '');
  const feet = height.charAt(0);
  const inches = height.slice(1);
  return `${feet}' ${inches}"`
}

export const isDate = /\d\d\d\d-\d\d-\d\d/

export const toDate = value => {
  return moment.tz(value, 'YYYY-MM-DD', 'UTC').toISOString(true)
}
