import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';

import { dedupe } from '@hisports/parsers';

import { authService } from '../index';

export const isTemporaryId = id =>
  id && typeof id === 'string' && id.startsWith('_')

export const getIdProperty = resource => {
  switch (resource) {
    case 'scoresheet':
    case 'gameassignsettings':
      return 'gameId';

    case 'schedulesettings':
      return 'scheduleId';

    case 'officesettings':
    case 'branchofficesettings':
    case 'officeassignsettings':
    case 'effectiveofficesettings':
    case 'effectivebranchofficesettings':
    case 'effectiveofficeassignsettings':
      return 'officeId';

    default:
      return 'id'
  }
}

export const hasContent = resource =>
  ['posts', 'pages', 'webevents', 'announcements', 'articles'].includes(resource)

const _parseResponse = (resource, data, scopes) => {
  if (!data) return;

  // react-admin requires an id property
  const ID = getIdProperty(resource);
  if (ID !== 'id') {
    data.id = data[ID];
  }

  switch (resource) {
    case 'notifications':
    case 'officialassignments': {
      data.id = data.id || `_${uuid()}`
      break;
    }
    case 'permissions': {
      data.principalId = Number(data.principalId);
      break;
    }

    case 'practices': {
      data.type = 'Practice';
      break;
    }
  }

  if (hasContent(resource)) {
    data.content = JSON.parse(data.content);
  }

  if (scopes) {
    data._scopes = scopes;
    Object.defineProperty(data, '_scopes', {
      enumerable: true,
      configurable: false,
      writable: false,
    })
  }

  const permissions = authService.getPermissions();
  const officeId = (resource === 'offices') ? data.id : data.officeId;
  const scheduleId = (resource === 'schedules') ? data.id : data.scheduleId;

  // List of resource to get filtered scopes
  const filteredResource = ['games', 'draftgames', 'offices', 'schedules'];
  let filteredScopes

  // Get filtered record scopes if you find a permission with side inheritance
  // and resource is include in filteredResource
  if (permissions.some(p => p?.inherited != false) && filteredResource.includes(resource)) {
    filteredScopes = getFilteredScopes(permissions, officeId, scheduleId)
  }

  if (!isEmpty(filteredScopes)) {
    data._filteredScopes = filteredScopes;
    Object.defineProperty(data, '_filteredScopes', {
      enumerable: true,
      configurable: false,
      writable: false,
    })
  }

  return data;
}

export const parseResponse = (resource, data, headers) => {
  if (!data) return;

  const scopes = headers && headers['x-authorized-roles']?.split(',');

  if (Array.isArray(data)) {
    return data.map(entity => _parseResponse(resource, entity, scopes));
  }
  return _parseResponse(resource, data, scopes);
}

export const getTotal = (headers, data) => {
  if (!("x-total-count" in headers)) return data.length;
  return parseInt(headers["x-total-count"].split("/").pop(), 10);
}

/**
 * Get filtered scopes for a record from their officeId and/or scheduleId
 * Currently only support Office and Schedule permission role types
 */
export const getFilteredScopes = (permissions = [], officeId, scheduleId, homeOfficeId) => {
  const scopes = []

  if (permissions.some(p => p.roleType === 'System')) {
    return authService.getScopes();
  }

  if (officeId) {
    permissions.forEach(p => {
      if (p.roleType === 'Office' && p.officeIds?.includes(officeId)) {
        scopes.push(...p.scopes);
      }
    })
  }

  if (scheduleId) {
    permissions.forEach(p => {
      if (p.roleType === 'Schedule' && p.scheduleIds?.includes(scheduleId)) {
        scopes.push(...p.scopes);
      }
    })
  }

  if (homeOfficeId) {
    permissions.forEach(p => {
      if (p.roleType === 'Office' && p.officeIds?.includes(homeOfficeId)) {
        scopes.push(...p.scopes);
      }
    })
  }

  permissions.forEach(p => {
    if (p.roleType !== 'Office' && p.roleType !== 'Schedule') {
      scopes.push(...p.scopes);
    }
  })

  return dedupe(scopes);
}
