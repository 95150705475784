import { OFFICIAL_STATUS_ASSIGNED, OFFICIAL_STATUS_CONFIRMED, OFFICIAL_STATUS_DECLINED, OFFICIAL_STATUS_NO_SHOW, SCOREKEEPER, TIMEKEEPER } from '../constants';
import { isReferee, isLinesperson, isSupervisor, isKeeper, sortOfficials } from '../util';

export const getKeepers = (state) => {
  if (!state) return {};
  return getOfficials(state).filter(isKeeper)
}

export const getGameScorekeeper = (state) => {
  const keepers = getKeepers(state);
  const scorekeeper = keepers.find(keeper => keeper.position === SCOREKEEPER)

  if (!scorekeeper || !scorekeeper.participant) return null;
  if (typeof scorekeeper === 'string') return scorekeeper;
  return scorekeeper.participant.fullName;
}

export const getGameTimekeeper = (state) => {
  const keepers = getKeepers(state);
  const timekeeper = keepers.find(keeper => keeper.position === TIMEKEEPER)

  if (!timekeeper || !timekeeper.participant) return null;
  if (typeof timekeeper === 'string') return timekeeper;
  return timekeeper.participant.fullName;
}

export const getOfficials = (state, { assignedOnly = false, noShowOnly = false, declinedOnly = false } = {}) => {
  if (!state) return [];
  const filters = []

  state.officials = sortOfficials(state.officials)

  if (assignedOnly) {
    filters.push(OFFICIAL_STATUS_ASSIGNED, OFFICIAL_STATUS_CONFIRMED)
  }
  if (noShowOnly) {
    filters.push(OFFICIAL_STATUS_NO_SHOW)
  }
  if (declinedOnly) {
    filters.push(OFFICIAL_STATUS_DECLINED)
  }

  if (!filters.length) return state.officials
  return state.officials.filter(official => filters.includes(official.status))
}

export const getGameReferees = (state, { assignedOnly }) => {
  return getOfficials(state, { assignedOnly }).filter(isReferee);
}

export const getGameLinespersons = (state, { assignedOnly }) => {
  return getOfficials(state, { assignedOnly }).filter(isLinesperson);
}

export const getGameSupervisors = (state, { assignedOnly }) => {
  return getOfficials(state, { assignedOnly }).filter(isSupervisor);
}

export const getOfficialsCount = (state, { assignedOnly = false }) => {
  const officials = getOfficials(state, { assignedOnly })
  const totals = {
    totalReferee: 0,
    totalLinesperson: 0,
    totalSupervisor: 0,
  }
  return officials.reduce((total, official) => {
    if (isReferee(official)) total.totalReferee++;
    if (isLinesperson(official)) total.totalLinesperson++;
    if (isSupervisor(official)) total.totalSupervisor++;
    return total;
  }, totals)
}

export const isKeepersReady = (state, type = null) => {
  if (!state) return false;
  const scorekeeper = getGameScorekeeper(state)
  const timekeeper = getGameTimekeeper(state)

  if (type === SCOREKEEPER) return !!scorekeeper
  if (type === TIMEKEEPER) return !!timekeeper

  return !!scorekeeper && !!timekeeper
}

export const isOfficialsReady = (state) => {
  if (!state) return false;
  const officials = getOfficials(state, { assignedOnly: true });
  // require at least one referee or one linesperson
  return officials.some(official => isReferee(official) || isLinesperson(official));
}
