import { v5 as v5uuid } from 'uuid';
import { OFFICIAL_ADDED, OFFICIAL_UPDATED, OFFICIAL_REMOVED, OFFICIAL_ASSIGNED, OFFICIAL_REQUESTED, OFFICIAL_ACCEPTED, OFFICIAL_DECLINED, GAME_RESCHEDULED, SCORESHEET_APPROVED, GAME_FORFEITED, OFFICIAL_DELEGATED, KEEPERS_UPDATED } from "../actions";
import { OFFICIAL_STATUS_CONFIRMED, OFFICIAL_STATUS_ASSIGNED, OFFICIAL_STATUS_REQUESTED, OFFICIAL_STATUS_DECLINED, OFFICIAL_STATUS_NO_SHOW, SCHEDULED, SCOREKEEPER, TIMEKEEPER } from "../constants";
import { append, update, replace, convertOfficialPositions, removeById } from "../util";

export default (state = [], action, scoresheet, prevScoresheet) => {
  switch (action.type) {
    case OFFICIAL_DELEGATED: {
      const { officialId, position, officeId, payOfficeId, feesId } = action.payload.event;

      const existing = state.find(official => official.id === officialId);
      if (existing && !existing.participant && !officeId) return removeById(state, officialId)

      const payload = official => ({ ...official, id: officialId, position, officeId, payOfficeId, feesId })

      return replace(state, officialId, payload)
    }

    case OFFICIAL_REQUESTED: {
      const { official, position, notes } = action.payload.event;

      // request is ignored if official already exists (assigned/requested)
      const exists = state.some(
        position => position.participantId === official.participantId
      )
      if (exists) return state;

      const payload = {
        id: official.id || official.participant.id,
        participantId: official.participantId,
        participant: official.participant,
        position,
        status: OFFICIAL_STATUS_REQUESTED,
        notes,
      }

      return append(state, payload, 'participantId')
    }

    case OFFICIAL_ASSIGNED: {
      const { official, position, notes } = action.payload.event;
      const { id, participantId, participant } = official;

      const payload = official => ({
        ...official,
        id: id || participant.id,
        participantId: participantId,
        participant: participant,
        position,
        status: OFFICIAL_STATUS_ASSIGNED,
        notes
      })

      // remove existing requests
      const filtered = state.filter(entity => entity.participantId != official.participantId || entity.id == official.id)

      // add/update new assignment
      return replace(filtered, id, payload)
    }

    case OFFICIAL_ACCEPTED: {
      const { participantId, notes } = action.payload.event;

      const payload = official => {
        if (!official) return;
        return {
          ...official,
          status: OFFICIAL_STATUS_CONFIRMED,
          notes,
        }
      }

      // replace will ignore if not exists (empty append) and ensure only one position exists
      return replace(state, participantId, payload, 'participantId')
    }

    case OFFICIAL_ADDED: {
      let { official, positions, position, officeId, feesId, notes, signature } = action.payload.event;
      const { id, participantId, participant } = official;

      if (Array.isArray(positions)) {
        position = convertOfficialPositions(positions);
      }

      // silently ignore if participant has that position already
      const exists = participantId && state.some(entity => entity.participantId === participantId && entity.position === position)
      if (exists) return state

      const payload = official => ({
        ...official,
        id: id || participant.id,
        participantId: participantId,
        participant: participant,
        position,
        officeId,
        feesId,
        status: OFFICIAL_STATUS_CONFIRMED,
        notes,
        signature,
      })

      // add/update new assignment
      return replace(state, id, payload)
    }

    case OFFICIAL_DECLINED: {
      const { participantId, notes, removed } = action.payload.event;

      const payload = official => {
        if (!official) return;
        return {
          ...official,
          status: OFFICIAL_STATUS_DECLINED,
          notes,
        }
      }

      // replace will ignore if not exists (empty append) and ensure only one position exists
      if (!removed) return replace(state, participantId, payload, 'participantId')
      // falls through if removed is true
    }

    case OFFICIAL_REMOVED: {
      const { participantId, officialId, isNoShow } = action.payload.event;

      return state.map(official => {
        const isAssignmentOrRequest = officialId ? (official.id && official.id === officialId) : (official.participantId && official.participantId === participantId)
        const isDelegatedAssignment = official.officeId

        if (!isAssignmentOrRequest) return official

        if (isNoShow) return { ...official, status: OFFICIAL_STATUS_NO_SHOW }

        if (!isDelegatedAssignment) return

        const { id, officeId, payOfficeId, feesId, position } = { ...official }
        return { id, officeId, payOfficeId, feesId, position }
      }).filter(Boolean)
    }

    case OFFICIAL_UPDATED: {
      let { participantId, officialId, positions, position, status } = action.payload.event;

      if (Array.isArray(positions)) {
        position = convertOfficialPositions(positions);
      }

      const changes = {}
      if (position) changes.position = position;
      if (status) changes.status = status;

      if (participantId) {
        return update(state, participantId, changes, 'participantId')
      } else {
        return update(state, officialId, changes);
      }
    }

    case KEEPERS_UPDATED: {
      const { scorekeeper, timekeeper } = action.payload.event;

      return [
        { fullName: scorekeeper, position: SCOREKEEPER },
        { fullName: timekeeper, position: TIMEKEEPER }
      ].reduce((state, { fullName, position, signature = null }) => {
        // event id + position to always generate the same official id
        const id = v5uuid(position, action.payload.id)
        const [ firstName, lastName ] = (fullName || '').split(' ')
        const payload = {
          id,
          position,
          ...(fullName ? {
            participant: {
              fullName,
              firstName,
              lastName
            }
          } : {}),
          status: OFFICIAL_STATUS_CONFIRMED,
          signature
        }

        return replace(state, position, payload, 'position');
      }, state)
    }

    case SCORESHEET_APPROVED: {
      const { officials: approvals = [], timekeeper, scorekeeper } = action.payload.event;

      return state.map(official => {
        const approval = approvals.find(approval => {
          if (approval.officialId == official.id && official.id != null) return true;
          if (approval.participantId == official.participantId && official.participantId != null) return true;
          return false;
        })
        if (approval && approval.signature) {
          official.signature = approval.signature;
        } else if (official.position === SCOREKEEPER && scorekeeper) {
          official.signature = scorekeeper.signature
        } else if (official.position === TIMEKEEPER && timekeeper) {
          official.signature = timekeeper.signature
        }
        return official;
      })
    }

    case GAME_RESCHEDULED: {
      const { startTime, arenaId } = action.payload.event;

      // skip confirmation reset if time or surface didn't change
      // this will ignore changes to only status, endTime, home/awayTeamId, comments
      if (!(startTime || arenaId)) return state;

      return state.map(official => {
        if (official.status === OFFICIAL_STATUS_CONFIRMED) {
          official.status = OFFICIAL_STATUS_ASSIGNED;
        }
        return official;
      })
    }

    case GAME_FORFEITED: {
      // remove all officials from the scoresheet when a game is forfeited but not yet started
      if (prevScoresheet.status !== SCHEDULED) return state;
      return []
    }

    default:
      return state;
  }
}
