import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField'

export const ParticipantGrid = props =>
  <Datagrid size="medium" {...props}>
    <FunctionField source="lastName" label="resources.participants.fields.name" render={participant => participant.fullName} />
    <TextField source="HCRId" />
  </Datagrid>
