import React, { useContext, useEffect, useState } from 'react';
import { SimpleShowLayout, useQuery, useRecordContext, GET_LIST, useTranslate } from 'react-admin';
import { Grid, makeStyles } from '@material-ui/core';
import { ViewQuiltOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { usePermissions } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { OfficeContext } from '../../common/inputs/OfficeSelector';

import AvailabilityCard from '../availabilities/AvailabilityCard';

import SurfaceCard from './cards/SurfaceCard';
import OfficesCard from './cards/OfficesCard';
import EventsCard from './cards/EventsCard';
import SurfacesCard from './cards/SurfacesCard';
import ArenaSlotsCard from './cards/ArenaSlotsCard';
import { SurfaceField } from './SurfaceField';

const useArenaOffices = (surfaceId) => useQuery({
  type: GET_LIST,
  resource: 'arenaoffices',
  payload: {
    filter: { effectiveSurfaces: surfaceId, _scope: 'Tenant' },
    sort: { field: 'isPrimary', order: 'DESC' },
  }
}, { enabled: !!surfaceId })

const hasPermission = (permissions, officeId) => {
  if (!officeId) return false;
  const scopes = permissions.filter(p => p.roleType === 'System' || (p.roleType === 'Office' && p.officeIds.includes(officeId))).flatMap(p => p.scopes)
  return scopes.includes('scheduling:availability')
}

const useAlertStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  }
}))

const SplitAlert = () => {
  const classes = useAlertStyles();
  const translate = useTranslate()

  return <Alert severity="info" className={classes.root}>
    {translate("resources.surfaces.labels.alerts.message")} <SurfaceField source="parentId" link={false} />
  </Alert>
}


export const SurfaceShowLayout = (props) => {
  const permissions = usePermissions()
  const surface = useRecordContext(props);
  const { officeId, setOfficeId } = useContext(OfficeContext);
  const [ arenaOfficeIds, setArenaOfficeIds ] = useState([]);
  const { data: arenaOffices = [] } = useArenaOffices(surface?.id);

  const canListOffices = isAuthorized(surface, 'arenaoffices', 'list');
  const canListSlots = isAuthorized(surface, 'arenaslots', 'list')
  const canListAvailabilities = isAuthorized(surface, 'availabilities', 'list')
  const canEditAvailabilities = hasPermission(permissions, officeId)

  useEffect(() => {
    if (!arenaOffices.length) return;

    const authArenaOffices = arenaOffices.filter(arenaOffice => hasPermission(permissions, arenaOffice.officeId))
    const officeIds = authArenaOffices.map(arenaOffice => arenaOffice.officeId);
    setArenaOfficeIds(officeIds);

    const defaultArenaOffice = authArenaOffices[0] || arenaOffices[0]
    if (defaultArenaOffice) {
      setOfficeId(defaultArenaOffice.officeId)
    }
  }, [ permissions, arenaOffices, setOfficeId ])

  return <SimpleShowLayout>
    {surface?.parentId && <SplitAlert />}
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <SurfaceCard />
      </Grid>
      {canListOffices && <Grid item xs={12} md={6}>
        <OfficesCard />
      </Grid>}
      <Grid item xs={12}>
        <SurfacesCard
          title="resources.surfaces.labels.card.splitTitle"
          subtitle="resources.surfaces.labels.card.splitSubtitle"
          icon={<ViewQuiltOutlined />}
          collapsable
        />
      </Grid>
      {canListAvailabilities && <Grid item xs={12}>
        <AvailabilityCard collapsable editable={canEditAvailabilities} officeIds={arenaOfficeIds} />
      </Grid>}
      {canListSlots && <Grid item xs={12}>
        <ArenaSlotsCard
          title="resources.arenaslots.labels.card.title"
        />
      </Grid>}
      <Grid item xs={12}>
        <EventsCard />
      </Grid>
    </Grid>
  </SimpleShowLayout>
}
