import React from 'react';
import { ReferenceInput, ReferenceArrayInput, useLocale } from 'react-admin';

import { translateApiProperty } from '@hisports/common';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'

const filterRuleOption = name => ({ name })

export const formatCodes = infraction => {
  if (!infraction) return null;
  return infraction
    .filter(({ code }) => code)
    .sort((a, b) => a.code.localeCompare(b.code))
    .map(({ code }) => code)
    .join(' + ');
}

export const RuleOptionSuggestion = ({ suggestion: option }) => {
  const locale = useLocale();
  const primary = translateApiProperty(option, 'name', locale);
  const secondary = formatCodes(option?.infraction);
  return <AutocompleteSuggestion primary={primary} secondary={secondary} />
}

export const RuleOptionInput = ({ helperText, multiple, ...props }) => {
  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  return <Input
    reference="ruleOptions"
    filterToQuery={filterRuleOption}
    sort={{ field: ['name'], order: ['ASC'] }}
    perPage={25}
    {...props}
  >
    <AutocompleteInput
      optionText="name"
      suggestionComponent={RuleOptionSuggestion}
      filterKeys={['name']}
      multiple={multiple}
      helperText={helperText}
    />
  </Input>
}
