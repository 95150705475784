import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import ListCard from '../../../common/cards/ListCard';

import { IdentityGrid } from '../../identities/IdentityGrid';
import { AddIdentityButton } from '../../identities/IdentityModal';

export default props => {
  const account = useRecordContext(props);
  const translate = useTranslate()

  return <ListCard
    title="resources.participants.labels.card.title"
    reference="identities"
    target="accounts"
    label={translate('resources.identities.name', 2)}
    sort={{ field: 'isPrimary', order: 'DESC' }}
    rowsPerPageOptions={[]}
    addButton={<AddIdentityButton initialValues={{ accountId: account?.id }} />}
  >
    <IdentityGrid showAccount={false} />
  </ListCard>
}
