import React from 'react'
import { NumberInput, SimpleForm, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core'

import Toolbar from '../../common/ra/Toolbar';
import { ArrayI18nInput } from '../../common/inputs/I18nInput';
import { SanctionPositionGroupInput } from '../../common/inputs/EnumInputs';
import { useSeason } from '../../common/inputs/SeasonSelector';

import { RuleSectionInput } from '../infractions/RuleSectionInput';
import { SanctionInput } from './SanctionInput';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.positionGroups || !values.positionGroups.length) errors.positionGroups = 'ra.validation.required';

  if (values.accumulationCount == null) {
    errors.accumulationCount = 'ra.validation.required'
  } else if (values.accumulationCount <= 0) {
    errors.accumulationCount = 'ra.validation.greater_than_zero'
  }

  if (values.order == null) {
    errors.order = 'ra.validation.required'
  } else if (values.order <= 0) {
    errors.order = 'ra.validation.greater_than_zero'
  }
  return errors;
}

const inputProps = {
  resource: 'sanctions',
  basePath: '/sanctions',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const ParentSanctionInput = ({ office, seasonId, ...props }) => {
  // hide sanctions of current office
  const hasParentOffice = !!office?.parentId
  const getOptionDisabled = sanction => {
    if (sanction?.officeId === office?.id) return true
    return false
  }

  return <SanctionInput
    source="parentId"
    filter={{ seasonId, effectiveOffices: office?.id, includeParents: true, _scope: 'Parents' }}
    helperText="resources.sanctions.helpers.parentId"
    includeOffice
    disabled={!hasParentOffice}
    getOptionDisabled={getOptionDisabled}
    hideDisabled
    {...props}
  />
}

export default ({ office, ...props }) => {
  const seasonId = useSeason();

  return <SimpleForm toolbar={<Toolbar />} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12} md={8}>
        <TextInput source="name" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextInput source="code" helperText="ra.message.optional" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ArrayI18nInput {...inputProps} />
      </Grid>
      <Grid item xs={8}>
        <RuleSectionInput source="sectionId" filter={{ seasonId }} includeOffice {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberInput source="order" helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <ParentSanctionInput source="parentId" office={office} seasonId={seasonId} {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <NumberInput source="accumulationCount" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SanctionPositionGroupInput source="positionGroups" select multiple {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
