import { startCase } from 'lodash';

import { translateApiProperty } from '@hisports/common';

import { EVENT_TYPE_GOAL, EVENT_TYPE_PENALTY, EVENT_TYPE_GOALIE } from '../constants';
import { getMemberLabel } from './members';
import { isTimeEqual, displayTime } from './gameTime';
import { getFractionLabel, getInfractionRule, getInfractionType, isLegacyRulebookSeason } from './penalties';

const typeMapping = {
  isShorthanded: 'shg',
  isPowerplay: 'ppg',
  isEmptyNet: 'eng',
  isPenaltyShot: 'psg',
  isOwnGoal: 'og'
}

/**
* @param {object} i18n
* @param {string} i18n.assist_abbr
* @param {string} i18n.bench
* @param {string} i18n.coach
*/
export const getAssists = (event, members, i18n) => {
  if (!event.assistIds || !event.assistIds.length) return;
  const assists = event.assistIds.map(participantId => getMemberLabel(members, participantId, i18n));
  return `${i18n.assist_abbr}: ${assists.join(', ')}`;
}

/**
* @param {object} i18n
* @param {string} i18n.served_abbr
* @param {string} i18n.bench
* @param {string} i18n.coach
*/
export const getPenaltyServer = (event, members, i18n, { hideServedBy = false } = {}) => {
  if (!event.servedById || hideServedBy) return;
  return `${i18n.served_abbr}: ${getMemberLabel(members, event.servedById, i18n)}`;
}

/**
* @param {object} i18n
* @param {string} i18n.shg
* @param {string} i18n.ppg
* @param {string} i18n.eng
* @param {string} i18n.psg
*/
export const getGoalTypes = (event, i18n) => {
  const types = Object.entries(typeMapping).reduce((types, [type, key]) => {
    if (event[type]) {
      types.push(i18n[key]);
    }
    return types;
  }, []).filter(Boolean);

  return types.length ? `[${types.join(', ')}]` : '';
}

const getInfractionTypeLabel = (penalty, type, fraction, locale) => {
  if (!type) return startCase(penalty.duration);

  let infractionTypeLabel = translateApiProperty(type, 'name', locale)

  if (fraction) {
    infractionTypeLabel += ` ${fraction}`;
  }

  return infractionTypeLabel;
}

const getRuleLabel = (penalty, rule, locale, seasonId) => {
  if (!rule) return startCase(penalty.infraction);

  let label = translateApiProperty(rule, 'name', locale);

  const code = isLegacyRulebookSeason(seasonId) ? penalty.code : rule.code;

  if (code) {
    label = `${code} - ${label}`;
  }

  return label;
}

/**
* @param {object} i18n
* @param {string} i18n.start
* @param {string} i18n.ended
*/
export const getPenalty = (event, infractions, rules, types, penalties, i18n, sport, seasonId, locale, { hideStartTime = false } = {}) => {
  if (!event || (!event.infraction && !event.infractionId)) return null;

  const rule = getInfractionRule(event, infractions, rules, seasonId);
  const type = getInfractionType(event, infractions, types, seasonId);
  const fraction = getFractionLabel(event, infractions, types, rules, penalties, sport, seasonId);

  const ruleLabel = getRuleLabel(event, rule, locale, seasonId);
  const infractionTypeLabel = getInfractionTypeLabel(event, type, fraction, locale);

  let label = ''

  if (type && (type.overrideInfractionName || type.overrideRuleName)) {
    label = infractionTypeLabel;
  } else if (sport === 'Soccer') {
    label = !isLegacyRulebookSeason(seasonId) && rule ? ruleLabel : infractionTypeLabel;
  } else {
    label = `${ruleLabel} (${infractionTypeLabel})`;
  }

  if (!event.startTime || hideStartTime) return label;

  const isDelayed = !isTimeEqual(event.gameTime, event.startTime, sport);
  if (!isDelayed && !event.isEnded) return label;

  if (isDelayed && !event.isEnded) return `${label}\n${i18n.start}: ${displayTime(event.startTime, sport)}`;
  if (!isDelayed && event.isEnded) return `${label}\n${i18n.ended}: ${displayTime(event.endTime, sport)}`;
  return `${label}\n${i18n.start}: ${displayTime(event.startTime, sport)}, ${i18n.ended}: ${displayTime(event.endTime, sport)}`
}

/**
* @param {object} i18n
* @param {string} i18n.served_abbr
* @param {string} i18n.bench
* @param {string} i18n.coach
* @param {string} i18n.start
* @param {string} i18n.ended
*/
export const getPenaltyDetails = (event, infractions, rules, types, penalties, members, i18n, sport, seasonId, locale, options) => {
  const player = getMemberLabel(members, event.participantId, i18n);
  const servedBy = getPenaltyServer(event, members, i18n, options);
  const penalty = getPenalty(event, infractions, rules, types, penalties, i18n, sport, seasonId, locale, options);

  return [player, servedBy, penalty].filter(Boolean).join(' - ');
}

/**
* @param {object} i18n
* @param {string} i18n.shg
* @param {string} i18n.ppg
* @param {string} i18n.eng
* @param {string} i18n.psg
* @param {string} i18n.assist_abbr
* @param {string} i18n.bench
* @param {string} i18n.coach
*/
export const getGoalDetails = (event, members, i18n) => {
  const playerLabel = getMemberLabel(members, event.participantId, i18n);
  const goalTypes = getGoalTypes(event, i18n);

  const player = [playerLabel, goalTypes].join(' ');
  const assists = getAssists(event, members, i18n);

  return [player, assists].filter(Boolean).join(' - ');
}

/**
* @param {object} i18n
* @param {string} i18n.empty_net
* @param {string} i18n.put_in
* @param {string} i18n.coach
* @param {string} i18n.bench
*/
export const getGoalieDetails = (event, members, i18n) => {
  if (!event.onParticipantId) return i18n.empty_net;
  const goaliePutIn = getMemberLabel(members, event.onParticipantId, i18n);

  return `${goaliePutIn} - ${i18n.put_in}`
}

/**
* @param {object} i18n
* @param {string} i18n.shg
* @param {string} i18n.ppg
* @param {string} i18n.eng
* @param {string} i18n.psg
* @param {string} i18n.assist_abbr
* @param {string} i18n.served_abbr
* @param {string} i18n.bench
* @param {string} i18n.coach
* @param {string} i18n.start
* @param {string} i18n.ended
* @param {string} i18n.empty_net
* @param {string} i18n.put_in
*/
export const getSummaryEventDetails = (event, members, infractions, rules, types, penalties, i18n, sport, seasonId, locale) => {
  switch (event.eventType) {
    case EVENT_TYPE_GOAL:
      return getGoalDetails(event, members, i18n);
    case EVENT_TYPE_GOALIE:
      return getGoalieDetails(event, members, i18n);
    case EVENT_TYPE_PENALTY:
      return getPenaltyDetails(event, infractions, rules, types, penalties, members, i18n, sport, seasonId, locale);
  }
}
