import React from 'react';
import DefaultIcon from '@material-ui/icons/ViewList';
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux';
import { useResourceContext, useRecordContext, getResources } from 'react-admin';
import Helmet from 'react-helmet';

const useStyles = makeStyles({
  icon: {
    verticalAlign: 'middle',
    marginRight: 8,
    marginTop: -3,
  }
})

export const ResourceIcon = ({ className, ...props }) => {
  const resourceName = useResourceContext(props);
  const resource = useSelector(state => getResources(state).find(resource => resource.name === resourceName))
  const Icon = resource && resource.icon || DefaultIcon;
  return <Icon className={className} />
}

export const ResourceTitle = ({ title, classes: classOverrides, ...props }) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props)
  const classes = useStyles(classOverrides);
  return <>
    <Helmet title={title(record)} />
    <ResourceIcon className={classes.icon} />
    {title(record)}
  </>
}

export default ResourceTitle;
