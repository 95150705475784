import Moment from 'moment';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

export const momentRange = extendMoment(Moment);

export const createRange = (start, end) =>
  momentRange.range(start, end)

export const overlaps = (expectedRange, game, options) => {
  const range = createRange(
    moment.tz(game.startTime, game.timezone).toDate(),
    moment.tz(game.endTime, game.timezone).toDate()
  );
  return range.overlaps(expectedRange, options)
}

export const areConsecutiveGames = (game, otherGame) => {
  const gameRange = createRange(
    moment.tz(game.startTime, game.timezone).toDate(),
    moment.tz(game.endTime, game.timezone).toDate()
  );

  const consecutiveRange = createRange(
    moment.tz(game.startTime, game.timezone).subtract(1, 'hour').toDate(),
    moment.tz(game.endTime, game.timezone).add(1, 'hour').toDate()
  );

  if (overlaps(gameRange, otherGame)) return false;
  return overlaps(consecutiveRange, otherGame, { adjacent: true })
}

export const isSameTime = (first, second) => {
  if (!first || !second) return false;
  const isStartSame = moment.tz(first.startTime, first.timezone).isSame(moment.tz(second.startTime, second.timezone));
  const isEndSame = moment.tz(first.endTime, first.timezone).isSame(moment.tz(second.endTime, second.timezone));
  return isStartSame && isEndSame;
}

export const validateConstraintRanges = (constraint, games, date, homeTeamId, awayTeamId) => {
  const { limit, period } = constraint;
  const violations = [];

  for (let index = 0; index < period; index++) {
    const startDate = moment.utc(date).subtract((period - 1) - index, 'days').format('YYYY-MM-DD')
    const endDate = moment.utc(date).add(index, 'days').format('YYYY-MM-DD');
    const rangeGames = games.filter(game => moment.utc(game.date).isSameOrAfter(startDate, 'day') && moment.utc(game.date).isSameOrBefore(endDate, 'day'))

    const homeTeamGames = rangeGames.filter(game => homeTeamId && [game.homeTeamId, game.awayTeamId].includes(homeTeamId));
    if (homeTeamGames.length >= limit) {
      violations.push(
        ...homeTeamGames.map(game => {
          const offenderId = game.homeTeamId === homeTeamId ? game.homeTeamId : game.awayTeamId
          return {
            ...(game.toJSON ? game.toJSON() : game),
            constraint: {
              ...(constraint.toJSON ? constraint.toJSON() : constraint),
              teamId: offenderId,
              total: homeTeamGames.length,
            },
          }
        })
      )
    }

    const awayTeamGames = rangeGames.filter(game => awayTeamId && [game.homeTeamId, game.awayTeamId].includes(awayTeamId));
    if (awayTeamGames.length >= limit) {
      violations.push(
        ...awayTeamGames.map(game => {
          const offenderId = game.awayTeamId === awayTeamId ? game.awayTeamId : game.homeTeamId
          return {
            ...(game.toJSON ? game.toJSON() : game),
            constraint: {
              ...(constraint.toJSON ? constraint.toJSON() : constraint),
              teamId: offenderId,
              total: homeTeamGames.length,
            },
          }
        })
      )
    }
  }

  return violations
}
