import React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid, useMediaQuery } from '@material-ui/core';
import { AssignmentIndOutlined } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';

import { FF_DRAFT_GAMES } from '@hisports/common/featureFlags';

import { useFlag, useScopes } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { Tab, TabbedShowLayout } from '../../common/ra/TabbedShowLayout';
import SchedulerView from '../../common/calendar/scheduler/SchedulerView';
import { useBranchSettings } from '../branchOfficeSettings';

import ReportsCard from '../reports/ReportsCard';
import SchedulesCard from '../offices/cards/SchedulesCard';
import AvailabilityCard from '../availabilities/AvailabilityCard';
import GroupsCard from '../offices/cards/GroupsCard';
import SchedulingConstraintsCard from '../offices/cards/SchedulingConstraintsCard';
import NotificationCard from '../offices/cards/NotificationsCard';
import AssignRulesCard from '../offices/cards/AssignRulesCard';
import RolesCard from '../offices/cards/RolesCard';
import SuspensionsCard from './cards/SuspensionsCard';
import GamePenaltiesCard from './cards/GamePenaltiesCard';
import OfficeSettingsCard from '../offices/cards/OfficeSettingsCard';

const SummaryTab = props => {
  const office = useRecordContext(props);
  const scopes = useScopes();
  const { data: branchSettings } = useBranchSettings(office?.id)

  const showSchedules = isAuthorized(office, 'schedules', 'list')
  const showGamePenalties = isAuthorized(office, 'gamepenalties', 'show')
  const showSuspensions = isAuthorized(office, 'suspensions', 'show') && branchSettings?.ff_suspensions;
  const showReports = isAuthorized(scopes, 'reports', 'list');

  const showAvailabilitiesCard = isAuthorized(office, 'availabilities', 'list')
  const canEditAvailabilities = isAuthorized(office, 'availabilities', 'edit')

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showSchedules && <Grid item xs={12}>
        <SchedulesCard showOffice={false} />
      </Grid>}
      {showAvailabilitiesCard && <Grid item xs={12}>
        <AvailabilityCard collapsable editable={canEditAvailabilities} />
      </Grid>}
      {showGamePenalties && <Grid item xs={12}>
        <GamePenaltiesCard collapsable />
      </Grid>}
      {showSuspensions && <Grid item xs={12}>
        <SuspensionsCard collapsable />
      </Grid>}
      {showReports && <Grid item xs={12}>
        <ReportsCard targetType="Office" officeId={office.id} />
      </Grid>}
    </Grid>
  </Tab>
}

const SchedulerTab = props => {
  const office = useRecordContext();
  const isEnabled = useFlag();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const showScheduler = isAuthorized(office, 'offices', 'schedulerTab');

  if (!isEnabled(FF_DRAFT_GAMES) || isMobile || !showScheduler) return null;

  return <Tab {...props}>
    <SchedulerView office={office} />
  </Tab>
}

const SettingsTab = props => {
  const office = useRecordContext(props);

  if (!isAuthorized(office, 'offices', 'settingsTab')) return null

  const showGroups = isAuthorized(office, 'groups', 'list')
  const canShowConstraints = isAuthorized(office, 'schedulingConstraints', 'list')
  const showAssignRules = isAuthorized(office, 'assignRules', 'list')
  const showNotifications = isAuthorized(office, 'notifications', 'list')
  const showRoles = isAuthorized(office, 'roles', 'list')
  const showSettings = isAuthorized(office, 'officeSettings', 'list')

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showSettings && <Grid item xs={12} md={6}>
        <OfficeSettingsCard />
      </Grid>}
      {showGroups && <Grid item xs={12}>
        <GroupsCard />
      </Grid>}
      {canShowConstraints && <Grid item xs={12}>
        <SchedulingConstraintsCard scheduleTypes={office.type} />
      </Grid>}
      {showAssignRules && <Grid item xs={12}>
        <AssignRulesCard
          title="resources.assignRules.labels.alt_card.title"
          subtitle="resources.assignRules.labels.alt_card.subtitle"
          icon={<AssignmentIndOutlined />}
          types={["officeId", "assignType"]} />
      </Grid>}
      {showRoles && <Grid item xs={12}>
        <RolesCard />
      </Grid>}
      {showNotifications && <Grid item xs={12}>
        <NotificationCard />
      </Grid>}
    </Grid>
  </Tab>
}

export const LeagueShowLayout = props => {
  const office = useRecordContext(props);

  if (office?.type !== 'League') return <Redirect to={`/offices/${office?.id}/show`} />
  return <TabbedShowLayout>
    <SummaryTab label="resources.offices.labels.tabs.summary" />
    <SchedulerTab label="resources.offices.labels.tabs.scheduler" path="scheduler" />
    <SettingsTab label="resources.offices.labels.tabs.settings" path="settings" />
  </TabbedShowLayout>
}
