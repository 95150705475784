import { useMemo } from 'react';
import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import blueGrey from '@material-ui/core/colors/blueGrey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import yellow from '@material-ui/core/colors/yellow';
import { enUS, frFR } from '@material-ui/core/locale'

import { useLocale } from './locale';

const theme = {
  palette: {
    purple,
    blueGrey,
    lightBlue,
    yellow,
    primary: { ...blue, main: '#3994ff', contrastText: '#fff' },
    secondary: grey,
    success: {
      ...green,
      contrastText: '#fff',
    },
    error: red,
    warning: orange,
    tertiary: {
      ...purple,
      light: purple[300],
      main: purple[500],
      dark: purple[600],
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        minWidth: 120,
      }
    },
    MuiFormHelperText: {
      root: {
        whiteSpace: 'pre-line',
      }
    },
    MuiTableCell: {
      root: {
        padding: 12,
      }
    },
    MuiList: {
      root: {
        backgroundColor: '#fff',
      }
    },
    MuiCardHeader: {
      action: {
        display: 'flex',
      }
    },
    RaShow: {
      root: {
        width: '100%',
        maxWidth: 8 * 150,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    RaCreate: {
      root: {
        width: '100%',
        maxWidth: 8 * 150,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    RaEdit: {
      root: {
        width: '100%',
        maxWidth: 8 * 150,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    RaTabbedShowLayout: {
      content: {
        paddingLeft: 0,
        paddingRight: 0,
      }
    },
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 9999,
      }
    },
    RaFileInputPreview: {
      removeButton: {
        display: 'none',
      }
    },
    RaImageField: {
      image: {
        maxHeight: '15rem',
        maxWidth: '100%',
      }
    },
    RaFilterForm: {
      form: {
        marginTop: 'auto',
        alignItems: 'center',
        minHeight: 40,
      }
    },
    RaTopToolbar: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        alignSelf: 'baseline',
        alignItems: 'center',
        minHeight: 40,
        '& .MuiButtonBase-root': {
          whiteSpace: 'pre',
        },
        '& .MuiButton-startIcon': {
          display: 'inline-block',
          position: 'relative',
          top: '2px',
        },
        '@media(max-width: 600px)': {
          display: 'flex',
          flexDirection: 'column',
        }
      },
    },
    RaList: {
      bulkActionsDisplayed: {
        marginTop: 0,
        '& > .MuiToolbar-root': {
          position: 'sticky',
          top: 0,
        }
      }
    },
    RaListToolbar: {
      toolbar: {
        // minHeight: 48,
        // '@media (max-width: 900px)': {
        //   flexDirection: 'column',
        // },
      },
      actions: {
        paddingTop: 8,
        alignItems: 'center',
        '@media (min-width: 600px)': {
          minWidth: 200,
        }
      }
    },
    RaFilter: {
      form: {
        '& [data-source="seasonId"]': {
          display: 'none',
        }
      }
    },
    RaFilterFormInput: {
      body: {
        '@media (max-width: 600px)': {
          width: '100%',
          '& > div:first-child': {
            width: '100%',
          },
          '& .MuiFormControl-root': {
            width: '100%',
          }
        }
      },
    },
    MuiAutocomplete: {
      groupLabel: {
        lineHeight: '24px',
        paddingTop: 8,
        paddingBottom: 8,
      },
      hasPopupIcon: {
        '&:not(.Mui-focused) label': {
          right: 48,
          whiteSpace: 'pre',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      }
    },
    MuiTabs: {
      root: {
        maxWidth: 'calc(100vw - 16px)',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      }
    },
    MuiAlert: {
      root: {
        borderRadius: 8,
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true,
    }
  }
}

export const useCreateTheme = () => {
  const [ locale ] = useLocale();

  const appTheme = useMemo(() => {
    let muiLocale = enUS;
    if (locale === 'fr') {
      muiLocale = frFR;
    }
    return createTheme(theme, muiLocale)
  }, [ locale ])

  return appTheme;
}
