import { isEmpty, range } from "lodash";

import { LEGACY_GAME_NUMBERING_SEASONS } from '@hisports/scoresheet/src/constants';

const LEADING_ZEROS = /^(0+).*/
const hasLeadingZeros = number => {
  const [ , leadingZeros ] = LEADING_ZEROS.exec(number) || []
  return leadingZeros != null;
}

export const GAME_NUMBER_REGEX = /^(.*?)(\d+)(\D*)$/
export const PATCHED_GAME_NUMBER_REGEX = /^(?:(?:[a-zA-Z0-9-])(?!--))*$/

// if no seasonId, we assume it's legacy
export const isLegacyGameNumbering = seasonId => !seasonId || LEGACY_GAME_NUMBERING_SEASONS.includes(seasonId)

export const validateGameNumber = (gameNumber, seasonId) => {
  if (!gameNumber) return

  const isValidGameNumber = GAME_NUMBER_REGEX.test(gameNumber);

  if (isLegacyGameNumbering(seasonId) || !isValidGameNumber) {
    return isValidGameNumber
  }

  // added more validation when its not legacy
  if (gameNumber.length > 15) return

  return PATCHED_GAME_NUMBER_REGEX.test(gameNumber);
}


export const getGameNumberValidationError = (gameNumber, seasonId) => {
  const CONTAINS_SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9-]/;
  const CONTAINS_DOUBLE_DASH_REGEX = /--/;
  const MISSING_NUMBER_REGEX = /^[^\d]+$/;

  if (!isLegacyGameNumbering(seasonId) && gameNumber.length > 15) return 'too_many_characters';

  if (!isLegacyGameNumbering(seasonId) && CONTAINS_DOUBLE_DASH_REGEX.test(gameNumber)) return 'cannot_contain_consecutive_dashes';

  if (!isLegacyGameNumbering(seasonId) && CONTAINS_SPECIAL_CHAR_REGEX.test(gameNumber)) return 'cannot_contain_special_characters';

  if (MISSING_NUMBER_REGEX.test(gameNumber)) return 'must_contain_a_number';
}


export const parseGameNumber = (gameNumber) => {
  if (gameNumber == null) return {};

  const [ , prefix, start, suffix ] = GAME_NUMBER_REGEX.exec(gameNumber) || [];
  const padLength = start != null ? start.length : 0;
  const number = Number(start) || 0;
  return {
    prefix,
    number,
    suffix,
    hasLeadingZeros: hasLeadingZeros(start),
    padLength,
  }
}

export const isInSequence = (gameNumber, sequence) => {
  if (gameNumber == null || !sequence) return false;

  const { prefix, number, suffix } = parseGameNumber(gameNumber);
  const { prefix: startPrefix, number: startNumber, suffix: startSuffix } = parseGameNumber(sequence.start);
  if (prefix !== startPrefix || suffix !== startSuffix) return false;

  return number >= startNumber && number <= sequence.max;
}

export const overlaps = (sequence, sequences) => {
  if (isEmpty(sequences)) return false;

  const { number: startNumber } = parseGameNumber(sequence.start);
  if (startNumber > sequence.max) return false;

  const sequenceRange = range(startNumber, sequence.max + 1);

  return sequences
    .filter(s => s.id !== sequence.id)
    .some(checkingSequence => {
      const { number: checkingNumber } = parseGameNumber(checkingSequence.start);
      if (checkingNumber > checkingSequence.max) return false;

      const checkingRange = range(checkingNumber, checkingSequence.max + 1);
      return checkingRange.some(num => sequenceRange.includes(num))
    })
}

export const getNextNumber = (sequence, seasonId) => {
  const { start, current, max } = sequence;

  // no more numbers
  if (current === max) return;

  // sequence is starting
  if (current == null) return start;

  // next number
  const { prefix, suffix, hasLeadingZeros, padLength } = parseGameNumber(start);
  const nextNumber = current + 1;
  const leadingZerosLength = padLength - nextNumber.toString().length;
  const leadingZeros = hasLeadingZeros && padLength > 1 ? '0'.repeat(leadingZerosLength) : ''

  const newGameNumber = `${prefix}${leadingZeros}${nextNumber}${suffix}`;

  if (!validateGameNumber(newGameNumber, seasonId)) return;

  return newGameNumber;
}
