import { isSuspended } from "../util";
import { getLineup, getMembers } from "./lineups";

export const hasUnselectedSuspendedPlayers = (scoresheet, team, memberValidations = [], sport) => {
  const lineup = getLineup(scoresheet, { teamId: team.id })
  if (!lineup) return false;

  // get all members including the non rostered ones
  const members = getMembers(lineup, team, sport)

  return members.some(member => {
    const memberValidation = memberValidations.find(({ participantId }) => participantId === member.participantId);
    const isInvalid = memberValidation && memberValidation.invalidMemberTypes && memberValidation.invalidMemberTypes.length > 0;
    const hasSuspension = isSuspended(member) || (memberValidation && memberValidation.suspensions && memberValidation.suspensions.length);
    const isInLineup = lineup.members.find(lineupMember => lineupMember.participantId === member.participantId);

    return hasSuspension && !isInLineup && !isInvalid
  })
}
