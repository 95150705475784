import React from 'react';
import { useTranslate } from 'react-admin';
import { TableRow, TableCell } from '@material-ui/core';

export default ({ label, children, hidden = false, cellClass }) => {
  if (hidden) return null;
  const translate = useTranslate();
  return <TableRow>
    {label &&
    <TableCell component="th" scope="row" className={cellClass}>
      {translate(label, { _: label, smart_count: 1 })}
    </TableCell>}
    <TableCell colSpan={label ? 1 : 2} className={cellClass}>{children}</TableCell>
  </TableRow>
}
