import React, { Fragment } from 'react';
import { SimpleForm, FormInput, TextInput, useNotify, useTranslate, useRefresh } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { FORM_ERROR } from 'final-form';

import { apiClient } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';

const validate = values => {
  const errors = {};
  if (!values.email) errors.email = 'ra.validation.required';
  return errors;
}

const SsoInviteForm = props =>
  <SimpleForm component={Fragment} validate={validate} variant="outlined" margin="normal" {...props}>
    <FormInput fullWidth input={
      <TextInput source="email" type="email" autoComplete="off" />
    } />
  </SimpleForm>

export const SsoInviteDialog = ({ participantId, open, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleCancel = () => onClose()
  const handleSubmit = async values => {
    return apiClient(`/participants/${participantId}/ssoInvite`, {
      method: 'POST',
      data: {
        email: values.email,
      },
    })
      .then(() => {
        onClose();
        notify('resources.participants.notifications.sso_created_and_linked', 'success');
        refresh();
      })
      .catch(err => {
        const error = err?.response?.data?.error?.message
        return { [FORM_ERROR]: error || 'ra.page.error' }
      })
  }

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.participants.labels.account_invitation_sso')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('resources.participants.messages.link_sso')}</DialogContentText>
      <SsoInviteForm initialValues={{ participantId }} save={handleSubmit} toolbar={
        <DialogFormToolbar submitLabel="ra.action.send" onCancel={handleCancel} />
      } />
    </DialogContent>
  </Dialog>
}
