import React, { useEffect, useRef, useState } from 'react';
import { BooleanInput, GET_MANY_REFERENCE, GET_ONE, NumberInput, RecordContextProvider, SimpleForm, TextInput, useLocale, useQuery, useRecordContext, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid, InputAdornment } from '@material-ui/core'
import createCalculator from 'final-form-calculate';
import { isEmpty } from 'lodash';

import { dedupe } from '@hisports/parsers';
import { FF_DISTANCE } from '@hisports/common/featureFlags';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { apiClient, useFlag, usePermissions } from '../../http';
import { formatCurrency, formatDistance } from '../../locale';

import Toolbar from '../../common/ra/Toolbar';
import CurrencyInput from '../../common/inputs/CurrencyInput';
import { ClaimStatusEnumInput, ClaimTypeEnumInput } from '../../common/inputs/EnumInputs';
import { hasAnyScope } from '../../common/Authorize';

import { OfficeInput } from '../offices/OfficeInput';
import { ParticipantInput } from '../participants/ParticipantInput';
import { GameInput } from '../games/GameInput';
import OfficialClaimDocumentsCard from '../games/cards/OfficialClaimDocumentsCard';
import { AddressInput } from '../addresses/AddressInput';

import { isDistance } from './ClaimsGrid';

export const useGameOfficials = (gameId) => useQuery({
  type: GET_MANY_REFERENCE,
  resource: 'officials',
  payload: {
    target: 'games',
    id: gameId,
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'gameId', order: 'ASC' },
    filter: { participantId: { neq: null } },
  }
}, {
  enabled: gameId != null,
})

export const useGameAssignSettings = (gameId) => useQuery({
  type: GET_ONE,
  resource: 'gameAssignSettings',
  payload: {
    id: gameId,
  }
}, {
  enabled: gameId != null,
})

const travelAmount = (amount, distance, exclude) => {
  if (!amount || !distance) return 0;
  if (exclude) {
    if (exclude >= distance) return 0;
    return amount * (distance - exclude);
  }
  return amount * distance
}

const travelCalculation = (rate, distance, exclude, locale) => {
  if (rate == null || distance == null) return null;
  if (exclude == null) return `${formatDistance(distance.toFixed(2))} x ${formatCurrency(rate, locale, true)}`;

  const nonExcluded = distance - exclude;
  return `(${formatDistance(nonExcluded < 0 ? distance.toFixed(2) : exclude.toFixed(2))} x ${formatCurrency(0, locale, true)}) + (${formatDistance(nonExcluded < 0 ? 0 : nonExcluded.toFixed(2))} x ${formatCurrency(rate, locale, true)})`
}

const getDistances = async (gameId, addressId) => {
  return await apiClient(`/games/${gameId}/distances?addressId=${addressId}`)
    .then(res => res?.data);
}

const getOfficeAssignSettings = officeId => {
  return apiClient('/effectiveOfficeAssignSettings', { params: { filter: { where: { officeId }, scope: 'Tenant' } } })
    .then(res => res?.data?.[0]);
}

const validate = values => {
  const errors = {};

  if (!values.participantId) errors.participantId = 'ra.validation.required'
  if (!values.gameId) errors.gameId = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'
  if (!values.type) errors.type = 'ra.validation.required'
  if (values.type && isDistance(values.type) && !values.distance) errors.distance = 'ra.validation.required'
  if (values.amount == null) errors.amount = 'ra.validation.required'
  if (values.distance && values.distance < 0) errors.distance = 'ra.validation.positive';

  return errors;
}

const inputProps = {
  resource: 'officialClaims',
  basePath: '/officialclaims',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const OfficeGameInput = ({ filter = {}, ...props }) => {
  const { values } = useFormState()
  const { officeId, participantId } = values || {};

  if (officeId) {
    filter = { and: [ filter, { or: [{ 'officials.officeId': officeId }, { 'assignSettings.officeId': officeId }] } ] }
  }

  if (participantId) {
    filter['officials.participantId'] = participantId;
  }

  return <GameInput filter={filter} {...props} />
}

const OfficialInput = ({ filter = {}, officials, ...props }) => {
  const { values } = useFormState()
  const { officeId } = values || {};

  if (officials) {
    let officeOfficials = officials.filter(official => official.officeId === officeId)
    if (!officeOfficials.length) {
      officeOfficials = officials.filter(official => official.officeId === null)
    }
    filter.id = { inq: officeOfficials.map(official => official.participantId) }
  }

  return <ParticipantInput filter={filter} {...props} />
}

const DistanceInput = props => {
  const translate = useTranslate();
  return <NumberInput {...props} InputProps={{
    endAdornment: <InputAdornment position="end">
      {translate('ra.input.adornments.kilometers')}
    </InputAdornment>
  }} />
}

const FormContent = (props) => {
  const { values, initialValues } = useFormState()
  const { gameId, officeId, type, amount, distance } = values || {};
  const [ officeAssignSettings, setOfficeAssignSettings ] = useState()
  const { data: gameAssignSettings } = useGameAssignSettings(gameId);
  const { data: officials } = useGameOfficials(gameId);
  const permissions = usePermissions();
  const translate = useTranslate();
  const claim = useRecordContext()
  const locale = useLocale();
  const isEnabled = useFlag();

  useEffect(() => {
    if (!officeId) return
    getOfficeAssignSettings(officeId)
      .then(settings => setOfficeAssignSettings(settings))
  }, [officeId])

  const officeIds = dedupe(officials?.map(official => official.officeId || gameAssignSettings?.officeId) || []);
  const isAssigner = permissions && permissions.some(({ roleType, scopes = [], officeIds = [], inherited }) =>
    (roleType === 'System') ||
    (roleType === 'Office' && inherited === false && hasAnyScope(scopes, ['assigning:manage', 'assigning:assign']) && officeIds.includes(officeId))
  )

  const showAddress = isEnabled(FF_DISTANCE) && ['Driver', 'Passenger'].includes(type);
  const showDistance = ['Driver', 'Passenger'].includes(type);
  const showStatus = isAssigner && claim?.id;
  const isApproved = initialValues?.status === 'Approved'
  const inputSize = showDistance ? 4 : 6;

  const claimHelperText = (() => {
    if (isEmpty(officeAssignSettings)) return null;
    const {
      claimableDriver,
      claimablePassenger,
      claimableBreakfast,
      claimableLunch,
      claimableDinner,
    } = officeAssignSettings;

    if (claimableDriver || claimablePassenger || claimableBreakfast || claimableLunch || claimableDinner) return null

    return 'resources.officialClaims.helpers.no_claim_types';
  })()

  const amountHelperText = (() => {
    const { driverExcludedDistance, passengerExcludedDistance, driverAmount, passengerAmount } = officeAssignSettings || {};
    if (!showDistance || amount == null || distance == null) return null;

    if (type === 'Driver') {
      return travelCalculation(driverAmount, distance, driverExcludedDistance, locale);
    }
    if (type === 'Passenger') {
      return travelCalculation(passengerAmount, distance, passengerExcludedDistance, locale)
    }
  })()

  const distanceHelperText = (() => {
    const { driverExcludedDistance, passengerExcludedDistance } = officeAssignSettings || {};
    if (!showDistance) return null;

    if (type === 'Driver' && driverExcludedDistance != null) {
      return translate('resources.officialClaims.helpers.distance', driverExcludedDistance)
    }

    if (type === 'Passenger' && passengerExcludedDistance != null) {
      return translate('resources.officialClaims.helpers.distance', passengerExcludedDistance)
    }
  })()

  return <Grid container spacing={2} fullWidth>
    <Grid item xs={12} sm={4}>
      <OfficeGameInput source="gameId" disabled={!!initialValues?.gameId || isApproved} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <OfficeInput source="officeId" disabled={!!initialValues?.officeId || !gameId || isApproved} filter={{ _scope: 'Tenant', id: { inq: officeIds }, type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] } }} {...inputProps} />
    </Grid>
    <Grid item xs={12} sm={4}>
      <OfficialInput source="participantId" disabled={!!initialValues?.participantId || !gameId || !officeId || !officials || isApproved} filter={{ _scope: 'Tenant' }} officials={officials} {...inputProps} />
    </Grid>

    <Grid item xs={12} sm={inputSize}>
      <ClaimTypeEnumInput source="type" disabled={!gameId || !officeId || isApproved || !!initialValues.type} settings={officeAssignSettings} helperText={claimHelperText} {...inputProps}
      />
    </Grid>
    {showAddress && <Grid item xs={12} sm={inputSize}>
      <AddressInput source="addressId" helperText="ra.message.optional" disabled={isApproved || !!initialValues.addressId} filter={{ 'participantId': values.participantId }} {...inputProps} />
    </Grid>}
    {showDistance && <Grid item xs={12} sm={inputSize}>
      <DistanceInput min={0} source="distance" disabled={isApproved || values.addressId} helperText={distanceHelperText} {...inputProps} />
    </Grid>}
    <Grid item xs={12} sm={inputSize}>
      <CurrencyInput source="amount" disabled nullable helperText={amountHelperText} {...inputProps} />
    </Grid>

    <Grid item xs={12}>
      <TextInput source="note" disabled={!gameId || isApproved} helperText="ra.message.optional" multiline minRows={3} {...inputProps} />
    </Grid>
    {showAddress && <Grid item xs={12} sm={inputSize}>
      <BooleanInput source="roundtrip" disabled={isApproved} helperText={distanceHelperText} {...inputProps} />
    </Grid>}
    {showStatus && <Grid item xs={12}>
      <ClaimStatusEnumInput source="status" disabled={!gameId} radio {...inputProps} />
    </Grid>}
    <RecordContextProvider value={{ id: gameId, claimId: initialValues?.id }}>
      <Grid item xs={12}>
        <OfficialClaimDocumentsCard />
      </Grid>
    </RecordContextProvider>
  </Grid>
}

export const ClaimForm = ({ initialValues = {}, ...props }) => {
  const decorators = useRef([createCalculator({
    field: 'type',
    updates: {
      amount: async (type, values, prevValues) => {
        if (!type || !values?.officeId) return 0;
        const settings = await getOfficeAssignSettings(values.officeId);
        if (type === 'Driver' && settings?.driverAmount != null && values?.distance != null) {
          return travelAmount(settings.driverAmount, values.distance, settings.driverExcludedDistance);
        }
        if (type === 'Passenger' && settings?.passengerAmount != null && values?.distance != null) {
          return travelAmount(settings.passengerAmount, values.distance, settings.passengerExcludedDistance);
        }
        if (type === 'Breakfast' && settings?.breakfastAmount != null) {
          return settings.breakfastAmount
        }
        if (type === 'Lunch' && settings?.lunchAmount != null) {
          return settings.lunchAmount
        }
        if (type === 'Dinner' && settings?.dinnerAmount != null) {
          return settings.dinnerAmount
        }
        return 0;
      }
    }
  }, {
    field: 'addressId',
    updates: {
      distance: async (addressId, values, prevValues) => {
        if (isEmpty(prevValues) || values.addressId == null) return values.distance
        return await getDistances(values.gameId, values.addressId, values.roundtrip)
      },
    } }, {
    field: 'roundtrip',
    updates: {
      distance: async (roundtrip, values, prevValues) => {
        if (isEmpty(prevValues)) return values.distance
        if (values.roundtrip) return values.distance * 2
        return values.distance / 2
      },
    } }, {
    field: 'distance',
    updates: {
      amount: async (distance, values, prevValues) => {
        if (!['Driver', 'Passenger'].includes(values.type)) return values.amount;

        if (distance == null || !values?.officeId) return 0;

        const settings = await getOfficeAssignSettings(values.officeId);
        if (values?.type === 'Driver' && settings?.driverAmount != null && values?.distance != null) {
          return travelAmount(settings.driverAmount, values.distance, settings.driverExcludedDistance);
        }
        if (values?.type === 'Passenger' && settings?.passengerAmount != null && values?.distance != null) {
          return travelAmount(settings.passengerAmount, values.distance, settings.passengerExcludedDistance)
        }
        return 0;
      }
    }
  })])

  return <SimpleForm toolbar={<Toolbar />} validate={validate} initialValues={{ status: 'Pending', ...initialValues }} decorators={decorators.current} {...props}>
    <FormContent {...props} />
  </SimpleForm>
}
