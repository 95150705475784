import React, { forwardRef } from 'react';
import { TextField, useLogout, useTranslate } from 'react-admin';
import { Button, Table, TableBody } from '@material-ui/core'
import { PowerSettingsNew } from '@material-ui/icons';

import InfoCard from "../../../common/cards/InfoCard"
import Row from '../../../common/cards/TableRow';

import ChangePasswordModal from '../dialogs/ChangePasswordModal';

export const LogoutButton = forwardRef((props, ref) => {
  const translate = useTranslate();
  const logout = useLogout();

  return <Button color="primary" startIcon={<PowerSettingsNew />} onClick={() => logout()} ref={ref}>
    {translate('ra.auth.logout')}
  </Button>
});

const inputProps = {
  resource: 'accounts',
  basePath: '/accounts',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export default props =>
  <InfoCard title="resources.accounts.labels.my" actions={[
    <ChangePasswordModal />,
    <LogoutButton />
  ]}>
    <Table>
      <TableBody>
        <Row label="ra.auth.username">
          <TextField source="username" {...inputProps} />
        </Row>
      </TableBody>
    </Table>
  </InfoCard>
