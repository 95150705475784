import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom'
import { Layout, useLocale, useTranslate } from 'react-admin'
import { IconButton, makeStyles, Button } from '@material-ui/core';
import { Close, History, Launch } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import { translateApiProperty } from '@hisports/common';
import { FF_VERSION_MISMATCH } from '@hisports/common/featureFlags';

import Volunteer from '../routes/Volunteer';
import Unauthorized from '../routes/Unauthorized';
import GameContext from '../resources/gameStatus/GameContext';

import Sidebar from './Sidebar';
import AppBar from './AppBar';
import Menu from './Menu';
import Error from './Error';

import { useSeason } from '../common/inputs/SeasonSelector';
import { useDefaultSeason, useLoggedIn, useNotices, usePermissions, useVolunteer, apiClient, useFlag, useSport } from '../http';
import usePreferences from '../common/usePreferences';
import { getSeasonDisplayName } from '@hisports/common/src/seasons';

const useStyles = makeStyles(theme => ({
  appFrame: {
    marginTop: theme.spacing(8),
    flex: 1,
  },
  content: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 2),
    },
  },
  alerts: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  }
}))

const useAlertStyles = makeStyles(theme => ({
  message: {
    whiteSpace: 'pre-line',
  }
}))

const getVersion = async () => {
  return await apiClient(`/apimetadatas/getVersion`)
    .then(res => res?.data);
}

const RefreshButton = () => {
  const translate = useTranslate();
  return <Button color="primary" onClick={() => window.location.reload()} variant="outlined">{translate('ra.action.refresh')}</Button>
}

const NoticeAlert = ({ notice, onHide }) => {
  const locale = useLocale();
  const classes = useAlertStyles();

  const title = translateApiProperty(notice, 'title', locale);
  const message = translateApiProperty(notice, 'message', locale);
  const showLink = translateApiProperty(notice, 'link', locale);
  const { severity, dismissable = true } = notice;

  const actions = [
    showLink && <IconButton color="inherit" size="large" href={showLink} target="_blank">
      <Launch fontSize="inherit" />
    </IconButton>,
    dismissable && <IconButton color="inherit" size="small" onClick={() => onHide(notice)}>
      <Close fontSize="inherit" />
    </IconButton>
  ]

  return <Alert severity={severity || 'info'} action={actions}>
    {title && <AlertTitle>{title}</AlertTitle>}
    <span className={classes.message}>{message}</span>
  </Alert>
}

export default ({ children, ...props }) => {
  const translate = useTranslate();
  const seasonId = useSeason();
  const sport = useSport();
  const defaultSeason = useDefaultSeason();
  const isLoggedIn = useLoggedIn();
  const permissions = usePermissions();
  const isVolunteer = useVolunteer();
  const classes = useStyles();
  const [ apiVersion, setApiVersion ] = useState();
  const location = useLocation();
  const isEnabled = useFlag();

  useEffect(() => {
    if (!isLoggedIn) return
    getVersion()
      .then(version => setApiVersion(version))
  }, [location, isLoggedIn]);

  const appVersion = process.env.RELEASE_VERSION;
  const versionMismatch = apiVersion ? appVersion !== apiVersion : false


  const notices = useNotices();
  const [ hiddenNoticeIds, setHiddenNoticeIds, hiddenLoaded ] = usePreferences('hiddenNotices', []);
  const visibleNotices = notices.filter(notice => hiddenLoaded && (!notice.dismissable || !hiddenNoticeIds.includes(notice.id)));

  const hideNotice = notice => {
    const currentNoticeIds = notices.map(notice => notice.id);
    setHiddenNoticeIds(prevNoticeIds => [
      ...prevNoticeIds.filter(noticeId => currentNoticeIds.includes(noticeId)),
      notice.id,
    ])
  }

  if (!isLoggedIn) return <Redirect to="/login" />
  if (!permissions.filter(p => p.roleName !== 'participant').length) return <Unauthorized />
  if (isVolunteer) return <Volunteer />

  const isPastSeason = seasonId !== defaultSeason;
  const season = getSeasonDisplayName(seasonId, sport);
  return <Layout
    {...props}
    classes={classes}
    appBar={AppBar}
    menu={Menu}
    sidebar={Sidebar}
    error={Error}
  >
    <div className={classes.alerts}>
      {}
      {versionMismatch && isEnabled(FF_VERSION_MISMATCH) && <Alert severity="error" action={<RefreshButton />} icon={<History />}>
        {translate('ra.message.version_mismatch')}
      </Alert>}
      {visibleNotices.map(notice => <NoticeAlert notice={notice} onHide={hideNotice} />)}
      {isPastSeason && <Alert severity="info" icon={<History />}>
        {translate('resources.seasons.alerts.viewing_inactive_season', { season })}
      </Alert>}
    </div>
    <GameContext>
      {children}
    </GameContext>
  </Layout>
}
