import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import { getPenalties } from '@hisports/scoresheet/src/selectors';
import { displayTime, getPenalty } from '@hisports/scoresheet/src/util';

import { useSport } from '../../../http';
import { useLocale } from '../../../locale';
import FunctionField from '../../../common/fields/FunctionField';
import UserTextField from '../../../common/fields/UserTextField';
import { CreatedAtField } from '../../../common/fields/CreatedAtField';
import { UpdatedAtField } from '../../../common/fields/UpdatedAtField';
import { ParticipantField } from '../../participants/ParticipantField';

import { IncidentReportDatagrid } from './IncidentReportModal';
import { useMeta, useScoresheet } from '../ScoresheetContext';

const renderInjury = (incident, translate) => {
  if (!incident) return
  if (!incident.injured) return translate("resources.gameIncidents.labels.notInjured")

  const details = [];
  if (incident.injured) details.push(translate("resources.gameIncidents.fields.injured"))
  if (incident.returnedToGame) details.push(translate("resources.gameIncidents.labels.returned"))

  return details.join(', ')
}

export const PenaltyField = ({ className, variant="body2", ...props }) => {
  const sport = useSport();
  const translate = useTranslate();
  const [ locale ] = useLocale();
  const { meta, game } = useMeta();
  const record = useRecordContext(props);
  const penalties = useScoresheet(scoresheet => getPenalties(scoresheet, meta.infractions, meta.types, sport, game.seasonId))
  const penalty = penalties.find(penalty => penalty?.id === record?.penaltyId);

  return <FunctionField className={className} render={() => {
    if (!penalty) return translate('ra.message.none')
    return `${displayTime(penalty?.gameTime, sport)} - ${getPenalty(penalty, meta.infractions, meta.rules, meta.types, penalties, {
      start: translate('resources.scoresheets.labels.start'),
      ended: translate('resources.scoresheets.labels.ended'),
    }, sport, game.seasonId, locale)}`;
  }} variant={variant} />
}

const InjuryField = ({ className, variant="body2", ...props }) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  return <FunctionField className={className} render={() => renderInjury(record, translate)} variant={variant} />
}

export const IncidentReportGrid = props =>
  <IncidentReportDatagrid size="medium" {...props}>
    <ParticipantField source="participantId" label="resources.gameIncidents.labels.participantId" includeId="full" />
    <PenaltyField source="penaltyId" label="resources.gameIncidents.fields.penaltyId" />
    <UserTextField source="description" label="resources.gameIncidents.fields.description" />
    <InjuryField source="id" label="resources.gameIncidents.labels.injury" />
    <CreatedAtField source="createdAt" />
    <UpdatedAtField source="updatedAt" />
  </IncidentReportDatagrid>
