import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { FormatListNumbered } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import ScheduleSequencesGrid from '../../schedulesequences/ScheduleSequencesGrid';
import { AddScheduleSequenceButton } from '../../schedulesequences/ScheduleSequenceModal';

export default props => {
  const translate = useTranslate();
  const schedule = useRecordContext(props);

  const canAddSequence = isAuthorized(schedule, 'schedulesequences', 'create')
  const addButton = canAddSequence && <AddScheduleSequenceButton size="small" initialValues={{ scheduleId: schedule?.id }} />

  return <ListCard
    title={translate('resources.schedulesequences.name', 2)}
    icon={<FormatListNumbered />}
    basePath="/schedulesequences"
    resource="schedulesequences"
    filter={{ 'scheduleId': schedule?.id }}
    sort={{ field: ['start'], order: ['ASC'] }}
    addButton={addButton}
    {...props}
  >
    <ScheduleSequencesGrid />
  </ListCard>
}
