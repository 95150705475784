
import { isEmpty } from '@hisports/parsers';

import { YELLOW, RED, YELLOW_RED, PENALTY_SEVERITY, TEAM_FLAGS, FLAG_COLORS, GOALIE, GENERAL_FLAGS, FAULTY_PLAYER_REASONS, INFRACTION_SEVERITY } from '../constants'
import { isNotSuspended, isPlayer, isStaff, isSuspended } from './members'
import { getLineupCounts, getPenalties } from '../selectors';
import { getSeverity } from './penalties';

export const getGeneralFlagNames = () => [
  GENERAL_FLAGS.COACH_ENTERED_RESULT,
  GENERAL_FLAGS.UNFINISHED,
  GENERAL_FLAGS.NOTES,
].filter(Boolean);

export const getTeamFlagNames = sport => [
  TEAM_FLAGS.LINEUP_MAX_LIMIT,
  TEAM_FLAGS.LINEUP_MIN_LIMIT,
  TEAM_FLAGS.EXTRA,
  TEAM_FLAGS.SERVING,
  TEAM_FLAGS.SECONDARY_TEAM,
  TEAM_FLAGS.NOT_ROSTERED,
  TEAM_FLAGS.NOT_SERVING,
  TEAM_FLAGS.TRANSFERRED,
  sport === 'Hockey' && TEAM_FLAGS.LOW_SEVERITY_PENALTIES,
  sport !== 'Soccer' && TEAM_FLAGS.HIGH_SEVERITY_PENALTIES,
  sport === 'Soccer' && TEAM_FLAGS.YELLOW_PENALTIES,
  sport === 'Soccer' && TEAM_FLAGS.INDIRECT_RED_PENALTIES,
  sport === 'Soccer' && TEAM_FLAGS.DIRECT_RED_PENALTIES,
  sport === 'Soccer' && TEAM_FLAGS.UNVALIDATED_MEMBERS,
  sport === 'Baseball' && TEAM_FLAGS.FAULTY_PLAYERS
].filter(Boolean)

export const isGeneralFlag = (flag, sport) => getGeneralFlagNames(sport).includes(flag.name);
export const isTeamFlag = (flag, sport) => getTeamFlagNames(sport).includes(flag.name) && !!flag.teamId;

const createFlag = (name, teamId) => ({
  name,
  teamId
});

export const getFlagData = (flag = {}, scoresheet = {}, meta, memberValidations) => {
  if (!meta || !memberValidations || !flag.teamId) return [];

  const { infractions, types, seasonId, sport } = meta || {};
  const members = scoresheet.lineups[flag.teamId].members || [];
  const extras = scoresheet.lineups[flag.teamId].extras || [];
  const penalties = getPenalties(scoresheet, infractions, types, sport, seasonId);
  const scoresheetThrowsInnings = scoresheet.throwsInnings || [];

  let flagData = [];
  switch (flag.name) {
    case TEAM_FLAGS.EXTRA:
      flagData = extras
      break;
    case TEAM_FLAGS.SERVING:
      flagData = members.filter(isSuspended)
      break;
    case TEAM_FLAGS.NOT_SERVING: {
      const membersWithSuspensions = memberValidations.filter(memberValidation => !!memberValidation.suspensions.length)
      flagData = members.filter(member => membersWithSuspensions.some(memberWithSuspensions => isNotSuspended(member) && member.participantId === memberWithSuspensions.participantId))
      break;
    }
    case TEAM_FLAGS.SECONDARY_TEAM: {
      const membersWithSecondaryTeam = memberValidations.filter(validation => validation.isSecondaryTeam)
      flagData = membersWithSecondaryTeam.map(memberWithSecondaryTeam => ({
        ...members.find(member => member.participantId === memberWithSecondaryTeam.participantId),
        ...memberWithSecondaryTeam
      }))
      break;
    }
    case TEAM_FLAGS.LOW_SEVERITY_PENALTIES:
    case TEAM_FLAGS.HIGH_SEVERITY_PENALTIES: {
      const FLAG_TO_SEVERITY_MAP = {
        [TEAM_FLAGS.LOW_SEVERITY_PENALTIES]: [PENALTY_SEVERITY.LOW, INFRACTION_SEVERITY.LOW],
        [TEAM_FLAGS.HIGH_SEVERITY_PENALTIES]: [PENALTY_SEVERITY.HIGH, INFRACTION_SEVERITY.HIGH],
      }

      const flaggedPenalties = penalties.filter(penalty => FLAG_TO_SEVERITY_MAP[flag.name].includes(getSeverity(penalty, infractions, types, seasonId)));
      flagData = flaggedPenalties.map(flaggedPenalty => {
        const member = members.find(member => member.participantId === flaggedPenalty.participantId)
        if (!member) return
        return { ...member, penalty: flaggedPenalty }
      }).filter(Boolean)
      break;
    }
    case TEAM_FLAGS.YELLOW_PENALTIES:
    case TEAM_FLAGS.INDIRECT_RED_PENALTIES:
    case TEAM_FLAGS.DIRECT_RED_PENALTIES: {
      const FLAG_TO_DURATION_MAP = {
        [TEAM_FLAGS.YELLOW_PENALTIES]: YELLOW,
        [TEAM_FLAGS.INDIRECT_RED_PENALTIES]: YELLOW_RED,
        [TEAM_FLAGS.DIRECT_RED_PENALTIES]: RED
      }

      const flaggedPenalties = penalties.filter(penalty => penalty.duration === FLAG_TO_DURATION_MAP[flag.name])
      flagData = flaggedPenalties.map(flaggedPenalty => {
        const member = members.find(member => member.participantId === flaggedPenalty.participantId)
        if (!member) return
        return { ...member, penalty: flaggedPenalty }
      }).filter(Boolean)
      break;
    }
    case TEAM_FLAGS.FAULTY_PLAYERS:
      flagData = memberValidations.map(validation => {
        const status = validation.throwsInningsStatus
        if (!status) return;
        const memberThrowsInnings = scoresheetThrowsInnings.find(throwsInning => throwsInning.participantId == validation.participantId)
        if (!memberThrowsInnings) return;

        if (status.hasInningsRest || status.hasThrowsRest || status.consecutiveDays >= 2 ||
          (status.dailyInningsRemaining !== null && memberThrowsInnings.inningsPitched > status.dailyInningsRemaining) ||
          (status.weeklyInningsRemaining !== null && memberThrowsInnings.inningsPitched > status.weeklyInningsRemaining)) {
          const member = members.find(member => member.participantId === validation.participantId)
          return { ...member, ...validation, throwsInnings: memberThrowsInnings }
        }
      }).filter(Boolean)
      break;
    default:
      break;
  }

  return flagData;
}

const getLineupLimitFlags = (scoresheet, meta = {}, homeLineup, awayLineup) => {
  if (!homeLineup || !awayLineup || !meta.policies || !meta.policies.lineupLimits) return [];

  const flags = [];
  const { sport, policies } = meta;

  const limits = policies.lineupLimits;

  const checkAndAddLimitFLag = (limitPositionCount, positionCount, teamId) => {
    if (positionCount == null || limitPositionCount == null || limitPositionCount.min == null || limitPositionCount.max == null) return

    if (positionCount < limitPositionCount.min) {
      flags.push(createFlag(TEAM_FLAGS.LINEUP_MIN_LIMIT, teamId))
    }
    if (positionCount > limitPositionCount.max) {
      flags.push(createFlag(TEAM_FLAGS.LINEUP_MAX_LIMIT, teamId))
    }
  }

  [homeLineup, awayLineup].forEach(teamLineup => {
    const { teamId } = teamLineup
    const teamCount = getLineupCounts(scoresheet, { teamId, sport })

    checkAndAddLimitFLag(limits.players, teamCount.totalPlayer, teamId)
    checkAndAddLimitFLag(limits.skaters, teamCount.totalSkater, teamId)
    checkAndAddLimitFLag(limits.goalies, teamCount[GOALIE], teamId)
    checkAndAddLimitFLag(limits.staff, teamCount.totalStaff, teamId)
  })

  return flags
}

const getNoteFlag = (scoresheet) => {
  if (!scoresheet || (!scoresheet.notes && !scoresheet.adminNotes)) return;

  return createFlag(GENERAL_FLAGS.NOTES)
}

const getExtraFlags = (homeLineup, awayLineup) => {
  const flags = [];

  if (homeLineup.extras && homeLineup.extras.length) {
    flags.push(createFlag(TEAM_FLAGS.EXTRA, homeLineup.teamId))
  }
  if (awayLineup.extras && awayLineup.extras.length) {
    flags.push(createFlag(TEAM_FLAGS.EXTRA, awayLineup.teamId))
  }

  return flags
}

const getServingFlags = (homeLineup, awayLineup) => {
  const homeServingPlayers = homeLineup.members.filter(isSuspended).length;
  const awayServingPlayers = awayLineup.members.filter(isSuspended).length;
  const flags = [];

  if (homeServingPlayers) {
    flags.push(createFlag(TEAM_FLAGS.SERVING, homeLineup.teamId));
  }
  if (awayServingPlayers) {
    flags.push(createFlag(TEAM_FLAGS.SERVING, awayLineup.teamId));
  }

  return flags;
}

const getPenaltyFlags = (scoresheet, meta, homeLineup, awayLineup) => {
  const { penalties = [] } = scoresheet;
  const { sport, infractions, types, seasonId } = meta || {};

  const flags = [];

  [homeLineup.teamId, awayLineup.teamId].forEach(teamId => {
    // severity based penalty flags
    if (sport !== 'Soccer') {
      const hasLowSeverityPenalty = penalties.some(
        penalty => [PENALTY_SEVERITY.LOW, INFRACTION_SEVERITY.LOW].includes(getSeverity(penalty, infractions, types, seasonId)) && penalty.teamId === teamId
      );
      const hasHighSeverityPenalty = penalties.some(
        penalty => [PENALTY_SEVERITY.HIGH, INFRACTION_SEVERITY.HIGH].includes(getSeverity(penalty, infractions, types, seasonId)) && penalty.teamId === teamId
      );

      if (hasLowSeverityPenalty) {
        flags.push(createFlag(TEAM_FLAGS.LOW_SEVERITY_PENALTIES, teamId));
      }
      if (hasHighSeverityPenalty) {
        flags.push(createFlag(TEAM_FLAGS.HIGH_SEVERITY_PENALTIES, teamId));
      }
    }

    // soccer based penalty flags
    if (sport === 'Soccer') {
      const hasYellowPenalty = penalties.some(
        penalty => penalty.duration === YELLOW && penalty.teamId === teamId
      );
      const hasRedPenalty = penalties.some(
        penalty => penalty.duration === RED && penalty.teamId === teamId
      );
      const hasYellowRedPenalty = penalties.some(
        penalty => penalty.duration === YELLOW_RED && penalty.teamId === teamId
      );

      if (hasYellowPenalty) {
        flags.push(createFlag(TEAM_FLAGS.YELLOW_PENALTIES, teamId));
      }
      if (hasRedPenalty) {
        flags.push(createFlag(TEAM_FLAGS.DIRECT_RED_PENALTIES, teamId));
      }
      if (hasYellowRedPenalty) {
        flags.push(createFlag(TEAM_FLAGS.INDIRECT_RED_PENALTIES, teamId));
      }
    }
  });

  return flags;
}

export const getPenaltyFlagName = (scoresheet, eventId, meta) => {
  const penalty = scoresheet.penalties.find(penalty => penalty.id === eventId);
  const { infractions, types, seasonId } = meta || {};

  const severity = getSeverity(penalty, infractions, types, seasonId);

  if ([PENALTY_SEVERITY.LOW, INFRACTION_SEVERITY.LOW].includes(severity)) {
    return TEAM_FLAGS.LOW_SEVERITY_PENALTIES;
  } else if ([PENALTY_SEVERITY.HIGH, INFRACTION_SEVERITY.HIGH].includes(severity)) {
    return TEAM_FLAGS.HIGH_SEVERITY_PENALTIES;
  }

  switch (penalty.duration) {
    case YELLOW:
      return TEAM_FLAGS.YELLOW_PENALTIES
    case RED:
      return TEAM_FLAGS.DIRECT_RED_PENALTIES
    case YELLOW_RED:
      return TEAM_FLAGS.INDIRECT_RED_PENALTIES
    default:
      break;
  }

  return null;
}

const getNotServingFlags = (homeLineup, awayLineup) => {
  const flags = [];

  const checkSuspensions = (lineup) => lineup.members.some(member => member.suspensions && member.suspensions.length && isNotSuspended(member));

  if (checkSuspensions(homeLineup)) {
    flags.push(createFlag(TEAM_FLAGS.NOT_SERVING, homeLineup.teamId));
  }
  if (checkSuspensions(awayLineup)) {
    flags.push(createFlag(TEAM_FLAGS.NOT_SERVING, awayLineup.teamId));
  }

  return flags;
}

const getSecondaryTeamFlags = (homeLineup, awayLineup) => {
  const flags = []

  const hasSecondaryTeamMember = lineup => lineup.members.some(member => member.isSecondaryTeam === true)

  if (hasSecondaryTeamMember(homeLineup)) {
    flags.push(createFlag(TEAM_FLAGS.SECONDARY_TEAM, homeLineup.teamId))
  }
  if (hasSecondaryTeamMember(awayLineup)) {
    flags.push(createFlag(TEAM_FLAGS.SECONDARY_TEAM, awayLineup.teamId))
  }

  return flags
}

const getUnvalidatedMemberFlags = (homeLineup, awayLineup) => {
  const flags = []

  const hasUnvalidatedMember = lineup => lineup.members.some(member => {
    return ((member.invalidMemberTypes || []).includes('Player') && isPlayer(member)) ||
      ((member.invalidMemberTypes || []).includes('Staff') && isStaff(member))
  })

  if (hasUnvalidatedMember(homeLineup)) {
    flags.push(createFlag(TEAM_FLAGS.UNVALIDATED_MEMBERS, homeLineup.teamId))
  }
  if (hasUnvalidatedMember(awayLineup)) {
    flags.push(createFlag(TEAM_FLAGS.UNVALIDATED_MEMBERS, awayLineup.teamId))
  }

  return flags
}

export const getFaultyPlayerReason = (throwsInningsStatus, memberThrowsInnings) => {
  if (isEmpty(memberThrowsInnings) || !throwsInningsStatus) return null;

  if (throwsInningsStatus.hasInningsRest) {
    return FAULTY_PLAYER_REASONS.HAS_INNINGS_REST
  } else if (throwsInningsStatus.hasThrowsRest) {
    return FAULTY_PLAYER_REASONS.HAS_THROWS_REST
  } else if (throwsInningsStatus.consecutiveDays >= 2) {
    return FAULTY_PLAYER_REASONS.CONSECUTIVE_DAYS
  } else if (throwsInningsStatus.dailyInningsRemaining !== null && memberThrowsInnings.inningsPitched > throwsInningsStatus.dailyInningsRemaining) {
    return FAULTY_PLAYER_REASONS.DAILY_INNINGS
  } else if (throwsInningsStatus.weeklyInningsRemaining !== null && memberThrowsInnings.inningsPitched > throwsInningsStatus.weeklyInningsRemaining) {
    return FAULTY_PLAYER_REASONS.WEEKLY_INNINGS
  } else {
    return null
  }
}

const getFaultyPlayerFlags = (scoresheet, meta, homeLineup, awayLineup) => {
  const flags = [];
  if (meta.sport !== 'Baseball') return flags;

  const isFaulty = (member, throwsInnings) => {
    const memberThrowsInnings = throwsInnings.find(throwsInning => member.participantId === throwsInning.participantId);
    const faultyReason = getFaultyPlayerReason(member.throwsInningsStatus, memberThrowsInnings);
    return !!faultyReason;
  };

  const homeFaulty = homeLineup.members.filter(member => isFaulty(member, scoresheet.throwsInnings));
  const awayFaulty = awayLineup.members.filter(member => isFaulty(member, scoresheet.throwsInnings));

  if (homeFaulty.length) {
    flags.push(createFlag(TEAM_FLAGS.FAULTY_PLAYERS, homeLineup.teamId));
  }
  if (awayFaulty.length) {
    flags.push(createFlag(TEAM_FLAGS.FAULTY_PLAYERS, awayLineup.teamId));
  }

  return flags;
};

export const getFlagColor = (flagName) => {
  switch (flagName) {
    case TEAM_FLAGS.LOW_SEVERITY_PENALTIES:
    case TEAM_FLAGS.YELLOW_PENALTIES:
    case TEAM_FLAGS.EXTRA:
      return FLAG_COLORS.ORANGE;

    case TEAM_FLAGS.HIGH_SEVERITY_PENALTIES:
    case TEAM_FLAGS.DIRECT_RED_PENALTIES:
    case TEAM_FLAGS.INDIRECT_RED_PENALTIES:
    case TEAM_FLAGS.NOT_SERVING:
    case TEAM_FLAGS.SERVING:
    case TEAM_FLAGS.FAULTY_PLAYERS:
    case TEAM_FLAGS.MISSING_SUSPENDED_MEMBER:
    case TEAM_FLAGS.UNVALIDATED_MEMBERS:
      return FLAG_COLORS.RED;

    case TEAM_FLAGS.TRANSFERRED:
    case TEAM_FLAGS.SECONDARY_TEAM:
      return FLAG_COLORS.DEFAULT;
    default:
      break
  }
}

export const generateGameFlags = (game, scoresheet = {}, meta) => {
  const flags = [];

  const lineups = scoresheet && scoresheet.lineups || {};

  if (!lineups) return flags;

  const notesFlag = getNoteFlag(scoresheet);
  if (notesFlag) {
    flags.push(notesFlag);
  }

  if (!lineups[game.homeTeamId] || !lineups[game.awayTeamId]) return flags;

  const [homeLineup, awayLineup] = [lineups[game.homeTeamId], lineups[game.awayTeamId]].map(lineup => {
    const { validations = [] } = meta.teams[lineup.teamId] || {}
    const appendValidation = member => ({
      ...member,
      ...(validations.find(({ participantId }) => participantId === member.participantId))
    })

    return {
      ...lineup,
      members: (lineup.members || []).map(appendValidation),
      extras: (lineup.extras || []).map(appendValidation)
    }
  })

  const lineupLimitFlags = getLineupLimitFlags(scoresheet, meta, homeLineup, awayLineup);
  const extraFlags = getExtraFlags(homeLineup, awayLineup);
  const servingFlags = getServingFlags(homeLineup, awayLineup);
  const penaltyFlags = getPenaltyFlags(scoresheet, meta, homeLineup, awayLineup);
  const faultyPlayerFlags = getFaultyPlayerFlags(scoresheet, meta, homeLineup, awayLineup);
  const notServingFlags = getNotServingFlags(homeLineup, awayLineup);
  const secondaryTeamFlags = getSecondaryTeamFlags(homeLineup, awayLineup);
  const unvalidatedMemberFlags = getUnvalidatedMemberFlags(homeLineup, awayLineup);

  return [
    ...flags,
    ...lineupLimitFlags,
    ...extraFlags,
    ...servingFlags,
    ...penaltyFlags,
    ...faultyPlayerFlags,
    ...notServingFlags,
    ...secondaryTeamFlags,
    ...unvalidatedMemberFlags,
  ]
}
