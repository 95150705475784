import React, { useRef, useState } from 'react';
import { TextInput, SimpleForm, useTranslate, useRecordContext, NumberInput } from 'react-admin';
import moment from 'moment-timezone';
import { Button, Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { changeTimezone, isEmpty } from '@hisports/parsers';
import { isSameTime } from '@hisports/common';
import { FF_CROSS_SCHEDULING } from '@hisports/common/featureFlags';
import { validateGameNumber } from '@hisports/scheduler';
import { getGameNumberValidationError } from '@hisports/scheduler/src/sequences/sequences';

import { useSchedulingContext } from '../../common/calendar/SchedulingContext';
import { InlineDateTimeInput, dateTimePlaceholder } from '../../common/inputs/DateInput';
import { GameStatusEnumInput, TimezoneEnumInput } from '../../common/inputs/EnumInputs';
import { EndTimeInput as InlineEndTimeInput } from '../../common/inputs/EndTimeInput';
import { FieldDependency } from '../../common/FieldDependency';
import Toolbar from '../../common/ra/Toolbar';
import { apiClient, useFlag } from '../../http';

import { useIsHomeFirst } from '../events/EventViewSettings';
import { ScheduleInput } from '../schedules/ScheduleInput';
import { TeamInput } from '../teams/TeamInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { ScheduleGroupInput } from '../groups/GroupInput';
import { CategoryInput } from '../categories/CategoryInput';

import { GameAvailabilityInfo, isTournamentConflict, useAvailabilities } from './GameAvailabilityInfo';
import { GameAvailabilityContextProvider, useGameAvailabilityContext } from './GameAvailabilityContext';

const useStyles = makeStyles(theme => ({
  crossSchedulingCard: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 6
  },
  crossSchedulingCardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    }
  }
}))

const validate = (values, schedules, slots = []) => {
  const errors = {};
  const schedule = schedules?.[values?.scheduleId]

  if (!values.scheduleId) errors.scheduleId = 'ra.validation.required'
  if (values.id && !values.categoryId) errors.categoryId = 'ra.validation.required'

  if (!values.arenaId) errors.arenaId = 'ra.validation.required'

  if (!values.homeTeamId && values.isApproved) errors.homeTeamId = 'ra.validation.required'
  if (!values.awayTeamId && values.isApproved) errors.awayTeamId = 'ra.validation.required'

  const { startTime, endTime, timezone } = values;

  if (!startTime) errors.startTime = 'ra.validation.required'
  if (!endTime) errors.endTime = 'ra.validation.required'
  if (startTime === endTime) errors.endTime = 'ra.validation.end_before_start'
  if (!timezone) errors.timezone = 'ra.validation.required'

  if (startTime && endTime) {
    const selectedSlot = slots.find(slot => isSameTime(slot, { startTime, endTime, timezone }));
    const overlappingSlot = slots.some(slot => slot.isOverlap);

    if (selectedSlot?.restrictions?.length) {
      errors.startTime = 'resources.games.validations.unavailable_slot';
      errors.endTime = 'resources.games.validations.unavailable_slot';
      errors.timeslot = 'resources.games.validations.unavailable_slot';
    } else if (overlappingSlot) {
      errors.startTime = 'resources.games.validations.overlapping_slot';
      errors.endTime = 'resources.games.validations.overlapping_slot';
      errors.timeslot = 'resources.games.validations.overlapping_slot';
    } else if (!moment.tz(startTime, timezone).isValid()) {
      errors.startTime = 'resources.games.validations.invalid_time'
    } else if (!moment.tz(endTime, timezone).isValid()) {
      errors.endTime = 'resources.games.validations.invalid_time'
    } else if (moment.tz(endTime, timezone).isBefore(startTime, 'minute')) {
      errors.endTime = 'resources.games.validations.end_before_start'
    } else if (moment.tz(endTime, timezone).diff(startTime, 'hours') > 6) {
      errors.endTime = 'resources.games.validations.invalid_time_check_am_pm'
    }
  }

  if (values.date && schedule?.startDate && moment.utc(schedule.startDate).isAfter(values.date)) {
    errors.startTime = 'resources.games.validations.before_schedule_date'
  } else if (values.date && schedule?.endDate && moment.utc(schedule.endDate).add(1, 'day').isBefore(values.date)) {
    errors.startTime = 'resources.games.validations.after_schedule_date'
  }

  if (!values.status) errors.status = 'ra.validation.required'
  if (!validateGameNumber(String(values.number))) {
    const gameNumberError = getGameNumberValidationError(String(values.number));
    errors.number = `resources.schedulesequences.validations.${gameNumberError}`
  }

  return errors;
}

const inputProps = {
  resource: 'games',
  basePath: '/games',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const hasConflicts = (availabilities, status, enforcementLevels = []) => {
  if (['Conflict', 'Cancelled', 'Postponed'].includes(status)) return false;

  const unavailabilities = availabilities?.availabilities?.filter(availability => !availability.isAvailable) || []
  if (enforcementLevels.includes(availabilities?.settings?.availabilityEnforcement) && unavailabilities.length > 0) return true
  if (enforcementLevels.includes(availabilities?.settings?.conflictEnforcement) && availabilities.conflicts?.length > 0) return true;
  if (enforcementLevels.includes(availabilities?.settings?.constraintEnforcement) && availabilities.constraints?.length > 0) return true;
  return false;
}

export const validateStatus = (availabilities, enforcementLevels = ['Validation']) => status => {
  if (!status) return 'ra.validation.required';
  if (hasConflicts(availabilities, status, enforcementLevels)) return 'resources.games.validations.conflicts';
}

export const GameStatusInput = ({ skipStatusValidation, ...props }) => {
  const { values } = useFormState();
  const schedules = useSelector(store => store.admin.resources.schedules.data);
  const schedule = schedules[values.scheduleId];
  const availabilities = useAvailabilities(values);
  const isTournamentSchedule = schedule && schedule.type == 'Tournament';
  const tournamentConflicts = availabilities.conflicts.filter(conflict => isTournamentConflict(conflict));
  const warningConflict = isTournamentSchedule && isEmpty(tournamentConflicts);

  const helperText = hasConflicts(availabilities, values.status, ['Conflict']) ? 'resources.games.helpers.conflicts' : '';

  return <GameStatusEnumInput validate={!skipStatusValidation && !warningConflict && validateStatus(availabilities)} helperText={!skipStatusValidation && !warningConflict && helperText} {...props} />
}

export const CrossSchedulingInput = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { change, batch } = useForm();
  const { values = {} } = useFormState();
  const { crossScheduleId, crossGroupId, scheduleId } = values

  const [ showCrossSchedulingInput, setShowCrossSchedulingInput ] = useState(crossScheduleId || crossGroupId);
  const onClick = () => {
    if (showCrossSchedulingInput) {
      batch(() => {
        change('crossScheduleId', null)
        change('crossGroupId', null)
      })
    }
    setShowCrossSchedulingInput(showCrossSchedulingInput => !showCrossSchedulingInput)
  }
  const ExpandIcon = showCrossSchedulingInput ? ExpandLessIcon : ExpandMoreIcon

  return <Grid container spacing={1} fullWidth>
    {showCrossSchedulingInput && <Grid item xs={12}>
      <Card variant="outlined" className={classes.crossSchedulingCard}>
        <CardContent className={classes.crossSchedulingCardContent}>
          <ScheduleInput source="crossScheduleId" helperText="ra.message.optional" {...inputProps} />
          <ScheduleGroupInput
            source="crossGroupId"
            scheduleId={crossScheduleId || scheduleId}
            showNone={translate('ra.message.no_group')}
            {...inputProps}
          />
        </CardContent>
      </Card>
    </Grid>}
    <Grid item xs={12} sm={12}>
      <Button onClick={onClick} startIcon={<ExpandIcon />} color="primary" size="small">{translate(`resources.games.labels.cross_scheduling_${showCrossSchedulingInput ? 'remove' : 'add'}`)}</Button>
    </Grid>
  </Grid>
}

const TimezoneInput = ({ surfaces, ...props }) => {
  const { values } = useFormState();

  const surface = surfaces?.[values?.arenaId];
  const disabled = !['TBA', 'NDA'].includes(surface?.alias);

  return <TimezoneEnumInput disabled={disabled} {...props} />
}

const InnerGameForm = ({
  teamProps = {},
  timeOptions = {},
  hideSchedule = false,
  hideNumber = false,
  hideAvailabilities,
  skipStatusValidation = false,
  resetDate = true,
  initialValues = {},
  draft,
  ...props
}) => {
  const { limitedView } = useSchedulingContext();
  const translate = useTranslate();
  const game = useRecordContext(props);
  const isHomeFirst = useIsHomeFirst();
  const isEnabled = useFlag();
  const {
    availabilityInfo: [ gameAvailabilities ],
    slot: [ selectedSlot ],
    loading: [ isLoading ]
  } = useGameAvailabilityContext();

  // must use ref for roles to get current value, otherwise value in calculator is stale
  const schedules = useSelector(store => store.admin.resources.schedules.data);
  const schedulesRef = useRef({});
  schedulesRef.current = schedules;

  const surfaces = useSelector(store => store.admin.resources.surfaces.data);
  const surfacesRef = useRef({});
  surfacesRef.current = surfaces;

  const scheduleSettings = useSelector(store => store.admin.resources.scheduleSettings.data);
  const settingsRef = useRef({})
  settingsRef.current = scheduleSettings

  const getScheduleSettings = async scheduleId => {
    if (!settingsRef.current[scheduleId]) {
      settingsRef.current[scheduleId] = await apiClient(`/schedules/${scheduleId}/settings`, { method: 'GET' })
        .then(res => res.data)
        .then(data => {
          settingsRef.current[scheduleId] = data;
          return data;
        })
    }
    return settingsRef.current[scheduleId];
  }

  const selectSlotRef = useRef({});
  selectSlotRef.current = selectedSlot;

  // decorators must be wrapped in useRef otherwise SimpleForm prop will register duplicates
  // this bug seems to be triggered by use of hooks (useSelector)
  const decorators = useRef([createCalculator({
    field: 'scheduleId',
    updates: {
      categoryId: (scheduleId, values, prevValues) => {
        const schedule = schedulesRef.current[scheduleId];
        if (!schedule) return values.categoryId;
        return schedule.categoryId;
      },
      endTime: async (scheduleId, values, prevValues) => {
        const { startTime, endTime, timezone } = values;
        if (!startTime || !scheduleId || isEmpty(prevValues)) return endTime;
        const { gameLength } = await getScheduleSettings(scheduleId);
        if (!gameLength) return endTime;
        return moment.tz(startTime, timezone).add(gameLength, 'minutes').toISOString();
      }
    }
  }, {
    field: 'crossScheduleId',
    updates: {
      crossGroupId: (crossScheduleId, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.crossScheduleId !== crossScheduleId) return
        return values?.crossGroupId
      }
    }
  }, {
    field: 'startTime',
    updates: {
      date: (startTime, values) => {
        if (!resetDate && !startTime) return values.date;
        if (!startTime || !values.timezone) return startTime;
        return moment.tz(startTime, values.timezone).format('YYYY-MM-DD');
      },
      endTime: async (startTime, values, prevValues) => {
        const { scheduleId, endTime, timezone } = values;
        if (!startTime || !scheduleId || isEmpty(prevValues) || startTime === prevValues.startTime) return endTime;
        const { gameLength } = await getScheduleSettings(scheduleId);
        if (!gameLength || selectSlotRef.current) return endTime;
        return moment.tz(startTime, timezone).add(gameLength, 'minutes').toISOString();
      }
    }
  }, {
    field: 'arenaId',
    updates: {
      timezone: (arenaId, values, prevValues) => {
        const surface = surfacesRef.current[arenaId];
        if (['TBA', 'NDA'].includes(surface?.alias)) {
          return values?.timezone || prevValues?.timezone;
        }
        return surface?.timezone || values?.timezone;
      }
    }
  }, {
    field: 'timezone',
    updates: {
      startTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.startTime || isEmpty(prevValues) || timezone === prevTimezone) return values.startTime;
        return changeTimezone(values.startTime, prevTimezone, timezone)
      },
      endTime: (timezone, values, prevValues) => {
        const { timezone: prevTimezone } = prevValues;
        if (!prevTimezone || !timezone || !values.endTime || isEmpty(prevValues) || timezone === prevTimezone) return values.endTime;
        return changeTimezone(values.endTime, prevTimezone, timezone)
      },
    }
  })])

  return <SimpleForm toolbar={<Toolbar disabled={isLoading} />} decorators={decorators.current} validate={values => validate(values, schedulesRef.current, gameAvailabilities?.slots)} validateOnBlur initialValues={{ status: 'Active', ...initialValues }} {...props}>
    <Grid container spacing={2} fullWidth>
      {!hideSchedule && <Grid item xs={12}>
        <ScheduleInput source="scheduleId" autoFocus {...inputProps} />
        <ScheduleGroupInput source="groupId" showNone={translate('ra.message.no_group')} {...inputProps} />
      </Grid>}
      {isEnabled(FF_CROSS_SCHEDULING) &&<Grid item xs={12}>
        <CrossSchedulingInput />
      </Grid>}
      <FieldDependency fieldSource="scheduleId">
        {!hideSchedule && <Grid item xs={12}>
          <CategoryInput source="categoryId" disabled {...inputProps} />
        </Grid>}

        {draft?.type === 'Generated' && <Grid item xs={12}>
          <NumberInput disabled={limitedView} source="round" label={translate('resources.drafts.labels.round', 1)} helperText="" {...inputProps} />
        </Grid>}

        <Grid item xs={12} sm={6}>
          <TeamInput disabled={limitedView} source={isHomeFirst ? 'homeTeamId' : 'awayTeamId'} helperText={!game?.isApproved ? 'resources.games.helpers.team' : ''} {...teamProps} {...inputProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TeamInput disabled={limitedView} source={isHomeFirst ? 'awayTeamId' : 'homeTeamId'} helperText={!game?.isApproved ? 'resources.games.helpers.team' : ''} {...teamProps} {...inputProps} />
        </Grid>
      </FieldDependency>

      <Grid item xs={12} md={4}>
        <SurfaceInput source="arenaId" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <FieldDependency fieldSource="arenaId" disabled>
          <InlineDateTimeInput
            source="startTime"
            helperText={selectedSlot ? 'resources.games.helpers.using_slot' : ''}
            options={{
              label: translate('resources.games.fields.startTime'),
              format: 'YYYY-MM-DD HH:mm',
              placeholder: dateTimePlaceholder,
              ampm: false,
              openTo: limitedView ? 'hours' : undefined,
              ...timeOptions,
            }}
            {...inputProps}
          />
        </FieldDependency>
      </Grid>
      <Grid item xs={12} md={4}>
        <FieldDependency fieldSource="arenaId" disabled>
          <InlineEndTimeInput
            source="endTime"
            maxHours={6}
            helperText={selectedSlot ? 'resources.games.helpers.using_slot' : ''}
            disabled={selectedSlot}
            options={{
              label: translate('resources.games.fields.endTime'),
              format: 'YYYY-MM-DD HH:mm',
              placeholder: dateTimePlaceholder,
              ampm: false,
              openTo: limitedView ? 'hours' : undefined,
              ...timeOptions,
            }}
            {...inputProps}
          />
        </FieldDependency>
      </Grid>
      <GameAvailabilityInfo draftId={draft?.id} />
      <Grid item xs={12} sm={6} lg={4}>
        <GameStatusInput source="status" defaultValue="Active" skipStatusValidation={skipStatusValidation} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <TextInput source="number" disabled={hideNumber || limitedView} helperText={hideNumber ? 'resources.games.helpers.number' : ''} {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <TimezoneInput source="timezone" surfaces={surfaces} {...inputProps} />
      </Grid>
      <Grid item xs={12} lg={12}>
        <TextInput source="comments" multiline rows="3" helperText="resources.games.helpers.comments" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}

export const GameForm = props =>
  <GameAvailabilityContextProvider>
    <InnerGameForm {...props} />
  </GameAvailabilityContextProvider>
