import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, makeStyles, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { HelpOutline as HelpIcon, MenuBook, LiveHelp } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.contrastText,
  }
}))

const HelpButton = ({ className, onClick }) => {
  const classes = useStyles();

  return <IconButton
    onClick={onClick}
    aria-haspopup
    className={`${className} ${classes.button}`}
  >
    <HelpIcon fontSize="small" />
  </IconButton>
}

export const HelpMenu = ({ className }) => {
  const translate = useTranslate();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return <>
    <HelpButton onClick={handleMenu} className={className} />
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      dense
    >
      <MenuItem component="a" href={translate('common.urls.documentation')} target="_blank">
        <ListItemIcon>
          <MenuBook fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={translate('resources.help.labels.documentation')} />
      </MenuItem>
      <MenuItem component="a" href={translate('common.urls.help_center')} target="_blank">
        <ListItemIcon>
          <LiveHelp fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={translate('resources.help.labels.help_center')} />
      </MenuItem>
    </Menu>
  </>
}
