import React from 'react';
import { TextField, useListContext, useRecordContext, useTranslate } from 'react-admin';

import FunctionField from '../../common/fields/FunctionField'

import { ParticipantField } from '../participants/ParticipantField';
import { TargetField } from '../targets/TargetField';
import { TargetTypeField } from '../targets/TargetTypeField';
import { CategoriesField } from '../categories/CategoriesField';
import { GroupsField } from '../groups/GroupsField';

import { NotificationDatagrid } from './NotificationModal'

const DestinationField = ({ showContact, ...props }) => {
  const notification = useRecordContext(props);
  if (!notification) return null;
  if (!notification.participantId) return <TextField source="email" {...props} />
  return <ParticipantField {...props} source="participantId" />
}

const TypesField = props =>
  <FunctionField {...props} render={(notification, source, resource, translate) => {
    const types = [
      notification.scheduling && translate('resources.notifications.fields.scheduling'),
      notification.activities && translate('resources.notifications.fields.activities'),
      notification.assigning && translate('resources.notifications.fields.assigning'),
      notification.gameReport && translate('resources.notifications.fields.gameReport'),
      notification.majorPenalty && translate('resources.notifications.fields.majorPenalty'),
      notification.drafts && translate('resources.notifications.fields.drafts'),
    ].filter(Boolean)

    return [
      types.length ? types.join(', ') : translate('ra.input.autocomplete.none'),
      !Number.isInteger(notification.id) ? `(${translate('ra.message.default')})` : null,
    ].filter(Boolean).join(' ')
  }} />


const has = (ids, data, property) =>
  ids?.some(id => data?.[id]?.[property]?.length > 0)

export const NotificationGrid = ({ showTarget = false, showParticipant = true, ...props }) => {
  const { ids = [], data } = useListContext(props);
  const translate = useTranslate();

  const showCategories = has(ids, data, 'categoryIds')
  const showGroups = has(ids, data, 'groupIds')

  return <NotificationDatagrid size="medium">
    {showTarget && <TargetTypeField source="targetType" label="" />}
    {showTarget && <TargetField source="targetId" />}
    {showParticipant && <DestinationField source="participantId" />}
    <TypesField label={translate('resources.notifications.labels.types')} />
    {showCategories && <CategoriesField source="categoryIds" emptyText="ra.message.all" />}
    {showGroups && <GroupsField source="groupIds" emptyText="ra.message.all" showType />}
  </NotificationDatagrid>
}
