import { sortBy } from 'lodash';
import { REFEREE, LINESPERSON, LINESPERSON_TWO, LINESPERSON_THREE, LINESMAN, OFFICIAL, SUPERVISOR, SCOREKEEPER, TIMEKEEPER, ALL_OFFICIAL_POSITIONS } from '../constants';
import { isKeeper, isOfficial, isSupervisor } from './members';

export const convertOfficialPositions = positions => {
  if (!positions.length) return REFEREE;

  switch (positions[0]) {
    case LINESMAN:
    case LINESPERSON:
    case LINESPERSON_TWO:
    case LINESPERSON_THREE:
      return LINESPERSON;

    case OFFICIAL:
      return OFFICIAL;

    case SUPERVISOR:
      return SUPERVISOR;

    case SCOREKEEPER:
      return SCOREKEEPER;

    case TIMEKEEPER:
      return TIMEKEEPER;

    case REFEREE:
    default:
      return REFEREE;
  }
}


/**
 * Filters officials based on assignment position.
 *
 * @param {array} officials - The list of officials to filter.
 * @param {object} assignment - The assignment to filter by.
 * @return {array} The filtered list of officials.
 */
export const filterOfficialsByPositionType = (officials = [], assignment) => {
  return officials.filter(official => {
    // Show all official types if supervisor
    if (isSupervisor(assignment)) return true;

    // filter out non scorekeeper qualified officials when its a keeper assignment position
    if (isKeeper(assignment) && !official.types.includes('Scorekeeper')) return false;

    // filter out non official qualified officials when its an official assignment position
    if (isOfficial(assignment) && !official.types.includes('Official')) return false;

    return true;
  });
}

export const sortOfficials = officials => {
  const sortedOfficials = sortBy(officials, ['participant.lastName', 'participant.firstName'])
  sortedOfficials.sort((a, b) => {
    const aPosition = ALL_OFFICIAL_POSITIONS.findIndex(position => a.position === position);
    const bPosition = ALL_OFFICIAL_POSITIONS.findIndex(position => b.position === position);
    return aPosition - bPosition;
  })

  return sortedOfficials
}
