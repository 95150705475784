import {
  OFFICIAL_STATUS_ASSIGNED,
  OFFICIAL_STATUS_CONFIRMED,
  OFFICIAL_STATUS_DECLINED,
  OFFICIAL_STATUS_NO_SHOW,
  OFFICIAL_STATUS_REQUESTED
} from "@hisports/scoresheet/src/constants";

const isStatus = (assignment, status) => assignment && assignment.status === status

export const isRequested = assignment => isStatus(assignment, OFFICIAL_STATUS_REQUESTED)
export const isUnconfirmed = assignment => isStatus(assignment, OFFICIAL_STATUS_ASSIGNED)
export const isConfirmed = assignment => isStatus(assignment, OFFICIAL_STATUS_CONFIRMED)
export const isDeclined = assignment => isStatus(assignment, OFFICIAL_STATUS_DECLINED)
export const isNoShow = assignment => isStatus(assignment, OFFICIAL_STATUS_NO_SHOW)

export const isAssigned = assignment => isUnconfirmed(assignment) || isConfirmed(assignment)

export const isAdhoc = assignment =>
  assignment && assignment.participant && !assignment.participantId

export const isDelegated = assignment =>
  assignment && assignment.officeId != null

export const isDelegatedPay = assignment =>
  assignment && assignment.officeId != null && assignment.payOfficeId != null
