import React, { useRef } from "react";
import { SimpleForm, TextInput, useRecordContext } from "react-admin";
import { Grid } from "@material-ui/core";
import createCalculator from 'final-form-calculate';

import { getSummary } from "@hisports/scoresheet/src/selectors";

import { useSport } from "../../../http";
import SwitchInput from "../../../common/inputs/SwitchInput";
import { FieldDependency } from "../../../common/FieldDependency";

import { useMeta, useScoresheet } from "../ScoresheetContext";
import { PenaltyInput } from "../suspension/SuspensionForm";
import { ListItemSummaryDescription } from "../summary/SummaryItem";

const validate = values => {
  const errors = {};
  if (!values.penaltyId) errors.penaltyId = 'ra.validation.required';
  if (!values.description) errors.description = 'ra.validation.required';

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const IncidentReportForm = props => {
  const record = useRecordContext(props);
  const sport = useSport();
  const { meta, game } = useMeta();
  const events = useScoresheet(scoresheet => getSummary(scoresheet, meta?.infractions, meta?.types, sport, game.seasonId));
  const event = props?.event || events.find(event => event.id === record?.penaltyId)
  const isEditing = props?.initialValues.penaltyId

  const decorators = useRef([createCalculator({
    field: 'injured',
    updates: {
      returnedToGame: (injured, values, prevValues) => {
        if (!injured) return false
        return values.returnedToGame;
      }
    }
  }, {
    field: 'penaltyId',
    updates: {
      participantId: (penaltyId, values, prevValues) => {
        if (!penaltyId) return null
        const penalty = events.find(({ id }) => id === penaltyId)
        if (!penalty) return null
        return penalty.participantId
      }
    }
  }
  )])

  return <SimpleForm decorators={decorators.current} validate={validate} {...props}>
    <Grid container spacing={2} fullWidth>
      {isEditing && <Grid item xs={12}>
        <ListItemSummaryDescription event={event} displayGameTime {...props} />
      </Grid>}
      {!isEditing && <Grid item xs={12}>
        <PenaltyInput source="penaltyId" allowEmpty={false} allowReuse={false} helperText="" label="resources.gameIncidents.fields.penaltyId" {...inputProps} />
      </Grid>}
      <Grid item xs={12}>
        <TextInput source="description" multiline rows={5} helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput source="injured" defaultValue={false} helperText="" {...props} />
      </Grid>
      <FieldDependency fieldSource="injured" test={value => value != false}>
        <Grid item xs={12}>
          <SwitchInput source="returnedToGame" defaultValue={false} helperText="" {...props} />
        </Grid>
      </FieldDependency>
    </Grid>
  </SimpleForm>
}
