import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import { Grid } from '@material-ui/core';

import { FF_DISTANCE } from '@hisports/common/featureFlags';

import Toolbar from '../../common/ra/Toolbar';
import { AddressTypeEnumInput, ProvinceEnumInput } from '../../common/inputs/EnumInputs';
import MaskedTextInput from '../../common/inputs/MaskedTextInput';
import SwitchInput from '../../common/inputs/SwitchInput';
import { useFlag } from '../../http';

const AZ = /[A-Za-z]/
const NM = /[0-9]/
const postalMask = () => [AZ, NM, AZ, ' ', NM, AZ, NM]

const validate = values => {
  const errors = {};
  if (!values.type) errors.type = 'ra.validation.required';
  if (!values.address) errors.address = 'ra.validation.required';
  if (!values.city) errors.city = 'ra.validation.required';
  if (!values.province) errors.province = 'ra.validation.required';
  if (!values.postal) errors.postal = 'ra.validation.required';
  return errors;
}

const inputProps = {
  resource: 'addresses',
  basePath: '/addresses',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

export const AddressForm = props => {
  const isEnabled = useFlag();

  const showDistance = isEnabled(FF_DISTANCE)
  return <SimpleForm validate={validate} toolbar={<Toolbar />} {...props}>
    <Grid container spacing={1} fullWidth>
      <Grid item xs={12} sm={4}>
        <AddressTypeEnumInput source="type" {...inputProps} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextInput source="label" helperText="resources.addresses.helpers.label" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="address" placeholder="400 Kipling Ave" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="address2" helperText="resources.addresses.helpers.address2" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="city" placeholder="Etobicoke" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProvinceEnumInput source="province" placeholder="ON - Ontario" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MaskedTextInput source="postal" maskOptions={{ mask: postalMask }} placeholder="M8V 3L1" {...inputProps} />
      </Grid>
      {showDistance && <Grid item xs={12} md={6}>
        <TextInput source="latitude" disabled placeholder="43.60318" {...inputProps} />
      </Grid>}
      {showDistance && <Grid item xs={12} md={6}>
        <TextInput source="longitude" disabled placeholder="-79.51988" {...inputProps} />
      </Grid>}
      <Grid item xs={12}>
        <SwitchInput source="isPrimary" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>;
}
