import React from 'react'
import { TextField, FunctionField, useTranslate } from 'react-admin'

import { SanctionOffenseDatagrid } from './SanctionOffenseModal'

const renderOptionalField = (record, source) => record[source] ? record[source] : '-';

export const SanctionOffenseGrid = props => {
  const translate = useTranslate();

  return <SanctionOffenseDatagrid size="medium" {...props} >
    <TextField source="offense" />
    <FunctionField source="durationType" render={({ durationType }) => translate(`resources.sanctions.values.durationType.${durationType}`, { _: durationType }) } />
    <FunctionField source="games" render={renderOptionalField} />
    <FunctionField source="lastMinutesGames" render={renderOptionalField} />
    <FunctionField source="injuryGames" render={renderOptionalField} />
    <FunctionField source="injuryDays" render={renderOptionalField} />
    <FunctionField source="days" render={renderOptionalField} />
    <FunctionField source="lastMinutesDays" render={renderOptionalField} />
  </SanctionOffenseDatagrid>
}
