import moment from 'moment-timezone';

import { officialAccepted, officialDeclined } from "./events";
import { isAssigned, isConfirmed, isDeclined } from "./assignment";

export class AssignmentError extends Error {
  constructor(name, ...rest) {
    super(...rest)
    this.name = name;
    this.status = 422;
  }
}

const validate = (assignments, validator) => {
  try {
    return assignments.every(validator)
  } catch (error) {
    if (error instanceof AssignmentError) return false;
    throw error;
  }
}

export const canAccept = (assignment, _skip = false, _now = moment()) => {
  if (!assignment) throw new AssignmentError('not_assigned', 'Must be assigned to accept an assignment')
  if (!assignment.assignSettings || !assignment.assignSettings.enabled) throw new AssignmentError('disabled', 'Game is not configured for assigning');

  if (!_skip && isConfirmed(assignment)) throw new AssignmentError('already_accepted', 'Assignment already accepted');

  const { overlappingAssignments = [], consecutiveAssignments = [], assignSettings: { enforceConsecutive } } = assignment;

  const canOverlapsDecline = validate(overlappingAssignments, overlap => canDecline(overlap, false, _now));
  if (!canOverlapsDecline) throw new AssignmentError('accept_overlaps', 'Cannot accept due to overlapping assignment');

  const hasRequiredConsecutiveOverlaps = enforceConsecutive && consecutiveAssignments.some(assignment => {
    return assignment.overlappingAssignments.some(assignment => isAssigned(assignment))
  })
  if (hasRequiredConsecutiveOverlaps) throw new AssignmentError('accept_consecutive_overlaps', 'Cannot accept due to consecutive overlapping assignment')

  if (!_skip && enforceConsecutive) {
    const canConsecutivesAccept = validate(consecutiveAssignments, consecutive => canAccept(consecutive, true, _now));
    if (!canConsecutivesAccept) throw new AssignmentError('accept_consecutive', 'Cannot accept due to consecutive assignment');
  }

  return true;
}

export const canDecline = (assignment, _skip = false, _now = moment()) => {
  if (!assignment) throw new AssignmentError('not_assigned', 'Must be assigned to decline an assignment')
  if (!assignment.assignSettings || !assignment.assignSettings.enabled) throw new AssignmentError('disabled', 'Game is not configured for assigning');

  if (!_skip && isDeclined(assignment)) throw new AssignmentError('already_declined', 'Assignment already declined');

  const { declineEnabled, declineHours, declineConfirmedDisabled } = assignment.assignSettings;
  if (!declineEnabled) throw new AssignmentError('decline_disabled', 'Must contact an assigner to be removed')

  const withinDeclineHours = declineHours && moment.tz(assignment.game.startTime, assignment.game.timezone).diff(_now, 'hours', true) < declineHours;
  if (withinDeclineHours) throw new AssignmentError('decline_hours', `Cannot decline under ${declineHours} hours before a game`)

  const declineDisabled = declineConfirmedDisabled && isConfirmed(assignment)
  if (declineDisabled) throw new AssignmentError('decline_disabled', 'Must contact an assigner to be removed')

  if (!_skip) {
    const { consecutiveAssignments, assignSettings: { enforceConsecutive } } = assignment;
    const canConsecutivesDecline = validate(consecutiveAssignments, consecutive => canDecline(consecutive, true, _now));
    if (enforceConsecutive && !canConsecutivesDecline) throw new AssignmentError('decline_consecutive', 'Cannot decline due to consecutive assignment');
  }

  return true;
}

export const acceptAssignment = (assignment, notes, _now) => {
  const events = [];
  if (!canAccept(assignment, false, _now)) return events; // throws

  const { gameId, consecutiveAssignments = [], overlappingAssignments = [], assignSettings: { enforceConsecutive } } = assignment;
  const consecutiveGameIds = consecutiveAssignments.map(assignment => assignment.gameId);
  const declinedOverlaps = overlappingAssignments.reduce((assignments, assignment) => {
    assignments.push(assignment)
    if (assignment.assignSettings.enforceConsecutive) {
      const additionalConsecutives = assignment.consecutiveAssignments.filter(assignment => assignment.gameId !== gameId && !consecutiveGameIds.includes(assignment.gameId))
      assignments.push(...additionalConsecutives)
    }
    return assignments;
  }, [])
  events.push(...declinedOverlaps.map(assignment => officialDeclined(assignment)))
  const gameIds = events.map(event => event.gameId);
  if (enforceConsecutive) {
    const additionalAssignments = consecutiveAssignments
      .filter(assignment => !gameIds.includes(assignment.gameId))
      .filter(assignment => !isConfirmed(assignment))
    events.push(...additionalAssignments.map(assignment => officialAccepted(assignment, notes)))
  }
  events.push(officialAccepted(assignment, notes))

  return events;
}

export const declineAssignment = (assignment, notes, _now) => {
  const events = [];
  if (!canDecline(assignment, false, _now)) return events; // throws

  const { consecutiveAssignments = [], assignSettings: { enforceConsecutive } } = assignment;
  if (enforceConsecutive) {
    const additionalAssignments = consecutiveAssignments
      .filter(assignment => !isDeclined(assignment))
    events.push(...additionalAssignments.map(assignment => officialDeclined(assignment, notes)))
  }
  events.push(officialDeclined(assignment, notes))

  return events;
}
